import React, { lazy, Suspense } from 'react';

const LazyExportDistributionList = lazy(async () => await import('./ExportDistributionList'));

const ExportDistributionList = () => (
  <Suspense fallback={null}>
    <LazyExportDistributionList />
  </Suspense>
);

export default ExportDistributionList;
