import React, { lazy, Suspense } from 'react';
import type { DocumentButtonsProps } from './DocumentButtons';
const LazyDocumentButtons = lazy(async () => await import('./DocumentButtons'));

const DocumentButtons = (props: DocumentButtonsProps) => (
  <Suspense fallback={null}>
    <LazyDocumentButtons {...props} />
  </Suspense>
);

export default DocumentButtons;
