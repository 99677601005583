import type { FC, SyntheticEvent } from 'react';
import { ListItem, Box } from '@mui/material';
import { BlackGrey, WhiteGrey, LightGreen, MainWhite } from '../../theme/colors';

interface Props {
  value: string
  label: string
  handleItemSelect: (event: SyntheticEvent, value: string) => void
  selected: boolean
}

/**
 * Tab Item Component for the Slide Drawer Component in distribution lists containing lists or contacts
 */
const TabItem: FC<Props> = ({ value, label, handleItemSelect, selected }: Props) => {
  return (
   <ListItem
     sx={{
       width: '100%',
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'flex-start',
       padding: '10px',
       border: `1px solid ${WhiteGrey}`,
       minHeight: '64px',
       background: (selected) ? LightGreen : MainWhite,
       '&:hover': {
         background: (selected) ? LightGreen : WhiteGrey,
         cursor: 'pointer'
       }
     }}
     onClick={(event: SyntheticEvent) => handleItemSelect(event, value)}
   >
     <Box
       component={'span'}
       sx={{
         textTransform: 'capitalize',
         color: BlackGrey,
         fontWeight: '400',
         fontSize: '0.875rem'
       }}
     >
       {label}
     </Box>
   </ListItem>
  )
}

export default TabItem;
