import React, { lazy, Suspense } from 'react';
import type { ParseFileProps } from './ParseFile';

const LazyParseFile = lazy(async () => await import('./ParseFile'));

const ParseFile = (props: ParseFileProps) => (
  <Suspense fallback={null}>
    <LazyParseFile {...props} />
  </Suspense>
);

export default ParseFile;
