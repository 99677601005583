import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { DistributionHistoryPage } from '../../DistributionHistory';
import type { DistributionMessage } from '../../../../models/DistributionMessage';
import type { RootState } from '../../../../state/store';
import styles from './EmailDrawerTab.module.scss';
import DistributionService from '../../../../services/distribution/DistributionService';
import { DraftNumOfTotalItems, SentNumOfTotalItems } from '../../../../services/distribution/constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import type { MessageStatus } from '../../../../models/Message';
import { scrollStyles } from '../../../../theme/styles';
import { BlackGrey } from '../../../../theme/colors';
import { DataGrid, type GridColDef, type GridRenderCellParams, type GridRowId, type GridSelectionModel, type GridRowsProp } from '@mui/x-data-grid';
import moment from 'moment';

interface Props {
  pageTitle: DistributionHistoryPage
  handleMessageSelect: (msgId: GridRowId) => void
  search: string
  messageStatus: MessageStatus
  pageNumber: number
  setPageNumber: Dispatch<SetStateAction<number>>
}

interface EmailRowData {
  id: string
  nbOfRecipients: number
  subject: string
  date: string
  hasAttachment: boolean
}

const originalPageNumber: number = 1;
let pageStart: number = 0;
let pageEnd: number = 0;

/**
 * Email Drawer Tab Component for the Email Drawer Component containing messages for sent & draft history page
 */
const EmailDrawerTab: FC<Props> = (props: Props) => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [totalItemsLimit, setTotalItemsLimit] = useState<number>(DraftNumOfTotalItems);
  // get distribution messages from redux for current page type
  const distributionMessages: DistributionMessage[] = useSelector((state: RootState) => state.distribution.distributionMessages[props.pageTitle]);

  // handle if new page type and/or if new distribution messages are added
  useEffect(() => {
    const newRows: GridRowsProp = Object.entries(distributionMessages).map(([index, data]) => ({
      id: data.id,
      data
    }));
    setRows(newRows);
  }, [distributionMessages]);

  useEffect(() => {
    if (props.pageTitle === DistributionHistoryPage.Sent) {
      setTotalItemsLimit(SentNumOfTotalItems);
    } else {
      setTotalItemsLimit(DraftNumOfTotalItems);
    }
  }, [props.pageTitle])

  const showMessage = (msgId: GridRowId): void => {
    props.handleMessageSelect(msgId);
  };

  const filteredRows = useMemo(() => {
    const searchedRows: GridRowsProp = (props.search) ? rows.filter(row => row.data.subject.toLowerCase().includes(props.search.toLowerCase())) : rows;
    pageStart = (searchedRows.length === 0) ? 0 : (props.pageNumber - 1) * totalItemsLimit + 1;
    pageEnd = (searchedRows.length === 0) ? 0 : pageStart + searchedRows.length - 1;
    return searchedRows;
  }, [props.search, rows]);

  const loadNextPage = () => {
    if (distributionMessages.length < totalItemsLimit) return;
    const nextPage: number = props.pageNumber + 1;
    DistributionService.fetchMessageHistory(props.pageTitle, props.messageStatus, nextPage);
    props.setPageNumber(nextPage);
  }

  const loadPrevPage = () => {
    if (props.pageNumber === originalPageNumber) return;
    const nextPage: number = props.pageNumber - 1;
    DistributionService.fetchMessageHistory(props.pageTitle, props.messageStatus, nextPage);
    props.setPageNumber(nextPage);
  }

  return (
    <div className={styles.EmailDrawerTab} data-testid="EmailDrawerTab">
      <List sx={{ width: '100%' }}>
        <div className={styles.drawerTab}>
          <DataGrid
            rows={filteredRows}
            columns={getColumns()}
            headerHeight={0}
            onSelectionModelChange={(selectionModels: GridSelectionModel) => {
              showMessage(selectionModels[0]);
            }}
            hideFooterSelectedRowCount={true}
            hideFooter={true}
            rowsPerPageOptions={[]}
            sx={{
              border: 'none',
              '& .MuiDataGrid-virtualScroller': scrollStyles,
              '& .MuiDataGrid-columnHeaders': {
              },
              '& .MuiDataGrid-renderingZone': {
                maxHeight: 'none !important'
              },
              '& .MuiDataGrid-cell': {
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'normal'
              },
              '& .MuiDataGrid-row': {
                maxHeight: 'none !important',
                color: BlackGrey,
                fontSize: '1rem'
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#DFF7DF',
                border: 'none',
                outline: 0
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#DFF7DF',
                cursor: 'pointer'
              },
              '& .MuiDataGrid-row.Mui-selected:hover': {
                backgroundColor: '#DFF7DF'
              }
            }}
          />
        </div>
        <div className={styles.paginationPanel}>
          <div className={`${pageStart > 0 && pageEnd > 0 ? styles.navLabel : styles.hideLabel}`}>{`${pageStart} - ${pageEnd}`}</div>
          <div className={`${styles.navItem} ${props.pageNumber === 1 ? styles.disabled : ''} `} onClick={loadPrevPage}><ArrowBackIosIcon/></div>
          <div className={`${styles.navItem} ${distributionMessages.length < totalItemsLimit ? styles.disabled : ''}`} onClick={loadNextPage}><ArrowForwardIosIcon/></div>
        </div>
      </List>
    </div>
  )
};

function getColumns (): GridColDef[] {
  // Render column data
  return [{
    field: 'data',
    flex: 1,
    renderCell: ({ value }: GridRenderCellParams<EmailRowData, any, any>) => {
      const { subject, date, hasAttachment } = value as EmailRowData;
      const today = moment().startOf('day');
      const dateAsMoment = moment(date);
      const isTodayOrLater = dateAsMoment.isSameOrAfter(today);
      return <div className={styles.messageItemTable}>
        <div className={styles.messageItemRow}>
          <div className={styles.subjectContainer}>
            {subject}
          </div>
          <div className={styles.dateCell}>
            {(isTodayOrLater) ? moment(date).format('h:mmA') : moment(date).format('MM/DD/YY')}
          </div>
          <div className={styles.attachmentCell}>
            {hasAttachment && <AttachmentIcon />}
          </div>
        </div>
      </div>
    }
  }]
}

export default EmailDrawerTab;
