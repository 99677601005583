import React, { lazy, Suspense } from 'react';

const LazyDeleteDocument = lazy(async () => await import('./DeleteDocument'));

const DeleteDocument = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyDeleteDocument {...props} />
  </Suspense>
);

export default DeleteDocument;
