import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import styles from './Sides.module.scss';
import MainSection from '../common/MainSection';
import DocumentViewer from '../DocumentViewer/DocumentViewer.lazy';
import DocumentLists from '../DocumentLists/DocumentLists.lazy';
import SlideDrawer from '../slideDrawer/SlideDrawer';
import DocumentService from '../../services/documents/DocumentService';
import type { Anchor } from '../../models/Anchor';
import type { Side } from '../../models/Side';
import { DefaultListOrder, sortFiles } from '../DocumentLists/DocumentLists';
import { useAppDispatch } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import { drawerWidth } from '../../theme/styles';
import NothingToSee, { NothingToSeeEntityType } from '../nothingToSee/NothingToSee';

export enum SidesPage {
  Title = 'Sides'
}

export interface SidesProps {
  pageTitle: SidesPage
  children?: React.ReactNode
}

const anchor: Anchor = 'left';

/**
 * Sides component for Side page
 * Calls the document viewer component to display the file selected
 */

const Sides: FC<SidesProps> = ({ pageTitle }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle]);

  const buttonText: string = 'sides.slideDrawer.button';
  const tabs: string[] = ['sides.slideDrawer.tabHeader'];
  const [open, setOpen] = useState<boolean>(true);
  const [files, setFiles] = useState<Side[]>([]);
  const [selectedFile, setSelectedFile] = useState<Side | null>(null);
  const [currentFile, setCurrentFile] = useState<Side | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    DocumentService.fetchDocumentsBySubcategory('side')
      .then((files: Side[]) => {
        files = sortFiles(files, DefaultListOrder);
        setFiles(files);
        if (files.length > 0) {
          setSelectedFile(files[0]);
        } else {
          setLoading(false);
        }
      })
      .catch((e: any) => console.debug(e));
  }, []);

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  const handleFileChange = (fileId: string): void => {
    if (!selectedFile || selectedFile.id !== fileId) {
      const newFile = files.find((file: Side) => file.id === fileId);
      if (newFile !== undefined) {
        setSelectedFile(newFile);
      }
    }
  };

  const handleDeleteSuccess = (id: string) => {
    setFiles(files => files.filter(f => f.id !== id));
  };

  return (
    <div className={styles.Sides} data-testid="Sides">
      <SlideDrawer
        drawerWidth={drawerWidth} open={open} anchor={anchor}
        toggleDrawer={toggleDrawer}
        buttonText={buttonText}
      >
        <DocumentLists // displays the scripts in the slide drawer - this is the child of the slide drawer
          file={selectedFile}
          files={files}
          handleFileChange={handleFileChange}
          tabs={tabs}
          documentType="side"
          setCurrentFile={setCurrentFile}
        />
      </SlideDrawer>
      <MainSection open={open} drawerwidth={drawerWidth}>
        {(files.length > 0)
          ? <DocumentViewer
            file={selectedFile}
            actionsFile={currentFile}
            watermark={false}
            onDeleteSuccess={handleDeleteSuccess}
            isLoading={isLoading}
            setLoading={setLoading}
          />
          : <Grid sx={{ display: 'flex', height: '100%', alignItems: 'center' }}><NothingToSee entityType={NothingToSeeEntityType.Side} /></Grid>
        }
      </MainSection>
    </div>
  )
};

export default Sides;
