import React, { lazy, Suspense } from 'react';
import type { DashboardButtonsProps } from './DashboardButtons';

const LazyDashboardButtons = lazy(async () => await import('./DashboardButtons'));

const DashboardButtons = (props: DashboardButtonsProps) => (
  <Suspense fallback={null}>
    <LazyDashboardButtons {...props} />
  </Suspense>
);

export default DashboardButtons;
