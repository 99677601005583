import { useState, useEffect } from 'react';
import type { FC } from 'react';
import FormCheckbox from '../../forms/FormCheckbox';
import type { OptionProps } from '../../../models/OptionProps';
import type { TemplateForm } from '../SaveTemplateFlow';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import type { Control, UseFormSetValue } from 'react-hook-form';

const items: OptionProps[] = [
  {
    key: 'subject',
    value: 'subject',
    label: 'compose.saveTemplate.includeOptions.items.subject'
  },
  {
    key: 'emailBody',
    value: 'emailBody',
    label: 'compose.saveTemplate.includeOptions.items.emailBody'
  },
  {
    key: 'recipients',
    value: 'recipients',
    label: 'compose.saveTemplate.includeOptions.items.recipients'
  }
];

interface Props {
  control: Control<TemplateForm>
  setValue: UseFormSetValue<TemplateForm>
}

/**
 * Template Options Component for the save template flow - step 1
 */
const TemplateOptions: FC<Props> = ({ control, setValue }) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const [selectedItems, setSelectedItems] = useState<string[]>(['subject', 'emailBody']);

  const checkChecked = (item: string): boolean => {
    return selectedItems.includes(item);
  }

  const handleClick = (value: string): void => {
    if (selectedItems.includes(value)) {
      const remaining = selectedItems.filter((item: string) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  }

  useEffect(() => {
    setValue('options', selectedItems);
  }, [selectedItems])

  return (
    <FormCheckbox
      name={'options'}
      formItemProps={{
        control
      }}
      checkChecked={checkChecked}
      onClick={handleClick}
      options={items.map((item: OptionProps) => ({ ...item, label: t(item.label) }))}
    />
  )
}

export default TemplateOptions;
