import { makeStyles } from '@mui/styles';

import { CrooglooGreen, MainBlack, MainWhite } from '../../theme/colors';

const useStyles = makeStyles({
  formControl: {
    border: 'none',
    '& fieldset': {
      border: 'none'
    },
    '& .MuiInputBase-root': {
      width: '35px'
    },
    '& .MuiInputLabel-root': {
      fontWeight: 'bold',
      color: MainBlack,
      '&.Mui-focused': {
        color: `${CrooglooGreen}`
      }
    },
    '&:hover': {
      '& .MuiInputLabel-root': {
        color: `${CrooglooGreen}`
      }
    }
  },
  button: {
    width: '30px',
    padding: '0px',
    color: MainBlack,
    '&:hover': {
      color: CrooglooGreen
    },
    '& svg': {
      fontSize: '1.4rem',
      '& fill': MainBlack
    }
  },
  expanded: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 2000,
    background: MainWhite
  }
});

export default useStyles;
