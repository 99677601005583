import React, { lazy, Suspense } from 'react';
import type { DocumentViewerProps } from './DocumentViewer';

const LazyDocumentViewer = lazy(async () => await import('./DocumentViewer'));

const DocumentViewer = (props: DocumentViewerProps) => (
  <Suspense fallback={null}>
    <LazyDocumentViewer {...props} />
  </Suspense>
);

export default DocumentViewer;
