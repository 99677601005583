import i18n from 'i18next';
import { store } from '../../state/store';
import type { Subscription } from '../../models/Subscription';
import { setCurrentPlan } from '../../state/slices/Subscription';

const { dispatch } = store;

interface ICrooglooSubscriptionService {
  setCurrentPlan: (planId: string) => Promise<void>
  getCurrentPlanName: () => string
  getCurrentPlanOptions: () => Subscription[]
  getPriceAsString: (product: Subscription) => string
}

/**
 * Service handling different functions related to current subscription for user
 */
class CrooglooSubscriptionService implements ICrooglooSubscriptionService {
  /**
   * set current user subscription plan based on the planId
   * @param planId: string
   */
  async setCurrentPlan (planId: string): Promise<void> {
    await new Promise((resolve, reject) => {
      if (planId) {
        const productList: Subscription[] = store.getState().subscription.productList;
        const currentPlan: Subscription | undefined = productList.find((product: Subscription) => product.planId === planId);
        if (currentPlan) {
          dispatch(setCurrentPlan({ plan: currentPlan }));
          resolve(true);
        } else {
          reject(new Error('Error Getting Current Plan: Invalid plan ID'));
        }
      } else {
        reject(new Error('Error Getting Current Plan: Invalid plan ID'));
      }
    });
  }

  /**
   * get name of current subscription plan in redux
   * if not set return generic croogloo string
   */
  getCurrentPlanName (): string {
    let planName: string = 'Croogloo Office';
    const currentPlan: Subscription | null = store.getState().subscription.currentPlan;
    if (currentPlan) {
      planName = currentPlan.name;
    }
    return planName;
  }

  /**
   * Return array of subscriptions available to user to change to
   * Called in the manage subscription modal
   */
  getCurrentPlanOptions (): Subscription[] {
    const { productList } = store.getState().subscription;
    return productList.filter((product: Subscription) => product.isVisibleInStore);
  }

  /**
   * Return subscription price from the Subscription object as a string
   * @param product: Subscription
   */
  getPriceAsString (product: Subscription): string {
    const priceString: string = product.price.toString();
    let price = `${priceString.substring(0, priceString.length - 2)}.${priceString.substring(priceString.length - 2)}`;
    price += `/${i18n.t('subscription.manage.billing.' + (product.billingPeriod || 'month'))}`
    return price;
  }
}

const crooglooSubscriptionService: ICrooglooSubscriptionService = new CrooglooSubscriptionService();
export default crooglooSubscriptionService;
