import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import styles from './DistributionReport.module.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, type ChartData, type CoreChartOptions, type DatasetChartOptions, type ElementChartOptions, type PluginChartOptions, type ScaleChartOptions, type DoughnutControllerChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { type _DeepPartialObject } from 'chart.js/dist/types/utils';
import LegendListItem from './LegendListItem/LegendListItem';
import { DataGrid, GridLinkOperator, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { type GridColDef, type GridColumnHeaderParams, type GridRenderCellParams, type GridRowsProp, type GridValidRowModel } from '@mui/x-data-grid/models';
import type { DistributionStatuses, DistributionReportData, RecipientData, ReportRowData } from '../../models/DistributionReport';
import { type UseTranslationResponse, useTranslation } from 'react-i18next';
import { type TFunction } from 'i18next';
import type { RootState } from '../../state/store';
import { useSelector } from 'react-redux';
import TertiaryButton from '../common/buttons/TertiaryButton';
import { MainBlack, CrooglooGreen } from '../../theme/colors';

ChartJS.register(ArcElement, Tooltip, Legend);

export interface DistributionReportProps {
  closeReport: () => void
  isDrawerOpen: boolean
}

const initalReportData: DistributionReportData = {
  processd: 0,
  delivered: 0,
  opened: 0,
  bounced: 0,
  deferred: 0,
  dropped: 0
}

const EmailStatusColor: any = {
  processed: 'rgba(152, 215, 104, 1)',
  delivered: 'rgba(130, 186, 90, 1)',
  opened: CrooglooGreen,
  bounced: 'rgba(208, 113, 84, 1)',
  deferred: 'rgba(195, 80, 45, 1)',
  dropped: 'rgba(169, 47, 9, 1)'
}

const OriginalFilteredStatus: string = '';

function getReportData (report: DistributionStatuses): DistributionReportData {
  const reportData: DistributionReportData = initalReportData;
  Object.keys(report).forEach((key: string) => {
    reportData[key] = report[key].length;
  });
  return reportData;
}

function getDeliveryData (
  t: TFunction<'translation', undefined, 'translation'>,
  reportData: DistributionReportData
): ChartData<'doughnut', number[], string> {
  return {
    labels: Object.keys(reportData).map(k => t(`distributionReport.status.${k}`)),
    datasets: [
      {
        label: t('distributionReport.datasetLabel') ?? '',
        data: Object.values(reportData),
        backgroundColor: [
          EmailStatusColor.processed,
          EmailStatusColor.delivered,
          EmailStatusColor.opened,
          EmailStatusColor.bounced,
          EmailStatusColor.deferred,
          EmailStatusColor.dropped
        ],
        borderWidth: 0
      }
    ]
  }
}

function getRowData (report: DistributionStatuses, filterStatus: string): GridRowsProp {
  const status: string = (filterStatus === 'Sent') ? 'processed' : filterStatus;
  const rows: ReportRowData[] = [];
  Object.keys(report).forEach((key: string, index: number) => {
    if ((status === '' || key === status) && report[key].length > 0) {
      report[key].forEach((recipient: RecipientData) => {
        rows.push({ status: key, name: recipient.name, email: recipient.email });
      });
    }
  });
  const gridRows: GridRowsProp = rows.map((row: GridValidRowModel, index: number) => {
    row.id = index + 1;
    return row;
  });
  return gridRows;
}

const options: _DeepPartialObject<CoreChartOptions<'doughnut'> & ElementChartOptions<'doughnut'> & PluginChartOptions<'doughnut'> & DatasetChartOptions<'doughnut'> & ScaleChartOptions<any> & DoughnutControllerChartOptions> | undefined = {
  plugins: {
    legend: {
      display: false
    }
  }
}

function QuickSearchToolbar () {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 0.5,
        pb: 0,

        '& .MuiInputBase-root': {
          border: '1px solid rgba(108, 109, 107, 0.34)',
          borderRadius: '50px',
          paddingLeft: '8px'
        },
        '& .MuiInputBase-root:before': {
          display: 'none'
        },
        '& .MuiInputBase-root:after': {
          display: 'none'
        }
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}

function getColumns (
  t: TFunction<'translation', undefined, 'translation'>
): GridColDef[] {
  return [
    {
      field: 'status',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<any, any, any>) =>
        <div
          style={{ backgroundColor: EmailStatusColor[params.value] }}
          className={styles.statusCircle}
        >
        </div>
    },
    { field: 'name', flex: 1 },
    { field: 'email', flex: 2 }
  ].map((column: GridColDef) => {
    column.renderHeader = (params: GridColumnHeaderParams) => (
      <div className={styles.dataGridHeader}>{t(`distributionReport.tableHeader.${params.field}`)}</div>
    );
    return column;
  });
}

/**
 * Distribution Report Component for the distribution history page containing messages for sent
 */
const DistributionReport: FC<DistributionReportProps> = ({ closeReport, isDrawerOpen }: DistributionReportProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const [reportData, setReportData] = useState<DistributionReportData>(initalReportData);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filteredStatus, setFilteredStatus] = useState<string>(OriginalFilteredStatus);
  // get current report from redux
  const distributionReport: DistributionStatuses = useSelector((state: RootState) => state.distribution.currentDistributionReport);

  // handle if a new report has been selected. get data in correct format to display in the report
  useEffect(() => {
    setReportData(getReportData(distributionReport));
    setRows(getRowData(distributionReport, OriginalFilteredStatus));
  }, [distributionReport]);

  useEffect(() => {
    if (filteredStatus) {
      setRows(getRowData(distributionReport, filteredStatus));
    }
  }, [filteredStatus])

  const handleOpenMessage = () => {
    closeReport();
  };

  return <div className={styles.DistributionReport} data-testid="DistributionReport">
    <TertiaryButton className={styles.viewButton} disableRipple onClick={handleOpenMessage} textcolor={MainBlack}>{t('action.viewMessage')}</TertiaryButton>
    <Grid
      className={styles.reportBox}
      item
      container={true}
      sx={{
        textAlign: 'left',
        border: '1px solid rgba(108, 109, 107, 0.34)',
        boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        marginBottom: '30px'
      }}
    >
      <Grid
        container={true}
        item
        md={4}
        sm={12}
      >
        <Grid
          item
          container
          xs={12}
          sx={{
            padding: '45px 20px 20px 20px',
            textAlign: 'center',
            borderRight: '2px solid rgba(0,0,0,0.1)'
          }}
        >
          <h2 style={{ marginBlockStart: '0', marginBlockEnd: '0.4rem', width: '100%' }}>{t('distributionReport.title')}</h2>
          <div className={styles.messageStatusPanel}>
            <span className={`${styles.successMessages} ${styles.messageData}`}>{t('distributionReport.successMessages')} {reportData.delivered + reportData.opened + reportData.processed}</span>&nbsp;
            <span className={`${styles.failMessages} ${styles.messageData}`}>{t('distributionReport.failMessages')} {reportData.bounced + reportData.deferred + reportData.dropped}</span>
          </div>
          <Doughnut style={{ padding: '10px' }} data={getDeliveryData(t, reportData)} options={options}/>
          <Grid item xs={12} sx={{ padding: '20px 10px', textAlign: 'left' }}>
            {/* <h2 className={styles.totalSentLabel}>{t('distributionReport.status.Sent')}: {reportData.processed}</h2> */}
            <LegendListItem
              color={EmailStatusColor.processed}
              label={t('distributionReport.status.Sent')}
              value={reportData.processed}
              filterStatus={setFilteredStatus}
            />
            <hr/>
            <LegendListItem
              color={EmailStatusColor.delivered}
              label={'delivered'}
              value={reportData.delivered}
              filterStatus={setFilteredStatus}
            />
            <hr/>
            <LegendListItem
              color={EmailStatusColor.opened}
              label={'opened'}
              value={reportData.opened}
              filterStatus={setFilteredStatus}
            />
            <hr/>
            <LegendListItem
              color={EmailStatusColor.bounced}
              label={'bounced'}
              value={reportData.bounced}
              filterStatus={setFilteredStatus}
            />
            <hr/>
            <LegendListItem
              color={EmailStatusColor.deferred}
              label={'deferred'}
              value={reportData.deferred}
              filterStatus={setFilteredStatus}
            />
            <hr/>
            <LegendListItem
              color={EmailStatusColor.dropped}
              label={'dropped'}
              value={reportData.dropped}
              filterStatus={setFilteredStatus}
            />
            <hr/>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container={true}
        md={8}
        sm={12}
        alignContent='flex-start'
      >
        <Grid item xs={12} sx={{ padding: '20px' }}>
          <Box sx={{ height: 565, width: 1 }}>
            <DataGrid
              hideFooterPagination={true}
              pageSize={50}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or
                  }
                }
              }}
              rows={rows}
              columns={getColumns(t)}
              components={{ Toolbar: QuickSearchToolbar }}
              className={styles.dataGrid}
              sx={{
                '& .MuiDataGrid-footerContainer': {
                  display: 'none'
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                  width: '0.6em'
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                  background: 'rgba(0,0,0,0.05)'
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                  background: 'rgba(0,0,0,0.15)',
                  borderRadius: '5px'
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                  background: 'rgba(0,0,0,0.25)'
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </div>
};

export default DistributionReport;
