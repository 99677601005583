import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import SaveTemplateSteps from './SaveTemplateSteps';
import SaveTemplateButtons from './SaveTemplateButtons';
import { SaveTemplateModal } from './StepsModel';
import ModalService from '../../services/ModalService';
import ModalTitle from '../modals/ModalTitle/ModalTitle.lazy';
import TemplateService from '../../services/TemplateService';
import Toast from '../common/toast/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import type { ToastProps } from '../../models/Toast';
import type { WSResponse } from '../../models/WSResponse';
import type { RootState } from '../../state/store';
import type { AlertColor } from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import dialogStyles from '../../theme/dialog.module.scss';
import UtilityService from '../../services/UtilityService';

export interface TemplateForm {
  options: string[]
  name: string
}

export interface AttachmentItem {
  checked: boolean
  quantity: number
  subcategory: string
}

export const InitiailAttachmentItem: AttachmentItem = { checked: false, quantity: 0, subcategory: 'callsheet' };

interface Props {
  getValues: any
  contentValue: string
}

/**
 * The save template component
 * Contains mui dialog component along with the saveTemplateSteps component and save template buttons component
 */
const SaveTemplateFlow: FC<Props> = (props: Props) => {
  const modalsState = useSelector((state: RootState) => state.modals.custom[SaveTemplateModal]);

  const defaultValues: TemplateForm = {
    options: [],
    name: ''
  }

  const { control, setValue, getValues } = useForm<TemplateForm>({
    defaultValues
  });

  const [selectedItems, setSelectedItems] = useState<AttachmentItem[]>([InitiailAttachmentItem]);
  // state for current step index
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  // state for errors
  const [errorValue, setErrorValue] = useState<string>('');
  // states for toast
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  useEffect(() => {
    if (modalsState.metaData) {
      if (modalsState.metaData.length > 0) {
        setValue('name', modalsState.metaData[0])
      } else {
        setValue('name', '')
      }
    }
  }, [modalsState.isVisible]);

  const handleSelectedItems = (newSelectedItems: AttachmentItem[]): void => {
    setSelectedItems(newSelectedItems);
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const handleShowToast = (message: string, toastType: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true
    })
  }

  // handle first two steps validation and going to next display
  const handleNextStep = (): void => {
    setErrorValue('');
    switch (currentStepIndex) {
      case 0: {
        const errorMessage: string | null = TemplateService.checkSelectedInclusions(getValues('options'), props.getValues);
        if (errorMessage && typeof errorMessage === 'string') {
          setErrorValue(errorMessage);
          return;
        }
        break;
      }
      case 1: {
        const errorMessage: string | null = TemplateService.checkAttachmentOptions(selectedItems);
        if (errorMessage && typeof errorMessage === 'string') {
          setErrorValue(errorMessage);
          return;
        }
        break;
      }
    }
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex === 2) {
      if (getValues('name') === '') {
        setCurrentStepIndex(nextStepIndex);
      } else {
        handleConfirm();
      }
    } else {
      setCurrentStepIndex(nextStepIndex);
    }
  }

  // handles the closing of the dialog and reseting the step count
  const handleCancel = (): void => {
    ModalService.closeCustomModal(SaveTemplateModal);
    setCurrentStepIndex(0);
    setErrorValue('');
  }

  // handle final validation and saving tempalte
  const handleConfirm = (): void => {
    setErrorValue('');
    const options: string[] = getValues('options');
    const name: string = getValues('name');
    if (!TemplateService.checkTemplateName(getValues('name'))) {
      setErrorValue('compose.saveTemplate.message.noName');
      return;
    }
    TemplateService.buildTemplate(options, name, selectedItems, props.getValues, props.contentValue)
      .then((result: WSResponse) => {
        if (UtilityService.isSuccessResponse(result.responseCode)) {
          handleShowToast('compose.saveTemplate.message.success', 'success', ToastIcons.Success);
        } else {
          handleShowToast('compose.saveTemplate.message.error', 'error', ToastIcons.Info);
        }
        handleCancel();
      }).catch((err) => {
        console.error(err);
      })
  }

  return (
    <Dialog
      open={modalsState.isVisible}
      onClose={handleCancel}
      sx={{
        textAlign: 'center',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '538px',
            maxHeight: '100%'
          }
        }
      }}
      classes={{
        root: `${dialogStyles.dialogRoot}`,
        paper: `${dialogStyles.dialogPaper}`
      }}
      BackdropProps={{
        className: dialogStyles.dialogBackdrop
      }}
    >
      <ModalTitle handleClose={handleCancel} title={modalsState.title} />
      <DialogContent>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='stretch'
          >
            <SaveTemplateSteps
              currentStepIndex={currentStepIndex}
              control={control}
              setValue={setValue}
              selectedItems={selectedItems}
              handleSelectedItems={handleSelectedItems}
              errorValue={errorValue}
            />
          </Grid>
      </DialogContent>
      <DialogActions>
        <SaveTemplateButtons
          currentStepIndex={currentStepIndex}
          handleClose={handleCancel}
          successButton={modalsState.confirmButton}
          handleNextStep={handleNextStep}
          handleConfirm={handleConfirm}
        />
      </DialogActions>
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={toastStatus.message}
        icon={toastStatus.icon}
      />
    </Dialog>
  )
}

export default SaveTemplateFlow;
