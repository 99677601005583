import { useState } from 'react';
import type { FC } from 'react';
import { IconButton, Box, Popover, Grid } from '@mui/material';
import { InsertLink } from '@mui/icons-material';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import useStyles from './editorStyles';

interface Props {
  checkIsLinkActive: () => boolean
  handleLinkSelect: (url: string) => void
  handleUnwrap: () => void
}

export const LinkButton: FC<Props> = ({
  checkIsLinkActive,
  handleLinkSelect,
  handleUnwrap
}: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [url, setUrl] = useState<string>('')

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    const isActiveLink: boolean = checkIsLinkActive();
    if (isActiveLink) {
      handleUnwrap();
    } else {
      setIsOpen(!isOpen);
      setAnchorEl(event.currentTarget);
    }
  }

  const handleClose = (): void => {
    setIsOpen(false);
    setUrl('');
  }

  const handleSave = (): void => {
    handleLinkSelect(url);
    handleClose();
  }

  const handleCancel = (): void => {
    handleClose();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <IconButton
        onMouseDown={handleOpen}
        className={classes.button}
        disableRipple
      >
       <InsertLink />
      </IconButton>
      <Popover
        id={'simple-popover'}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '5px',
            gap: '4px'
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <input
              type='text'
              placeholder='Enter URL'
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              style={{ width: '200px' }}
            />
          </Grid>
          <Grid>
            <button onClick={handleSave}>
              {t('action.save')}
            </button>
          </Grid>
          <Grid>
            <button onClick={handleCancel}>
            {t('action.cancel')}
            </button>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  )
}

export default LinkButton;
