import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import { DataGrid } from '@mui/x-data-grid';
import type { GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import type { DistributionHistoryPage } from '../../DistributionHistory';
import styles from './EmailDrawerTab.module.scss';
import { ToastPink, MainWhite } from '../../../../theme/colors';
import type { DistributionMessage } from '../../../../models/DistributionMessage';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import type { RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import ModalService from '../../../../services/ModalService';
import { AbortMessageModal } from '../../../modals/AbortMessage/constants';
import moment from 'moment';
import type { Moment } from 'moment';
import UtilityService from '../../../../services/UtilityService';
import { scrollStyles } from '../../../../theme/styles';
import { MessageStatus } from '../../../../models/Message';

interface Props {
  pageTitle: DistributionHistoryPage
  handleMessageSelect: (msgId: GridRowId) => void
}

/**
 * Outbox Drawer Tab Component for the Email Drawer Component containing messages for outbox history page
 */
const EmailDrawerTab: FC<Props> = (props: Props) => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowHovered, setRowHovered] = useState<string | null>(null);
  // get distribution messages from redux for current page type
  const distributionMessages: DistributionMessage[] = useSelector((state: RootState) => state.distribution.distributionMessages[props.pageTitle]);

  // handle if new page type and/or if new distribution messages are added
  useEffect(() => {
    const newRows = Object.entries(distributionMessages).map(([index, data]) => ({
      id: data.id,
      subject: data.subject,
      timeLeft: data.timeLeft,
      status: data.status
    }));
    setRows(newRows);
  }, [distributionMessages]);

  const showMessage = (msgId: GridRowId): void => {
    props.handleMessageSelect(msgId);
  };

  // display abort email modal if abort email button click for message
  const abortEmail = (id: GridRowId): void => {
    ModalService.openCustomModal(AbortMessageModal, {
      heading: 'distributionHistory.outbox.abort.heading',
      content: 'distributionHistory.outbox.abort.content',
      confirmButton: 'distributionHistory.outbox.abort.confirm',
      msgId: String(id)
    });
  };

  const handleRowHover = (event: React.MouseEvent<HTMLDivElement>): void => {
    const rowElement: HTMLDivElement = event.currentTarget;
    const rowId = rowElement.getAttribute('data-id');
    setRowHovered(rowId);
  }

  const handleRowLeave = (): void => {
    setRowHovered(null);
  }

  return (
    <List sx={{ width: '100%' }}>
      <div className={styles.drawerTab}>
        <DataGrid
          rows={rows}
          columns={getColumns(abortEmail, rowHovered)}
          onSelectionModelChange={(selectionModels: GridSelectionModel) => {
            showMessage(selectionModels[0]);
          }}
          hideFooterSelectedRowCount={true}
          hideFooter={false}
          rowsPerPageOptions={[]}
          disableColumnMenu
          componentsProps={{
            row: {
              onMouseEnter: handleRowHover,
              onMouseLeave: handleRowLeave
            }
          }}
          sx={{
            height: 'calc(100vh - 130px)',
            border: 'none',
            '& .MuiDataGrid-virtualScroller': scrollStyles,
            '& .MuiDataGrid-renderingZone': {
              maxHeight: 'none !important'
            },
            '& .MuiDataGrid-cell': {
              lineHeight: 'unset !important',
              maxHeight: 'none !important',
              whiteSpace: 'normal'
            },
            '& .MuiDataGrid-row': {
              color: 'rgba(0, 0, 0, 0.54)',
              maxHeight: 'none !important'
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#F2F0F0',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.15)',
              fontWeight: 700,
              color: '#222222',
              border: 'none',
              outline: 0
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#F2F0F0',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.15)',
              fontWeight: 700,
              color: '#222222'
            },
            '& .MuiDataGrid-row.Mui-selected:hover': {
              backgroundColor: '#F2F0F0',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.15)',
              fontWeight: 700,
              color: '#222222'
            },
            '& .MuiDataGrid-columnHeader': {
              '& .MuiDataGrid-columnSeparator': {
                display: 'none'
              }
            }
          }}
        />
      </div>
    </List>
  )
};

function getColumns (
  abortEmail: (msgId: GridRowId) => void,
  rowHovered: string | null
): GridColDef[] {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  return [
    {
      field: 'subject',
      flex: 1,
      headerName: t('distributionHistory.outbox.header.subject'),
      renderCell: (params: GridRenderCellParams<string, any, any>) => {
        return (
          <div className={styles.subjectContainer}>
            {params.value}
          </div>
        )
      },
      sortable: false
    },
    {
      field: 'timeLeft',
      flex: 1,
      headerName: t('distributionHistory.outbox.header.scheduled'),
      renderCell: (params: GridRenderCellParams<string, any, any>) => {
        const timeLeft = params.value as string;
        const status: MessageStatus = params.row.status;
        const hasTimeLeft: boolean = parseInt(timeLeft) > 0;
        const className = (rowHovered === params.row.id && hasTimeLeft) ? styles.dataTimeText : '';
        return <div className={styles.outboxSchedule}>
          <span className={className}>{formatTimeLeft(timeLeft, status)}&nbsp;</span>
          {(rowHovered === params.row.id && hasTimeLeft) &&
            <Button
              onClick={() => { abortEmail(params.id); }}
              sx={{
                background: ToastPink,
                color: MainWhite,
                borderRadius: '25px',
                padding: '8px 22px',
                marginLeft: '2px',
                textTransform: 'none',
                '&:hover': {
                  background: ToastPink,
                  color: MainWhite,
                  cursor: 'pointer'
                }
              }}
            >
              {t('action.abort')}
            </Button>
          }
        </div>
      },
      sortable: false
    }
  ].map((column: GridColDef) => {
    column.renderHeader = (params: GridColumnHeaderParams) => (
      <div className={styles.dataGridHeader}>{params.colDef.headerName}</div>
    );
    return column;
  });
}

function formatTimeLeft (value: string, status: MessageStatus): string {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const timeLeft: number = parseInt(value);
  if (status === MessageStatus.FAILED) {
    return String(t('distributionHistory.outbox.failed'));
  }
  if (timeLeft <= 0) {
    return String(t('distributionHistory.outbox.sending')); // set Sending Status
  }
  const minSeconds: number = 60;
  if (timeLeft < minSeconds) {
    return `${String(timeLeft)} ${t('common.time.seconds')}`;
  } else {
    const scheduled: Moment = moment().add(timeLeft, 'seconds');
    return UtilityService.getFormattedDate(scheduled);
  }
}

export default EmailDrawerTab;
