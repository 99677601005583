import React, { lazy, Suspense } from 'react';
import type { TrialBannerProps } from './TrialBanner';

const LazyTrialBanner = lazy(async () => await import('./TrialBanner'));

const TrialBanner = (props: TrialBannerProps) => (
  <Suspense fallback={null}>
    <LazyTrialBanner {...props} />
  </Suspense>
);

export default TrialBanner;
