import type { AttachmentItem } from '../components/saveTemplate/SaveTemplateFlow';
import type { DistributionEmailTemplate } from '../models/DistributionEmailTemplate';
import DistributionService from './distribution/DistributionService';
import type { SelectedGroup, SelectedMember } from '../models/DistributionList';
import type { WSResponse } from '../models/WSResponse';
import { MinAttachmentNumber, MaxAttachmentNumber } from '../components/saveTemplate/SaveTemplateSteps';
import { addDistributionEmailTemplateName, addDistributionEmailTemplate } from '../state/slices/DistributionReducer';
import MessageService from './message/MessageService';
import { DistributionMessagesKeys } from './distribution/constants';
import { setTemplateOperationCompleted, setTemplatesOperationStatus } from '../state/slices/Templates';
import ModalService from './ModalService';
import DocumentService from './documents/DocumentService';
import { GoogleFormOptionsModalId } from '../components/modals/GoogleFormOptions/constants';
import { TemplateDownloadModalId } from '../components/modals/TemplateDownload/constants';
import { store } from '../state/store';
import { t } from 'i18next';

const { dispatch } = store;

const TemplateLink: string = '/templates/documents/';

interface ITemplateService {
  buildTemplate: (options: string[], name: string, selectedItems: AttachmentItem[], getValues: any, contentValue: string) => Promise<WSResponse>
  checkTemplateName: (value: string) => boolean
  checkSelectedInclusions: (options: string[], getValues: any) => string | null
  checkAttachmentOptions: (selectedItems: AttachmentItem[]) => string | null
  openGoogleForm: () => void
  downloadTemplate: (docUrl: string, xlUrl: string) => void
  createTemplateLink: (fileName: string) => string
}

class TemplateService implements ITemplateService {
  async buildTemplate (options: string[], name: string, selectedItems: AttachmentItem[], getValues: any, contentValue: string): Promise<WSResponse> {
    const isSubjectIncluded: boolean = options.includes('subject');
    const isEmailBodyIncluded: boolean = options.includes('emailBody');
    const isSMSIncluded: boolean = options.includes('sms');
    const isRecipientsIncluded: boolean = options.includes('recipients');
    const groupList: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupList;
    const groupDepartment: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupDepartment;
    const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;

    let extraListMembersInputVal: string = '-';
    const partialGroupMembersIds: string[] = DistributionService.fetchPartialGroupsIds(groupList, groupDepartment);
    if (partialGroupMembersIds.length > 0) {
      extraListMembersInputVal = partialGroupMembersIds.join(',');
    }

    const template: DistributionEmailTemplate = {
      templateName: name,
      isSubjectIncluded,
      isEmailBodyIncluded,
      isSMSIncluded,
      isRecipientsIncluded,
      attachmentConfigJSON: JSON.stringify(
        selectedItems.map((item: AttachmentItem) => (
          { quanitity: item.quantity, subcategory: item.subcategory }
        ))
      )
    }

    if (isSubjectIncluded) {
      template.subject = getValues('subject');
    }

    if (isEmailBodyIncluded) {
      template.emailBody = contentValue;
    }

    if (isSMSIncluded) {
      template.smsMessage = getValues('smsmessage');
    }

    if (isRecipientsIncluded) {
      template.distributionListId = DistributionService.fetchCompleteGroups(groupList, []);
      template.departmentId = DistributionService.fetchCompleteGroups([], groupDepartment);
      template.extraEmailsForSelect = selectedExtraEmails.join(',');
      template.extraListMembersInputVal = extraListMembersInputVal;
    }

    return await DistributionService.saveDistributionEmailTemplate(template)
      .then((result: WSResponse) => {
        dispatch(addDistributionEmailTemplateName({ templateName: [template.templateName, template.subject] }));
        dispatch(addDistributionEmailTemplate({ distributionEmailTemplate: template }));
        MessageService.templateToDraft(template, getValues('msgId'));
        return result;
      })
      .catch((err) => {
        console.error(err);
        return { responseCode: '-1' };
      })
  }

  checkTemplateName (value: string): boolean {
    let result: boolean = false;
    if (typeof value === 'string' && value.trim().length > 0) {
      result = true;
    }
    return result;
  }

  checkSelectedInclusions (options: string[], getValues: any): string | null {
    let errorMessage = null;
    if (!options.length) {
      errorMessage = 'compose.saveTemplate.message.selection';
    } else {
      const groupList: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupList;
      const groupDepartment: SelectedGroup[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].groupDepartment;
      const affectedGroupMembers: SelectedMember[] = DistributionService.findAllAffectedDistGroups(groupList, groupDepartment);
      const selectedExtraEmails: string[] = store.getState().draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails;

      if (!((options.includes('recipients') && !(affectedGroupMembers.length === 0 && selectedExtraEmails.length === 0)) ||
        (options.includes('subject') && getValues('subject').trim().length !== 0) ||
        (options.includes('message') && getValues('unsingedMessage').trim().length !== 0) ||
        (options.includes('smsmessage') && getValues('smsmessage').trim().length !== 0))) {
        errorMessage = 'compose.saveTemplate.message.empty';
      }
    }
    return errorMessage;
  }

  checkAttachmentOptions (selectedItems: AttachmentItem[]): string | null {
    let errorMessage = null;
    const categories: string[] = [];
    selectedItems.forEach((item: AttachmentItem) => {
      if (item.checked) {
        if (isNaN(item.quantity) || item.quantity < MinAttachmentNumber || item.quantity > MaxAttachmentNumber) {
          errorMessage = 'compose.saveTemplate.message.attachmentNumber';
        } else if (!item.subcategory) {
          errorMessage = 'compose.saveTemplate.message.attachmentCategoryOne';
        } else if (categories.includes(item.subcategory)) {
          errorMessage = 'compose.saveTemplate.message.attachmentCategoryMultiple';
        } else {
          categories.push(item.subcategory);
        }
      }
    })
    return errorMessage;
  }

  openGoogleForm (): void {
    dispatch(setTemplateOperationCompleted({ operationCompleted: false }));

    DocumentService.fetchReadOnlyGoogleFormURL('crew_intake')
      .then((result: WSResponse) => {
        const formUrl: string = result.responseMessage ?? '';

        dispatch(setTemplateOperationCompleted({ operationCompleted: true }));
        if (formUrl) {
          ModalService.openCustomModal(
            GoogleFormOptionsModalId,
            {
              content: '',
              confirmButton: t('templates.page.confirm').toString(),
              metaData: [formUrl]
            }
          );
        } else {
          throw new Error('error getting url');
        }
      }).catch((err) => {
        console.error(err.message);
        dispatch(setTemplatesOperationStatus({
          toastOpen: true,
          operationStatus: 'error',
          operationMessage: t('templates.page.errorLoadingFormData'),
          operationCompleted: true
        }));
      });
  }

  createTemplateLink (fileName: string): string {
    return window.location.origin + TemplateLink + fileName;
  }

  downloadTemplate (docUrl: string, xlUrl: string): void {
    // If both links are present - open modal with format picker
    if (docUrl && xlUrl) {
      ModalService.openCustomModal(
        TemplateDownloadModalId,
        {
          content: '',
          confirmButton: t('actions.confirm').toString(),
          metaData: [xlUrl, docUrl]
        }
      );
    } else if (docUrl && !xlUrl) { // Only DOC
      window.location.href = this.createTemplateLink(docUrl);
    } else if (xlUrl && !docUrl) { // Only EXCEL
      window.location.href = this.createTemplateLink(xlUrl);
    }
  }
}

const templateService: ITemplateService = new TemplateService();
export default templateService;
