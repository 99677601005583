import React, { lazy, Suspense } from 'react';
import type { TemplateFolderProps } from './TemplateFolders';
const LazyTemplateFolders = lazy(async () => await import('./TemplateFolders'));

const TemplateFolders = (props: TemplateFolderProps) => (
  <Suspense fallback={null}>
    <LazyTemplateFolders {...props} />
  </Suspense>
);

export default TemplateFolders;
