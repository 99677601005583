import React, { lazy, Suspense } from 'react';
import type { ContactListsTableProps } from './ContactListsTable';

const LazyContactListsTable = lazy(async () => await import('./ContactListsTable'));

const ContactListsTable = (props: ContactListsTableProps) => (
  <Suspense fallback={null}>
    <LazyContactListsTable {...props} />
  </Suspense>
);

export default ContactListsTable;
