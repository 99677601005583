import React, { lazy, Suspense } from 'react';
import type { DistributionEditorProps } from './DistributionEditor';

const LazyDistributionEditor = lazy(async () => await import('./DistributionEditor'));

const DistributionEditor = (props: DistributionEditorProps) => (
  <Suspense fallback={null}>
    <LazyDistributionEditor {...props} />
  </Suspense>
);

export default DistributionEditor;
