import type { FC, SyntheticEvent, Dispatch, SetStateAction } from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List } from '@mui/material';
import type { RootState } from '../../state/store';
import TabItem from './TabItem';
import TabPanel from '../slideDrawer/TabPanel';
import TabHeaders from '../slideDrawer/TabHeaders/TabHeaders.lazy';
import DistributionService from '../../services/distribution/DistributionService';
import type { DistributionGroupLists, DistributionContact } from '../../models/DistributionList';
import scrollStyles from '../../theme/sidebar.module.scss';
import styles from './DistributionLists.module.scss';

export interface DrawerTabsProps {
  tab: number
  onChange: (event: React.SyntheticEvent, newTab: number) => void
  handleDistributionSelect: (value: string) => void
  handleContactSelect: (value: string) => void
  selectedList: string
  selectedContact: string
  setSelected: Dispatch<SetStateAction<string[]>>
}

/**
 * Drawer Tabs Component for the Slide Drawer Component in Distribution Lists
 */

const DrawerTabs: FC<DrawerTabsProps> = (props: DrawerTabsProps) => {
  const [distributionList, setDistributionList] = useState<DistributionGroupLists>({});
  const [contactList, setContactList] = useState<DistributionContact[]>([]);

  const distributionGroupList: DistributionGroupLists = useSelector((state: RootState) => state.distribution.distributionGroupList);
  const distributionContactList: DistributionContact[] = useSelector((state: RootState) => state.distribution.distributionContactList);

  useEffect(() => {
    if (distributionContactList.length > 0) {
      setContactList(distributionContactList);
      if (!props.selectedContact) {
        const value: string = distributionContactList[0].value;
        props.handleContactSelect(value);
        fetchDistributionListsWithContact(value);
      }
    }

    if (Object.keys(distributionGroupList).length > 0) {
      setDistributionList(distributionGroupList);
      if (!props.selectedList) {
        const value: string = Object.keys(distributionGroupList)[0];
        props.handleDistributionSelect(value);
        fetchDistributionListMembers(value);
      }
    }
  }, [distributionContactList, distributionGroupList]);

  const tabs: string[] = [
    'slideDrawer.tabs.listTab',
    'slideDrawer.tabs.contactsTab'
  ];

  const handleDistributionSelect = (event: SyntheticEvent, value: string): void => {
    event.stopPropagation();
    if (value !== props.selectedList) {
      props.handleDistributionSelect(value);
      props.setSelected([]);
      fetchDistributionListMembers(value);
    }
  }

  const handleContactSelect = (event: SyntheticEvent, value: string): void => {
    event.stopPropagation();
    if (value !== props.selectedContact) {
      props.handleContactSelect(value);
      props.setSelected([]);
      fetchDistributionListsWithContact(value);
    }
  }

  const fetchDistributionListMembers = (distributionListId: string): void => {
    DistributionService.fetchDistributionListMembers(distributionListId)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
  }

  const fetchDistributionListsWithContact = (personId: string): void => {
    DistributionService.fetchDistributionListsWithContact(personId)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      })
  }

  return (
    <>
      <TabHeaders tab={props.tab} onChange={props.onChange} tabs={tabs} centerHeaders={true} />
      <TabPanel value={props.tab} index={0}>
        <List className={`${scrollStyles.scrollbar} ${styles.sideBarList}`}>
          {Object.keys(distributionList).map((value: string) => {
            const distribution: string = distributionList[value];
            return (
              <TabItem
                key={`list-${value}`}
                value={value}
                label={distribution}
                handleItemSelect={handleDistributionSelect}
                selected={(props.selectedList === value)}
              />
            )
          })}
        </List>
      </TabPanel>
      <TabPanel value={props.tab} index={1}>
      <List className={`${scrollStyles.scrollbar} ${styles.sideBarList}`}>
        {contactList.map((contact: DistributionContact) => {
          const value: string = contact.value;
          const label: string = contact.label;
          return (
            <TabItem
              key={`contact-${value}`}
              value={value}
              label={label}
              handleItemSelect={handleContactSelect}
              selected={(props.selectedContact === value)}
            />
          )
        })}
      </List>
      </TabPanel>
    </>
  )
}

export default DrawerTabs;
