import React, { lazy, Suspense } from 'react';

const LazyModifyContact = lazy(async () => await import('./ModifyContact'));

const ModifyContact = () => (
  <Suspense fallback={null}>
    <LazyModifyContact />
  </Suspense>
);

export default ModifyContact;
