import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { IconButton, Box, Popover, Grid } from '@mui/material';
import {
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter
} from '@mui/icons-material';

import { CrooglooGreen, MainBlack } from '../../theme/colors';
import useStyles from './editorStyles';

interface Props {
  handleAlignSelect: (align: string) => void
  isActive: (align: 'left' | 'center' | 'right') => boolean
  activeAlign: 'left' | 'center' | 'right'
}
export const AlignButton: FC<Props> = ({
  handleAlignSelect,
  isActive,
  activeAlign
}: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentAlign, setCurrentAlign] = useState<'left' | 'center' | 'right'>('left');

  useEffect(() => {
    if (isActive(activeAlign)) {
      setCurrentAlign(activeAlign);
    } else {
      setCurrentAlign('left');
    }
  }, [activeAlign]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  }

  const handleClose = (): void => {
    setIsOpen(false);
  }

  const onSelect = (align: string): void => {
    handleAlignSelect(align);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <IconButton
        onMouseDown={handleOpen}
        className={classes.button}
        disableRipple
      >
        {(currentAlign === 'left') && <FormatAlignLeft />}
        {(currentAlign === 'center') && <FormatAlignCenter />}
        {(currentAlign === 'right') && <FormatAlignRight />}
      </IconButton>
      <Popover
        id={'simple-popover'}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Grid
          sx={{
            display: 'grid',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2px',
            gap: '5px'
          }}
        >
          <Grid>
            <IconButton
              disableRipple
              className={classes.button}
              sx={{
                color: (currentAlign === 'left') ? CrooglooGreen : MainBlack
              }}
              onClick={() => onSelect('left')}
            >
              <FormatAlignLeft />
            </IconButton>
          </Grid>
          <Grid>
            <IconButton
              disableRipple
              className={classes.button}
              sx={{
                color: (currentAlign === 'center') ? CrooglooGreen : MainBlack
              }}
              onClick={() => onSelect('center')}
            >
              <FormatAlignCenter />
            </IconButton>
          </Grid>
          <Grid>
            <IconButton
              disableRipple
              className={classes.button}
              sx={{
                color: (currentAlign === 'right') ? CrooglooGreen : MainBlack
              }}
              onClick={() => onSelect('right')}
            >
              <FormatAlignRight />
            </IconButton>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  )
}

export default AlignButton;
