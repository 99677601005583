import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import SlideDrawer from '../slideDrawer/SlideDrawer';
import DistributionLists from './DistributionLists';
import type { Anchor } from '../../models/Anchor';
import DrawerTabs from './DrawerTabs';
import MainSection from '../common/MainSection';
import type { ComboValueParameters } from '../../models/ComboValues';
import DistributionService from '../../services/distribution/DistributionService';
import ModifyContact from '../modals/ModifyContact/ModifyContact.lazy';
import AddDistributionList from '../modals/AddDistributionList/AddDistributionList.lazy';
import MergeDistributionList from '../modals/MergeDistributionList/MergeDistributionList.lazy';
import ExportDistributionList from '../modals/ExportDistributionList/ExportDistributionList.lazy';
import CompareLists from '../modals/CompareLists/CompareLists.lazy';
import DeleteList from '../modals/DeleteList/DeleteList.lazy';
import ExportOptions from '../modals/ExportOptions/ExportOptions.lazy';
import { drawerWidth } from '../../theme/styles';
import LoadingProgess from '../common/LoadingProgress/LoadingProgress.lazy';

export enum DistributionListsPage {
  Title = 'Distribution Lists'
}

export interface Props {
  pageTitle: DistributionListsPage
}

const anchor: Anchor = 'left';

export const DefaultComboParamsLists: ComboValueParameters = {
  entityName: 'distribution_list',
  filterAttribute: '-',
  filterValue: '-',
  valueAttrib: 'distributionListId',
  displayAttrib: 'distributionListName',
  sortAttrib: 'distributionListName'
}
export const DistributionGroupList: string = 'distributionGroupList';

const DistributionListsIndex: FC<Props> = ({ pageTitle }) => {
  const dispatch = useAppDispatch();

  // Whether Slide Drawer is open or not
  const [open, setOpen] = useState<boolean>(true);
  // the varaible holding what current tab is open
  const [tab, setTab] = useState<number>(0);
  const [selectedContact, setSelectedContact] = useState<string>('');
  const [selectedList, setSelectedList] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    getDistributionCombos(DefaultComboParamsLists, DistributionGroupList);
    getDistributionCombos({
      entityName: 'PERSON',
      filterAttribute: '-',
      filterValue: '-',
      valueAttrib: 'id',
      displayAttrib: 'title',
      sortAttrib: 'title'
    }, 'distributionContactList')
  }, [pageTitle]);

  const getDistributionCombos = (params: ComboValueParameters, listId: string): void => {
    DistributionService.getComboValues(params, listId)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }

  const handleDistributionSelect = (value: string): void => {
    setSelectedList(value);
  }

  const handleContactSelect = (value: string): void => {
    setSelectedContact(value);
  }

  const drawerDistributionSelect = (listSelected: string): void => {
    setIsLoading(true);
    handleDistributionSelect(listSelected);
  }

  const drawerContactSelect = (contactSelect: string): void => {
    setIsLoading(true);
    handleContactSelect(contactSelect);
  }

  // functions to handle opening and closing of drawer
  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  // handling the tab change event
  const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    setTab(newTab);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
        <SlideDrawer
          drawerWidth={drawerWidth} open={open}
          toggleDrawer={toggleDrawer}
          anchor={anchor}
        >
          <DrawerTabs
            tab={tab}
            onChange={handleTabChange}
            handleDistributionSelect={drawerDistributionSelect}
            handleContactSelect={drawerContactSelect}
            selectedList={selectedList}
            selectedContact={selectedContact}
            setSelected={setSelected}
          />
        </SlideDrawer>
        <MainSection open={open} drawerwidth={drawerWidth} sx={{ height: '100vh' }}>
          <LoadingProgess isLoading={isLoading} />
          <DistributionLists
            tabIndex={tab}
            selectedContact={selectedContact}
            selectedList={selectedList}
            handleDistributionSelect={handleDistributionSelect}
            handleContactSelect={handleContactSelect}
            setLoading={setIsLoading}
            drawerOpen={open}
            selected={selected}
            setSelected={setSelected}
          />
          <ModifyContact />
          <AddDistributionList handleDistributionSelect={drawerDistributionSelect} />
          <MergeDistributionList handleDistributionSelect={drawerDistributionSelect} />
          <ExportDistributionList />
          <DeleteList />
          <CompareLists />
          <ExportOptions />
        </MainSection>
      </Box>
  )
};

export default DistributionListsIndex;
