import React, { lazy, Suspense } from 'react';

const LazyDeleteList = lazy(async () => await import('./DeleteList'));

const DeleteList = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyDeleteList {...props} />
  </Suspense>
);

export default DeleteList;
