import { useSlate } from 'slate-react';
import { IconButton } from '@mui/material';

export type MarkFormat = 'bold' | 'italic' | 'underline';

interface ButtonProps {
  format: string
  Icon: React.ElementType
  style: string
  toggleMark: (editor: any, format: MarkFormat) => void
}

const MarkButton = ({ format, Icon, style, toggleMark }: ButtonProps) => {
  const editor = useSlate();
  return (
    <IconButton
      onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        toggleMark(editor, format as MarkFormat);
      }}
      disableRipple
      className={style}
    >
      <Icon />
    </IconButton>
  )
}

export default MarkButton;
