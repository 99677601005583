import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import type { BadgeProps } from '@mui/material/Badge';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAppSelector, useAppDispatch } from '../../state/hooks';
import styles from './Header.module.scss';
import { BorderBlack, CrooglooGreen, LightGrey, MainWhite, WhiteGrey } from '../../theme/colors';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import OPButton from '../common/buttons/OPButton';
import AuthService from '../../services/auth/AuthService';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SuccessButton from '../common/buttons/SuccessButton';
import UploadIcon from '@mui/icons-material/Upload';
import FileUpload from '../fileUpload/FileUpload';
import type { RootState } from '../../state/store';
import WatermarkNotifications from '../../services/notifications/WatermarkNotifications';
import DownloadNotifications from '../../services/notifications/DownloadNotifications';
import NotificationMenu from '../NotificationMenu/NotificationMenu.lazy';
import NotificationUtility from '../../services/notifications/NotificationUtility';
import UploadBoxes from '../UploadBoxes/UploadBoxes.lazy';
import ExpiredContacts from '../modals/ExpiredContacts/ExpiredContacts';
import type { AuthUser } from '../../services/auth/constants';
import DocumentService from '../../services/documents/DocumentService';
import { Notifications } from 'react-push-notification';
import type { OptionProps } from '../../models/OptionProps';
import { ImportFileModal } from '../modals/ImportFile/constants';
import ImportFile from '../modals/ImportFile/ImportFile.lazy';
import ModalService from '../../services/ModalService';
import TemplateService from '../../services/TemplateService';
import PersonService from '../../services/person/PersonService';
import DistributionService from '../../services/distribution/DistributionService';
import VCardDetails from '../modals/vCardDetails/vCardDetails.lazy';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import { GoogleRequestType } from '../../models/Google';
import Toast from '../common/toast/Toast';
import type { ToastProps } from '../../models/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import type { AlertColor, SxProps, Theme } from '@mui/material';
import { CrooglooUrl } from '../../models/CrooglooUrl';
import crooglooLogo from '../../assets/icons/croogloo-logo.png';
import { DashboardPage } from '../Dashboard/Dashboard';
import SubscriptionManagerService from '../../services/subscription/SubscriptionManagerService';
import ManageSubscription from '../modals/ManageSubscription/ManageSubscription.lazy';
import CancelNotAvailable from '../modals/CancelNotAvailable/CancelNotAvailable.lazy';
import ConfirmCancellation from '../modals/ConfirmCancellation/ConfirmCancellation.lazy';
import CancelSuccess from '../modals/CancelSuccess/CancelSuccess.lazy';
import SwitchProduction from '../modals/SwitchProduction/SwitchProduction.lazy';
import { setResetForm } from '../../state/slices/Contacts';
import UploadFlow from '../fileUpload/UploadFlow/UploadFlow';
import ExpiredTrial from '../modals/ExpiredTrial/ExpiredTrial.lazy';
import ActivateSubscriptionCancel from '../modals/ActivateSubscriptionCancel/ActivateSubscriptionCancel.lazy';
import ActivateSubscriptionFail from '../modals/ActivateSubscriptionFail/ActivateSubscriptionFail.lazy';
import ActivateSubscriptionSuccess from '../modals/ActivateSubscriptionSuccess/ActivateSubscriptionSuccess.lazy';
import StripePayment from '../modals/StripePayment/StripePayment.lazy';
import ActivateTrial from '../modals/ActivateTrial/ActivateTrial';

// TODO: When https://support.croogloo.com/fr/ is finished change below according to users language
const SupportLink: string = 'https://support.croogloo.com/en/';
const LogoutText: string = 'header.logout';
// const MyAccountLink: string = 'user-settings/my-account'; // TODO: leave commented out for now until my account is added back in
// const AddProductionText: string = 'header.addProduction';
const SwitchProductionText: string = 'header.switchProduction';
const UploadText: string = 'action.upload';
const ContactImportExcel: string = 'Croogloo Contact Import.xlsx';
const ManageSubscriptionText: string = 'header.manageSubscription';
const EndSubscriptionText: string = 'header.endSubscription';
const ManageSubscriptionId: string = 'header-manageSubscription';
const EndSubscriptionId: string = 'header-endSubscription';
const HeaderSettingsId: string = 'header-settings';
// const HeaderAddProductionId: string = 'header-addProduction';
const HeaderSwitchProductionId: string = 'header-switchProduction';

const enum AddContactValues {
  AddContact = 'addContact',
  GoogleImport = 'googleImport',
  VCard = 'vCard',
  Template = 'template'
}

export default function HeaderAppBar (props: any) {
  // Translation fct
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const originalMenuName = t('header.guest');

  useEffect(() => {
    WatermarkNotifications.load();
    DownloadNotifications.load();
    DocumentService.fetchAllFolders();
    PersonService.fetchDepartments();
    DistributionService.fetchDistributionLists();
    PersonService.getExpiringContacts();
  }, [])

  const preUserMenuOptions = [{
    id: HeaderSettingsId,
    text: t('header.settings')
  },
  // {
  //   id: HeaderAddProductionId,
  //   text: t(AddProductionText)
  // },
  {
    id: HeaderSwitchProductionId,
    text: t(SwitchProductionText)
  }, {
    id: ManageSubscriptionId,
    text: t(ManageSubscriptionText)
  }, {
    id: EndSubscriptionId,
    text: t(EndSubscriptionText)
  }];

  const addContactOptions: OptionProps[] = [{
    key: 'add-contact',
    label: t('header.addContact'),
    value: AddContactValues.AddContact
  }, {
    key: 'add-google',
    label: t('header.googleImport'),
    value: AddContactValues.GoogleImport
  }, {
    key: 'add-vCard',
    label: t('header.vCard'),
    value: AddContactValues.VCard
  }, {
    key: 'add-template',
    label: t('header.template'),
    value: AddContactValues.Template
  }];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);
  const [addContactAnchorEl, setAddContactAnchorEl] = React.useState<null | HTMLElement>(null);
  const [badgeCount, setBadgeCount] = React.useState<number>(0);
  const [fullNameMenu, setFullNameMenu] = React.useState<string>(originalMenuName);
  const [userMenuOptions, setUserMenuOptions] = React.useState(preUserMenuOptions)
  const [menuInitials, setMenuInitials] = React.useState<string>('');
  const [vCards, setVCards] = React.useState<File[]>([]);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleShowToast = (message: string, type: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message: t(message),
      type,
      icon,
      isShown: true
    });
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const showTrialEndedModal = (): void => {
    void SubscriptionManagerService.endTrial();
  }

  const showActivateTrialModal = (): void => {
    void SubscriptionManagerService.activateTrial();
  }

  // function to handle different options in the user menu
  const handleUserMenuOption = (option: string) => {
    switch (option) {
      case HeaderSettingsId:
        navigate(CrooglooUrl.USERSETTINGS);
        break;
      case ManageSubscriptionId:
        SubscriptionManagerService.manageSubscription()
          .then(() => {})
          .catch((err) => {
            console.error(err)
          });
        break;
      case EndSubscriptionId:
        SubscriptionManagerService.wrapUpSubscription()
          .then(() => {})
          .catch((err) => {
            console.error(err);
            handleShowToast(err.message, 'error', ToastIcons.Info);
          });
        break;
      case HeaderSwitchProductionId:
        try {
          void SubscriptionManagerService.showSwitchProduction();
        } catch (e) {
          handleShowToast('production.switch.error.none', 'error', ToastIcons.Info);
        }
        break;
      default:
        console.debug(option);
    }
    handleMoreClose();
    handleMenuClose();
  }

  const handleAddContactOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAddContactAnchorEl(event.currentTarget);
  }

  const handleAddContactClose = () => {
    setAddContactAnchorEl(null);
  }

  const addContactForm = (): void => {
    const currentUrl: CrooglooUrl = location.pathname as CrooglooUrl;
    if (currentUrl === CrooglooUrl.ADDCONTACT) {
      dispatch(setResetForm({ resetForm: true }));
    } else {
      navigate(CrooglooUrl.ADDCONTACT);
    }
  }

  const handleAddContactSelect = (value: string) => {
    switch (value) {
      case AddContactValues.AddContact:
        addContactForm();
        break;
      case AddContactValues.VCard:
        ModalService.openCustomModal(ImportFileModal, {});
        break;
      case AddContactValues.Template:
        window.location.href = TemplateService.createTemplateLink(ContactImportExcel);
        navigate(CrooglooUrl.UPLOAD);
        break;
    }
    handleAddContactClose();
  }

  const handleMoreOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreAnchorEl(event.currentTarget);
  }

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  }

  const handleLogout = () => {
    AuthService.logout()
      .then(() => {
        navigate(CrooglooUrl.LOGIN);
      })
      .catch(() => {
        navigate(CrooglooUrl.LOGIN);
      });
  };

  const handleUpload = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    fileInputRef.current?.click();
  }

  const openNotifications = (event: React.MouseEvent<HTMLElement>): void => {
    NotificationUtility.resetCounter();
    setNotificationAnchorEl(event.currentTarget);
  }

  const closeNotification = (): void => {
    setNotificationAnchorEl(null);
  }

  const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    marginLeft: '16px',
    width: '30px',
    height: '30px',
    color: BorderBlack,
    backgroundColor: WhiteGrey,
    '&:hover': {
      backgroundColor: LightGrey
    },
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'
  }));

  const IconStyles: SxProps<Theme> = {
    height: '0.875em'
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 0,
      top: 0,
      backgroundColor: CrooglooGreen,
      color: MainWhite
    }
  }));

  const StyledSmallBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -17,
      top: 13,
      backgroundColor: CrooglooGreen,
      color: MainWhite
    }
  }));

  const pageTitle: string = useAppSelector((state: RootState) => state.pageTitle ? state.pageTitle.value : '');
  const { notifications, count } = useAppSelector((state: RootState) => state.notifications);
  const crooglooAuth: AuthUser = useAppSelector((state: RootState) => state.auth.crooglooauth);

  const headerStyle = props.useBackground ? styles.appBarWithBackground : styles.appBar;

  useEffect(() => {
    setBadgeCount(count);
  }, [count]);

  useEffect(() => {
    if (crooglooAuth.firstName) {
      const fullName: string = crooglooAuth.firstName + ' ' + (crooglooAuth.lastName ?? '');
      const initials: string = crooglooAuth.firstName.charAt(0) + (crooglooAuth.lastName ? crooglooAuth.lastName.charAt(0) : '');
      setFullNameMenu(fullName);
      setMenuInitials(initials);
    }
    // filter out user menu options depending on auth
    let menuOptions = [...preUserMenuOptions];
    if ((crooglooAuth.productionOwnerEmail !== crooglooAuth.email)) {
      menuOptions = menuOptions.filter(option => ![ManageSubscriptionId].includes(option.id));
    }
    if (crooglooAuth.isTrial) {
      menuOptions = menuOptions.filter(option => ![EndSubscriptionId].includes(option.id));
      if (!crooglooAuth.trialDaysLeft) {
        showTrialEndedModal();
      } else {
        showActivateTrialModal();
      }
    }
    if (!SubscriptionManagerService.accessToOtherCommunities()) {
      menuOptions = menuOptions.filter(option => ![HeaderSwitchProductionId].includes(option.id));
    }
    setUserMenuOptions(menuOptions);
  }, [crooglooAuth]);

  return (
    <Box sx={{ flexGrow: 1 }} className={styles.header}>

      <AppBar sx={{ bgcolor: MainWhite, boxShadow: 'none' }} className={headerStyle} position="static">

        <Toolbar className={`${styles.toolBar}`}>
          <Box className={styles.headerLogo}>
          { pageTitle === DashboardPage.Title && <img src={crooglooLogo} alt={'CrooGloo'} style={{ float: 'left', width: '80px', marginLeft: '10px' }}/> }
          </Box>
          <Box className={styles.uploadWrap}>
          <FileUpload fileInputRef={fileInputRef} disableDragging={true}>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, marginBottom: '15px' }}>

              <Box component={'div'}>
                <SuccessButton
                  id={'header-upload-button'}
                  disableRipple
                  onClick={handleUpload}
                  startIcon={<UploadIcon />}
                  sx={{ height: '30px' }}
                >
                  {t(UploadText)}
                </SuccessButton>
              </Box>

              <Box component={'div'} onClick={handleAddContactOpen}>
                <StyledIconButton>
                  <PersonAddIcon sx={IconStyles} />
                </StyledIconButton>
              </Box>
                <Menu
                  sx={{ mt: '55px', ml: '-177px' }}
                  id="menu-addContact"
                  anchorEl={addContactAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(addContactAnchorEl)}
                  onClose={handleAddContactClose}
                >
                  {addContactOptions.map((option: OptionProps) => {
                    if (option.value === AddContactValues.GoogleImport) {
                      return (
                        <GoogleLogin key={`contact-${option.key}`} handleShowToast={handleShowToast} requestType={GoogleRequestType.Contacts}>
                          <MenuItem key={`contact-${option.key}`} onClick={() => handleAddContactSelect(option.value)}>
                            <Typography textAlign="left" fontWeight={400}>{option.label}</Typography>
                          </MenuItem>
                        </GoogleLogin>
                      )
                    } else {
                      return (
                        <MenuItem key={`contact-${option.key}`} onClick={() => handleAddContactSelect(option.value)}>
                          <Typography textAlign="left" fontWeight={400}>{option.label}</Typography>
                        </MenuItem>
                      )
                    }
                  })}
                </Menu>

              <Box component={'div'} onClick={openNotifications}>
                <StyledIconButton>
                  <StyledBadge anchorOrigin={{ vertical: 'top', horizontal: 'left' }} badgeContent={badgeCount}>
                    <NotificationsNoneIcon sx={IconStyles} />
                  </StyledBadge>
                </StyledIconButton>
              </Box>

              <NotificationMenu
                notifications={notifications}
                anchorEl={notificationAnchorEl}
                handleMenuClose={closeNotification}
              />

              <Link target="_blank" href={SupportLink}>
                <StyledIconButton>
                  <QuestionMarkIcon sx={IconStyles} />
                </StyledIconButton>
              </Link>

              <Box component={'div'} sx={{ marginLeft: 2 }}>
                <OPButton
                  onClick={handleProfileMenuOpen}
                  initials={menuInitials}
                  isButton={true}
                  width={30}
                  height={30}
                  fontSize={'0.875rem'}
                />
              </Box>

              <Menu
                sx={{
                  '& .MuiList-root': {
                    width: '200px'
                  }
                }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  sx={{
                    display: 'block',
                    textAlign: 'center',
                    '&:hover': {
                      cursor: 'default',
                      backgroundColor: 'transparent'
                    }
                  }}
                  onClick={handleMenuClose}
                  disableRipple
                >
                  <OPButton initials={menuInitials} isButton={false} />
                  <Typography textAlign="center" fontWeight={700}>{fullNameMenu}</Typography>
                </MenuItem>
                {userMenuOptions.map((option) => (
                    <MenuItem key={option.text} onClick={() => handleUserMenuOption(option.id)} disableRipple>
                    <Typography textAlign="center" className={styles.navItem}>{option.text}</Typography>
                  </MenuItem>
                ))}
                <MenuItem key="Logout" onClick={handleLogout}>
                  <Typography textAlign="center" className={styles.navItem}>{t(LogoutText)}</Typography>
                </MenuItem>
              </Menu>

            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' }, alignSelf: 'end', marginBottom: '15px' }}>
              <Box component={'div'} onClick={handleMoreOpen}>
                <StyledIconButton>
                  <MoreHorizIcon />
                </StyledIconButton>
              </Box>
              <Menu
                sx={{ mt: '55px', ml: '-16px' }}
                id="more-appbar"
                anchorEl={moreAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(moreAnchorEl)}
                onClose={handleMoreClose}
              >
                <MenuItem onClick={handleUpload}>
                  <Typography>{t(UploadText)}</Typography>
                </MenuItem>
                <MenuItem>
                  <StyledSmallBadge badgeContent={17}>
                    <Typography textAlign="center">{t('header.notifications')}</Typography>
                  </StyledSmallBadge>
                </MenuItem>
                {userMenuOptions.map((option) => (
                  <MenuItem key={option.text} onClick={() => handleUserMenuOption(option.id)} disableRipple>
                    <Typography textAlign="center" className={styles.navItem}>{option.text}</Typography>
                  </MenuItem>
                ))}
                <MenuItem>
                  <NavLink target="_blank" to={SupportLink} className={styles.navLink}>
                    <Typography textAlign="center" className={styles.navItem}>{t('header.help')}</Typography>
                  </NavLink>
                </MenuItem>
                <MenuItem key="Logout" onClick={handleLogout}>
                  <Typography textAlign="center" className={styles.navItem}>{t(LogoutText)}</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </FileUpload>
          </Box>
        </Toolbar>
      </AppBar>
      <Notifications position={'bottom-right'} />
      <UploadBoxes />
      <ExpiredContacts />
      <ImportFile setVCards={setVCards} />
      <VCardDetails vCards={vCards} />
      <ManageSubscription />
      <CancelNotAvailable />
      <ConfirmCancellation />
      <CancelSuccess />
      <SwitchProduction />
      <ExpiredTrial />
      <ActivateSubscriptionCancel />
      <ActivateSubscriptionFail />
      <ActivateSubscriptionSuccess />
      <StripePayment />
      <ActivateTrial />
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={toastStatus.message}
        icon={toastStatus.icon}
      />
      <UploadFlow />
    </Box>
  );
}
