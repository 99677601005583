import type { OptionProps } from '../../models/OptionProps';
import { RemoveWatermarkModal } from '../modals/RemoveWatermark/constants';
import { MoveDocumentModal } from '../modals/MoveDocument/constants';
import { ESignatureModal } from '../modals/ESignature/constants';
import { DeleteDocumentModal } from '../modals/DeleteDocument/constants';
import { ParseFileModal } from '../DocumentViewer/ParseFile/constants';
import { ApplyWatermarkModal } from '../modals/ApplyWatermark/constants';
import { RenameFileModal } from '../modals/RenameFile/constants';
import { EditFileCategoryModal } from '../modals/EditFileCategory/constants';
import { AccessLevelModal } from '../modals/AccessLevel/constants';
import PermissionService, { AccessLevel } from '../../services/auth/PermissionService';
import { SecurityPageName } from '../../services/auth/constants';
import { ToastPink } from '../../theme/colors';

export const PrintWatermarkKey: string = 'option-printWatermark';
export const RemoveWatermarkKey: string = 'option-removeWatermark';
export const ApplyWatermarkKey: string = 'option-applyWatermark';
export const OptionFolderKey: string = 'option-folder';
export const SendByEmailValue: string = 'sendByEmail';
export const DropboxLinkValue: string = 'dropboxLink';
export const GDriveLinkValue: string = 'gDriveLink';
export const BoxLinkValue: string = 'boxLink';
export const OptionDropboxLinkKey: string = 'option-dropbox-link';
export const OptionDropboxUnlinkKey: string = 'option-dropbox-unlink';
export const OptionGDriveLinkKey: string = 'option-gdrive-link';
export const OptionGDriveUnLinkKey: string = 'option-gdrive-unlink';
export const OptionBoxLinkKey: string = 'option-box-link';
export const OptionBoxUnlinkKey: string = 'option-box-unlink'
export const OptionArchiveKey: string = 'option-archive';
export const OptionRecoverKey: string = 'option-recover';
export const OptionParseKey: string = 'option-parse';
export const DropboxDownloadValue: string = 'dropboxDownload';
export const BoxDownloadValue: string = 'boxDownload';
export const DropboxImportValue: string = 'dropboxImport';
export const GoogleImportValue: string = 'googleImport';
export const BoxImportValue: string = 'boxImport';

// the options available for folders in the documents table
export const DocumentFolderOptions: OptionProps[] = [
  {
    key: 'option-move',
    label: 'documents.more.move',
    value: MoveDocumentModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-rename',
    label: 'documents.more.rename',
    value: RenameFileModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-downloadZip',
    label: 'documents.more.downloadZip',
    value: 'downloadZip'
  },
  {
    key: 'option-accessLevel',
    label: 'documents.more.accessLevel',
    value: AccessLevelModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-delete',
    label: 'documents.more.delete',
    value: DeleteDocumentModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE),
    sx: { '&:hover': { color: ToastPink } }
  }
];

// the options available for files in the documents table
export const DocumentFileOptions: OptionProps[] = [
  {
    key: 'option-sendByEmail',
    label: 'documents.more.sendByEmail',
    value: SendByEmailValue,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.Compose)
  },
  {
    key: RemoveWatermarkKey,
    label: 'documents.more.removeWatermark',
    value: RemoveWatermarkModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: ApplyWatermarkKey,
    label: 'documents.more.applyWatermark',
    value: ApplyWatermarkModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: PrintWatermarkKey,
    label: 'documents.more.printWatermark',
    value: 'printWatermark',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE, SecurityPageName.Watermark)
  },
  {
    key: 'option-rename',
    label: 'documents.more.rename',
    value: RenameFileModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-move',
    label: 'documents.more.move',
    value: MoveDocumentModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-merge',
    label: 'documents.more.merge',
    value: 'merge',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-editCategory',
    label: 'documents.more.editCategory',
    value: EditFileCategoryModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-accessLevel',
    label: 'documents.more.accessLevel',
    value: AccessLevelModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: OptionParseKey,
    label: 'documents.more.parse',
    value: ParseFileModal
  },
  {
    key: 'option-download',
    label: 'documents.more.download',
    value: 'download'
  },
  {
    key: OptionArchiveKey,
    label: 'documents.more.archive',
    value: 'archive',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: OptionRecoverKey,
    label: 'documents.more.recover',
    value: 'recover',
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
  },
  {
    key: 'option-delete',
    label: 'documents.more.delete',
    value: DeleteDocumentModal,
    hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE),
    sx: { '&:hover': { color: ToastPink } }
  }
];

export const DocumentDropboxOptions: OptionProps[] = [
  {
    key: OptionDropboxUnlinkKey,
    label: 'documents.more.unlink',
    value: 'dropboxUnlink'
  },
  {
    key: OptionDropboxLinkKey,
    label: 'documents.more.link',
    value: DropboxLinkValue
  }
];

export const DropboxFileOptions: OptionProps[] = [
  {
    key: 'option-download',
    label: 'documents.more.download',
    value: DropboxDownloadValue
  },
  {
    key: 'option-import',
    label: 'documents.more.importCroogloo',
    value: DropboxImportValue
  }
];

export const DropboxFolderOptions: OptionProps[] = [
  {
    key: 'option-import',
    label: 'documents.more.importCroogloo',
    value: DropboxImportValue
  }
];

export const DocumentGDriveOptions: OptionProps[] = [
  {
    key: OptionGDriveUnLinkKey,
    label: 'documents.more.unlink',
    value: 'gDriveUnlink'
  },
  {
    key: OptionGDriveLinkKey,
    label: 'documents.more.link',
    value: GDriveLinkValue
  }
];

export const GDriveFileOptions: OptionProps[] = [
  {
    key: 'option-download',
    label: 'documents.more.download',
    value: 'gDriveDownload'
  },
  {
    key: 'option-import',
    label: 'documents.more.importCroogloo',
    value: GoogleImportValue
  }
];

export const GDriveFolderOptions: OptionProps[] = [
  {
    key: 'option-import',
    label: 'documents.more.importCroogloo',
    value: GoogleImportValue
  }
];

export const DocumentBoxOptions: OptionProps[] = [
  {
    key: OptionBoxUnlinkKey,
    label: 'documents.more.unlink',
    value: 'boxUnlink'
  },
  {
    key: OptionBoxLinkKey,
    label: 'documents.more.link',
    value: BoxLinkValue
  }
];

export const BoxFileOptions: OptionProps[] = [
  {
    key: 'option-download',
    label: 'documents.more.download',
    value: BoxDownloadValue
  },
  {
    key: 'option-import',
    label: 'documents.more.importCroogloo',
    value: BoxImportValue
  }
];

export const BoxFolderOptions: OptionProps[] = [
  {
    key: 'option-import',
    label: 'documents.more.importCroogloo',
    value: BoxImportValue
  }
];

// the options available for the create button in the documents buttons component
export const CreateOptions: OptionProps[] = [
  {
    key: OptionFolderKey,
    label: 'documents.more.folder',
    value: 'folder'
  },
  {
    key: 'option-shared',
    label: 'documents.more.shared',
    value: 'shared'
  },
  {
    key: 'option-forms',
    label: 'documents.more.forms',
    value: 'forms'
  },
  {
    key: 'option-templates',
    label: 'documents.more.templates',
    value: 'templates'
  }
];

export enum PublishValues {
  Dropbox = 'dropbox',
  Box = 'box',
  GDrive = 'drive',
  Studio = 'publishToStudio',
  Sharepoint = 'sharepoint'
}

// the options available for the publish button in the documents buttons component
export const PublishOptions: OptionProps[] = [
  {
    key: OptionFolderKey,
    label: 'documents.more.studioHub',
    value: PublishValues.Studio
  },
  {
    key: 'option-dropbox',
    label: 'documents.more.dropbox',
    value: PublishValues.Dropbox
  },
  {
    key: 'option-drive',
    label: 'documents.more.drive',
    value: PublishValues.GDrive
  },
  {
    key: 'option-box',
    label: 'documents.more.box',
    value: PublishValues.Box
  },
  {
    key: 'option-sharepoint',
    label: 'documents.more.sharepoint',
    value: PublishValues.Sharepoint
  }
];

// the different modal popups for the documents page and their data
export const OptionPopups: any = {
  [MoveDocumentModal]: {
    heading: 'documents.moveDocument.heading',
    content: 'documents.moveDocument.content',
    confirmButton: 'action.select'
  },
  [RemoveWatermarkModal]: {
    heading: 'documents.removeWatermark.heading',
    content: 'documents.removeWatermark.content',
    confirmButton: 'action.remove'
  },
  [DeleteDocumentModal]: {
    heading: '',
    content: '',
    confirmButton: 'action.delete'
  },
  [ESignatureModal]: {
    heading: 'documents.eSignature.heading',
    content: 'documents.eSignature.content',
    confirmButton: 'action.okay'
  },
  [ParseFileModal]: {
    heading: 'scripts.viewer.parseFile.heading',
    content: 'scripts.viewer.parseFile.content',
    confirmButton: 'action.confirm'
  },
  [ApplyWatermarkModal]: {
    heading: '',
    content: '',
    confirmButton: 'action.confirm'
  },
  [RenameFileModal]: {
    heading: 'documents.renameFile.heading',
    content: '',
    confirmButton: 'action.confirm'
  },
  [EditFileCategoryModal]: {
    heading: 'documents.editCategory.heading',
    content: '',
    confirmButton: 'action.confirm'
  },
  [AccessLevelModal]: {
    heading: 'documents.accessLevel.heading',
    content: 'documents.accessLevel.content',
    confirmButton: 'action.confirm'
  }
}
