import type { FC, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import SearchBar from '../../common/SearchBar';
import EmailDrawerTab from './EmailDrawerTab/EmailDrawerTab';
import { DistributionHistoryPage } from '../DistributionHistory';
import OutboxDrawerTab from './EmailDrawerTab/OutboxDrawerTab';
import styles from './EmailDrawer.module.scss';
import type { GridRowId } from '@mui/x-data-grid';
import AbortMessage from '../../modals/AbortMessage/AbortMessage.lazy';
import Toast from '../../common/toast/Toast';
import { InitialToastValues, ToastIcons } from '../../../models/Toast';
import type { ToastProps, ToastAction } from '../../../models/Toast';
import TertiaryButton from '../../common/buttons/TertiaryButton';
import { CrooglooGreen, MainRed } from '../../../theme/colors';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation, type UseTranslationResponse } from 'react-i18next';
import { type AlertColor, Grid } from '@mui/material';
import ModalService from '../../../services/ModalService';
import { DeleteDraftModal } from '../../modals/DeleteDraft/constants';
import DeleteDraft from '../../modals/DeleteDraft/DeleteDraft.lazy';
import DistributionService from '../../../services/distribution/DistributionService';
import { removeAllDistributionMessages } from '../../../state/slices/DistributionReducer';
import { useDispatch, useSelector } from 'react-redux';
import type { MessageStatus } from '../../../models/Message';
import type { DistributionMessage } from '../../../models/DistributionMessage';
import type { Draft } from '../../../models/Draft';
import { CrooglooUrl } from '../../../models/CrooglooUrl';
import MessageService from '../../../services/message/MessageService';
import type { RootState } from '../../../state/store';
import { useNavigate } from 'react-router-dom';

interface Props {
  pageTitle: DistributionHistoryPage
  openReport: (msgId: GridRowId) => void
  closeReport: () => void
  handleMessageSelect: (msgId: GridRowId) => void
  onDraftDeleted?: () => void
  isReportOpen: boolean
  messageStatus: MessageStatus
  pageNumber: number
  setPageNumber: Dispatch<SetStateAction<number>>
  setSelectedMessage: Dispatch<SetStateAction<DistributionMessage | null>>
}

/**
 * Email Drawer Component to show messages for sent/outbox/draft
 * Used in the distribution history page
 */
const EmailDrawer: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const distributionMessages: DistributionMessage[] = useSelector((state: RootState) => state.distribution.distributionMessages[props.pageTitle]);

  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);
  const [search, setSearch] = useState('');
  const draftToRestoreRef = useRef<Draft | null>(null);

  const handleShowToast = (message: string, toastType: AlertColor, icon: ToastIcons, action?: ToastAction): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true,
      toastAction: action
    })
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const handleAbortSuccess = (draft: Draft | null): void => {
    if (draft) {
      draftToRestoreRef.current = draft;
      handleShowToast(
        'distributionHistory.outbox.abort.toast',
        'warning',
        ToastIcons.Success,
        {
          action: loadDraft,
          content: 'distributionHistory.outbox.abort.load'
        }
      );
      props.setSelectedMessage(null);
    } else {
      handleShowToast('distributionHistory.outbox.abort.toast', 'error', ToastIcons.Info);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const handleEmptyDrafts = (): void => {
    if (distributionMessages.length < 1) {
      handleShowToast('distributionHistory.emptyDrafts.empty', 'error', ToastIcons.Info);
      return;
    }
    ModalService.openCustomModal(DeleteDraftModal, {
      heading: 'distributionHistory.emptyDrafts.heading',
      content: 'distributionHistory.emptyDrafts.content',
      confirmButton: 'action.empty',
      callback: () => {
        DistributionService.deleteAllDrafts()
          .then(() => {
            dispatch(removeAllDistributionMessages({ key: DistributionHistoryPage.Drafts }));
            props.onDraftDeleted?.();
          })
          .catch((err) => {
            handleShowToast(err.message, 'error', ToastIcons.Info);
          })
      }
    });
  };

  const loadDraft = (): void => {
    if (draftToRestoreRef.current) {
      const draftToRestore: Draft = draftToRestoreRef.current;
      void MessageService.restoreDraft(draftToRestore);
      draftToRestoreRef.current = null;
      navigate(CrooglooUrl.COMPOSE);
    }
  }

  return (
    <Box className={styles.EmailDrawer} data-testid="EmailDrawer">
      {props.pageTitle !== DistributionHistoryPage.Drafts && props.pageTitle !== DistributionHistoryPage.Outbox &&
        <SearchBar sx={{ width: '290px' }} placeholder={'distributionHistory.placeholder.searchEmails'} onChange={handleSearch}/>
      }
      {props.pageTitle === DistributionHistoryPage.Drafts &&
        <Grid container item spacing={2} sx={{
          paddingTop: '30px',
          paddingLeft: '30px'
        }}>
          <TertiaryButton disableRipple onClick={handleEmptyDrafts} textcolor={CrooglooGreen} hovercolor={MainRed} activecolor={MainRed}
                          sx={{ fontWeight: 700 }} startIcon={<DeleteOutlineIcon />}>{t('action.emptyDrafts')}</TertiaryButton>
        </Grid>
      }
      {props.pageTitle !== DistributionHistoryPage.Outbox &&
        <EmailDrawerTab
          pageTitle={props.pageTitle}
          handleMessageSelect={props.handleMessageSelect}
          search={search}
          messageStatus={props.messageStatus}
          pageNumber={props.pageNumber}
          setPageNumber={props.setPageNumber}
        />
      }
      {props.pageTitle === DistributionHistoryPage.Outbox &&
        (<>
          <OutboxDrawerTab
            pageTitle={props.pageTitle}
            handleMessageSelect={props.handleMessageSelect}
          />
          <AbortMessage handleShowToast={handleAbortSuccess} />
        </>)
      }
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={String(t(toastStatus.message))}
        icon={toastStatus.icon}
        toastAction={toastStatus.toastAction}
      />
      <DeleteDraft />
    </Box>
  )
};

export default EmailDrawer;
