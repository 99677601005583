import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import type { AlertColor } from '@mui/material';
import { List } from '@mui/material';
import type { Script } from '../../models/Script';
import type { Side } from '../../models/Side';
import type { OptionProps } from '../../models/OptionProps';
import TabPanel from '../slideDrawer/TabPanel';
import DocumentItems from './DocumentItems/DocumentItems.lazy';
import FormInputSelect from '../forms/FormInputSelect';

import styles from './DocumentLists.module.scss';
import scrollStyles from '../../theme/sidebar.module.scss';

import ModalService from '../../services/ModalService';
import { AttachCallSheetModal } from '../modals/AttachCallSheet/constants';
import { ApplyWatermarkModal } from '../modals/ApplyWatermark/constants';
import { DeleteFileModal } from '../DocumentViewer/DeleteFile/constants';
import { ParseFileModal } from '../DocumentViewer/ParseFile/constants';
import Toast from '../common/toast/Toast';
import type { ToastProps } from '../../models/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import DocumentService from '../../services/documents/DocumentService';

import moment from 'moment';

export const DefaultListOrder: string = 'newest';

const options: OptionProps[] = [
  {
    key: 'ascending-order',
    value: 'ascending',
    label: 'common.sorting.options.ascending'
  },
  {
    key: 'descending-order',
    value: 'descending',
    label: 'common.sorting.options.descending'
  },
  {
    key: 'newest-order',
    value: 'newest',
    label: 'common.sorting.options.newest'
  },
  {
    key: 'oldest-order',
    value: 'oldest',
    label: 'common.sorting.options.oldest'
  }

];

export interface DocumentListsProps {
  file: Script | Side | null
  files: Script[] | Side[]
  handleFileChange: (fileId: string) => void
  tabs: string[]
  documentType?: string | undefined
  setCurrentFile: Dispatch<SetStateAction<Script | Side | null>>
}

/**
 * The DocumentLists component for Scripts & Sides
 * Is the child of the Slide Drawer component
 * Calls Tab Headers component to display the tab headers &
 * Calls the Tab Panel component to handle the layout of the tabs
 */
const DocumentLists: FC<DocumentListsProps> = (props: DocumentListsProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const [tab] = useState<number>(0);
  const [sortOption, setSortOption] = useState<string>(DefaultListOrder);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  const defaultValues = { sort: 'newest' };
  const { control } = useForm({ defaultValues });

  const { documentType } = props;

  const handleSortChange = (option: string): void => {
    setSortOption(option);
  };

  const handleShowToast = (message: string, type: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message: t(message),
      type,
      isShown: true,
      icon
    });
  }

  const downloadFile = (file: Script | Side): void => {
    handleShowToast('documents.download.started', 'success', ToastIcons.Success);
    DocumentService.fetchDownloadLink(file.id)
      .then((url: string) => {
        if (!url) {
          throw new Error('documents.download.error');
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file?.fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }).catch((err) => {
        console.error(err);
        handleShowToast('documents.download.error', 'error', ToastIcons.Info);
      })
  };
  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }
  const files = useMemo(() => sortFiles(props.files, sortOption),
    [props.files, sortOption]);

  const handleSelectMore = (option: string, file: Script | Side | null): void => {
    if (file !== null) {
      props.setCurrentFile(file);

      switch (option) {
        case 'download':
          downloadFile(file);
          break;
        case 'attachCallsheet':
          ModalService.openCustomModal(AttachCallSheetModal, {
            heading: 'sides.attachCallsheet.heading',
            confirmButton: 'action.confirm'
          });
          break;
        case 'parse':
          ModalService.openCustomModal(ParseFileModal, {
            confirmButton: 'action.confirm'
          });
          break;
        case 'watermark':
          ModalService.openCustomModal(ApplyWatermarkModal, {
            confirmButton: 'action.confirm'
          });
          break;
        case 'delete':
          ModalService.openCustomModal(DeleteFileModal, {
            confirmButton: 'action.confirm'
          });
          break;
      }
    }
  }

  return (
    <div className={styles.DocumentLists} data-testid="DocumentLists">
      <div className={styles.sortDropDown}>
        <FormInputSelect
          width={'308px'}
          name={'sort'}
          placeholder={String(t('common.sorting.options.ascending'))}
          formItemProps={{
            control
          }}
          onItemClick={handleSortChange}
          options={options.map((option: OptionProps) => ({ ...option, label: t(option.label) }))}
        />
      </div>
      <TabPanel value={tab} index={0}>
        <List className={`${styles.documentList} ${scrollStyles.scrollbar}`}>
          <DocumentItems
            file={props.file}
            files={files}
            handleFileChange={props.handleFileChange}
            handleSelectMore={handleSelectMore}
            documentType={documentType}
          />
        </List>
      </TabPanel>
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={toastStatus.message}
        icon={toastStatus.icon}
      />
    </div>
  );
};

export default DocumentLists;

export function sortFiles (files: Script[] | Side[], sort: string) {
  return files.sort((a: Script | Side, b: Script | Side) => {
    if (sort === 'descending') {
      return a.fileName > b.fileName ? -1 : 1;
    } else if (sort === 'newest') {
      return a.timeCreated && b.timeCreated && moment(a.timeCreated).isSameOrAfter(moment(b.timeCreated), 'millisecond') ? -1 : 1;
    } else if (sort === 'oldest') {
      return a.timeCreated && b.timeCreated && moment(b.timeCreated).isSameOrAfter(moment(a.timeCreated), 'millisecond') ? -1 : 1;
    }

    return a.fileName < b.fileName ? -1 : 1;
  });
}
