import { SecurityAdminApi } from './auth/constants';
import type { ApiDataType, SecurityProfile, AuthUser } from './auth/constants';
import AuthService from './auth/AuthService';
import { PostMethod, DeleteMethod, PatchMethod } from './constants';
import type { SubscriptionInfo } from '../models/SubscriptionInfo';
import type { WSResponse } from '../models/WSResponse';
import type { SecurityListResponse, SecurityList, WSSecurityList } from '../models/SecurityList';
import type { SecurityListMembers } from '../models/SecurityListMembers';
import type { ComboValues, ComboValueParameters } from '../models/ComboValues';
import type { AccessPermission } from '../models/AccessPermissions';
import { setSecurityLists, addRestrictedMember } from '../state/slices/SecurityAdmin';
import { SecurityListIds, DHDisplayName } from '../models/SecurityList';
import UtilityService from './UtilityService';
import { store } from '../state/store';
import type { Subscription, Currencies, ShippingDetails, BillingDetails } from '../models/Subscription';
const { dispatch } = store;

interface ISecurityAdminService {
  getSecurityProfile: () => Promise<SecurityProfile>
  fetchSubscriptionInfo: () => Promise<SubscriptionInfo>
  fetchUserSecurityList: () => Promise<string>
  fetchSecurityLists: () => void
  fetchAccessPermissions: () => Promise<AccessPermission[]>
  fetchPageSecurityFromToken: (menuPageName: string) => Promise<WSResponse>
  fetchPaymentMethod: () => Promise<string>
  fetchRestrictedMembers: () => Promise<void>
  getComboValues: (params: ComboValueParameters) => Promise<ComboValues>
  saveApplicationAccess: (authToken: string) => Promise<WSResponse>
  addSecurityListMember: (persons: string[], securityListId: string) => Promise<SecurityListResponse>
  removeSecurityListMember: (persons: string[], securityListId: string) => Promise<SecurityListResponse>
  fetchSecurityListMembers: (securityListId: string) => Promise<SecurityListMembers>
  addSecurityList: (securityListName: string) => Promise<SecurityListResponse>
  removeSecurityList: (securityListName: string) => Promise<SecurityListResponse>
  saveAccessPermissions: (data: BodyInit) => Promise<WSResponse>
  changeSubscriptionPlan: (newSubPlanId: string) => Promise<string>
  changeTwoFactorAuthForProduction: () => Promise<WSResponse>
  activateSubscriptionPlan: (tokenId: string, cardId: string, email: string, shippingDetails: ShippingDetails, billingDetails: BillingDetails) => Promise<WSResponse>
}

class SercurityAdminService implements ISecurityAdminService {
  /**
   * gets the security profile for the given production in the SYSTEM namespace.
   * @return The security profile
   */
  async getSecurityProfile (): Promise<SecurityProfile> {
    return await makeSecurityAdminCall('getSecurityProfile', {});
  }

  /**
   * Fetches all of the subscription information for the given production.
   * @return subscription info
   */
  async fetchSubscriptionInfo (): Promise<SubscriptionInfo> {
    return await makeSecurityAdminCall('fetchSubscriptionInfo', {});
  }

  /**
   * Fetches the security list an authenticated user belongs to.
   * @return response message
   */
  async fetchUserSecurityList (): Promise<string> {
    const res = await makeSecurityAdminCall('fetchUserSecurityList', {});
    if (!UtilityService.isSuccessResponse(res.responseCode)) {
      throw new Error('fetchUserSecurityList returned response code: ' + String(res.responseCode));
    }
    return res.responseMessage;
  }

  /**
   * returns all of the security lists for the production namespace
   * @return List of security lists
   */
  fetchSecurityLists (): void {
    makeSecurityAdminCall('fetchSecurityLists', {})
      .then((res) => {
        if (res.items !== null && Array.isArray(res.items) && res.items.length > 0) {
          const securityLists: SecurityList[] = [];
          const items: WSSecurityList[] = res.items;
          items.forEach((item: WSSecurityList) => {
            const securityListName: string = item.properties.securityListName ?? '';
            const securityList: SecurityList = {
              securityListId: item.properties.securityListId ?? '',
              displayOrder: item.properties.displayOrder ?? '',
              securityListName: (securityListName === SecurityListIds.DH) ? DHDisplayName : securityListName
            };
            securityLists.push(securityList);
          })
          securityLists.sort((a: SecurityList, b: SecurityList) => Number(a.displayOrder) - Number(b.displayOrder));
          dispatch(setSecurityLists({ securityLists }));
        }
      }).catch((err) => {
        console.error(err);
      });
  }

  /**
   * @return List of security access entities
   */
  async fetchAccessPermissions (): Promise<AccessPermission[]> {
    let permissions: AccessPermission[] = [];
    const res = await makeSecurityAdminCall('fetchAccessPermissions', {});
    if (res.items !== null && Array.isArray(res.items) && res.items.length > 0) {
      permissions = res.items;
    }
    return permissions;
  }

  /**
   *  Fetches a the security list and deletes the menu page given
   *  @param {string} menuPageName - The name of the menu page to be deleted
   * @return WSResponse
   */
  async fetchPageSecurityFromToken (menuPageName: string): Promise<WSResponse> {
    const res = await makeSecurityAdminCall('fetchPageSecurityFromToken', {
      params: {
        menuPageName
      }
    });

    if (parseInt(res.responseCode) > 0) {
      return await Promise.resolve(res);
    } else {
      throw new Error('Error with reponse code:' + String(res.responseCode));
    }
  }

  /**
   *  Fetch the payment method of the given production.
   * @return string of response message
   */
  async fetchPaymentMethod (): Promise<string> {
    const res = await makeSecurityAdminCall('fetchPaymentMethod', {});
    if (UtilityService.isSuccessResponse(res.responseCode)) {
      return await Promise.resolve(res.responseMessage);
    } else {
      throw new Error('Failed to retrieve payment method');
    }
  }

  /**
   *  returns the restricted memebers of the specified security
   * @return RestrictedMembers - list of restricted members
   */
  async fetchRestrictedMembers (): Promise<void> {
    const res = await makeSecurityAdminCall('fetchRestrictedMembers', {});
    if (res.activeUserPersonId !== null && res.activeUserSecurityListId !== null) {
      dispatch(addRestrictedMember({ restrictedMember: res }));
    } else {
      throw new Error('Invalid restricted member response.');
    }
  }

  /**
   *  @param {ComboValueParameters} params - given criteria to search for restricted members
   *  @return List of ComboValues objects
   */
  async getComboValues (params: ComboValueParameters): Promise<ComboValues> {
    return await makeSecurityAdminCall('getComboValues', {
      params: {
        entityName: (params.entityName !== null && params.entityName !== undefined) ? params.entityName : '',
        filterAttribute: (params.filterAttribute !== null && params.filterAttribute !== undefined) ? params.filterAttribute : '',
        filterValue: (params.filterValue !== null && params.filterValue !== undefined) ? params.filterValue : '',
        valueAttrib: (params.valueAttrib !== null && params.valueAttrib !== undefined) ? params.valueAttrib : '',
        displayAttrib: (params.displayAttrib !== null && params.displayAttrib !== undefined) ? params.displayAttrib : '',
        sortAttrib: (params.sortAttrib !== null && params.sortAttrib !== undefined) ? params.sortAttrib : ''
      }
    })
  }

  /**
   *  Saves the application access details of the given user in the production namespace.
   *  @param {string} authToken the authentication token
   * @return WSResponse
   */
  async saveApplicationAccess (authToken: string): Promise<WSResponse> {
    const res = await makeSecurityAdminCall('saveApplicationAccess', {
      params: {
        authToken
      }
    });
    if (!UtilityService.isSuccessResponse(res.responseCode)) throw new Error('server error');
    return res;
  }

  /**
   *  add a list of security list members tp the production
   *  @param {string[]} persons the array of ids to add
   *  @param {string} securityListId the security list id to add the persons to
   * @return SecurityListResponse stating success or not
   */
  async addSecurityListMember (persons: string[], securityListId: string): Promise<SecurityListResponse> {
    return await makeSecurityAdminCall('addSecurityListMember', {
      params: {
        personId: persons.join(','),
        securityListId
      },
      type: PostMethod
    })
  }

  /**
   *  remiove a list of security list members from the production
   *  @param {string[]} persons the array of ids to remove
   *  @param {string} securityListId the security list id to remove the persons from
   * @return @return SecurityListResponse stating success or not
   */
  async removeSecurityListMember (persons: string[], securityListId: string): Promise<SecurityListResponse> {
    return await makeSecurityAdminCall('removeSecurityListMember', {
      params: {
        personId: persons.join(','),
        securityListId
      },
      type: DeleteMethod
    })
  }

  /**
   *  returns a list of security list members in the production
   *  @param {string} securityListId the authentication token
   * @return SecurityListMembers list of secuiry members
   */
  async fetchSecurityListMembers (securityListId: string): Promise<SecurityListMembers> {
    return await makeSecurityAdminCall('fetchSecurityListMembers', {
      params: {
        securityListId
      }
    });
  }

  /**
   *  Creates a new security list in the security_list
   *  @param {string} securityListName name of security list
   * @return SecurityListResponse
   */
  async addSecurityList (securityListName: string): Promise<SecurityListResponse> {
    return await makeSecurityAdminCall('addSecurityList', {
      params: {
        securityListName
      },
      type: PostMethod
    });
  }

  /**
   *  Remove a security list in the security_list
   *  @param {string} securityListName name of security list
   * @return SecurityListResponse
   */
  async removeSecurityList (securityListName: string): Promise<SecurityListResponse> {
    return await makeSecurityAdminCall('removeSecurityList', {
      params: {
        securityListName
      },
      type: DeleteMethod
    });
  }

  /**
   *  updates the given access permissiions
   *  @param {BodyInit} data data to be sent in body of request
   * @return SecurityListResponse
   */
  async saveAccessPermissions (data: BodyInit): Promise<WSResponse> {
    return await makeSecurityAdminCall('saveAccessPermissions', {
      body: data,
      type: PostMethod
    })
  }

  async changeSubscriptionPlan (newSubPlanId: string): Promise<string> {
    const res = await makeSecurityAdminCall('changeSubscriptionPlan', {
      type: PostMethod,
      body: JSON.stringify({
        newPlanId: newSubPlanId
      })
    })
    if (!UtilityService.isSuccessResponse(res.responseCode)) throw new Error('server error');
    return newSubPlanId;
  }

  async changeTwoFactorAuthForProduction (): Promise<WSResponse> {
    return await makeSecurityAdminCall('changeTwoFactorAuthForProduction', {
      type: PatchMethod
    })
  }

  async activateSubscriptionPlan (tokenId: string, cardId: string, email: string, shippingDetails: ShippingDetails, billingDetails: BillingDetails): Promise<WSResponse> {
    const currentPlan: Subscription | null = store.getState().subscription.currentPlan;
    const currency: Currencies = store.getState().subscription.currency;
    const crooglooAuth: AuthUser = store.getState().auth.crooglooauth;
    if (currentPlan) {
      return await makeSecurityAdminCall('activateSubscriptionPlan', {
        type: PostMethod,
        params: {
          product: crooglooAuth.productionType ?? '',
          planId: currentPlan.planId,
          stripeToken: tokenId,
          cardId,
          currency,
          email
        },
        body: JSON.stringify({
          billing_address_city: billingDetails.city,
          billing_address_country: billingDetails.country,
          billing_address_line1: billingDetails.address,
          billing_address_state: billingDetails.state,
          billing_address_zip: billingDetails.postalCode,
          billing_name: billingDetails.name,
          shipping_address_city: shippingDetails.city,
          shipping_address_country: shippingDetails.country,
          shipping_address_line1: shippingDetails.address,
          shipping_address_state: shippingDetails.state,
          shipping_address_zip: shippingDetails.postalCode,
          shipping_name: shippingDetails.name
        })
      });
    } else {
      throw new Error('no current plan error');
    }
  }
}

/**
 *  Calls the auth service apiCall function to make call to backend
 *  @param {string} method the method to be call
 *  @param {API_DATA_TYPE} data to be sent in request
 * @return Promise
 */
const makeSecurityAdminCall = async (method: string, data: ApiDataType): Promise<any> => {
  return await AuthService.apiCall(SecurityAdminApi, method, data);
}

const securityAdminService: ISecurityAdminService = new SercurityAdminService();
export default securityAdminService;
