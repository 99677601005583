import React, { lazy, Suspense } from 'react';
import type { DocumentsTableProps } from './DocumentsTable';
const LazyDocumentsTable = lazy(async () => await import('./DocumentsTable'));

const DocumentsTable = (props: DocumentsTableProps) => (
  <Suspense fallback={null}>
    <LazyDocumentsTable {...props} />
  </Suspense>
);

export default DocumentsTable;
