import React, { lazy, Suspense } from 'react';
import type { DistributionListsTableProps } from './DistributionListsTable';

const LazyDistributionListsTable = lazy(async () => await import('./DistributionListsTable'));

const DistributionListsTable = (props: DistributionListsTableProps) => (
  <Suspense fallback={null}>
    <LazyDistributionListsTable {...props} />
  </Suspense>
);

export default DistributionListsTable;
