import { useEffect, useMemo } from 'react';
import type { FC, Dispatch, SetStateAction } from 'react';
import { createEditor, Transforms, Editor } from 'slate';
import type { Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact } from 'slate-react';
import TextEditor from './Editor';
import UtilityService from '../../services/UtilityService';

interface SpanElement {
  type: 'span'
  style: { fontSize: string, fontWeight: string, color: string, backgroundColor: string, fontFamily: string }
  text: string
  className?: string
  id?: string
}

interface Props {
  contentValue: Descendant[]
  initialValue: Descendant[]
  setContentValue: (value: Descendant[]) => void
  changeEditorValue: boolean
  setChangeEditorValue: Dispatch<SetStateAction<boolean>>
  placeholder?: string
  toolbar: boolean
  clearEditor: boolean
  setClearEditor: Dispatch<SetStateAction<boolean>>
  setInsertFunction?: any
}

const SlateTextEditor: FC<Props> = ({
  contentValue,
  initialValue,
  setContentValue,
  changeEditorValue,
  setChangeEditorValue,
  placeholder,
  toolbar,
  clearEditor,
  setClearEditor,
  setInsertFunction
}: Props) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  useEffect(() => {
    if (changeEditorValue) {
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, [])
        }
      })
      Transforms.removeNodes(editor, {
        at: [0]
      });
      Transforms.insertNodes(
        editor,
        contentValue
      )
      setChangeEditorValue(false);
    }
  }, [contentValue]);

  useEffect(() => {
    if (clearEditor) {
      Transforms.delete(editor, {
        at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) }
      });
      Transforms.insertNodes(editor, initialValue, { at: [0] });
      setClearEditor(false);
    }
  }, [clearEditor]);

  const insertVariable = (varName: string) => {
    const uniqueId: string = UtilityService.uniqueUuid();
    const spanValue: SpanElement = {
      type: 'span',
      style: { fontSize: '0.85rem', fontWeight: 'bold', color: 'crimson', backgroundColor: 'rgba(0, 0, 0, 0.1)', fontFamily: '\'Courier New\', Courier, monospace' },
      className: 'personalized-email-content',
      id: uniqueId,
      text: `{${varName}}`
    }
    Transforms.insertNodes(editor, spanValue);
  }

  if (setInsertFunction) { setInsertFunction(insertVariable); }

  return (
    <Slate editor={editor} initialValue={initialValue} onChange={setContentValue}>
      <TextEditor editor={editor} placeholder={placeholder} toolbar={toolbar} />
    </Slate>
  )
}

export default SlateTextEditor;
