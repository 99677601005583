import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { Dialog, DialogActions } from '@mui/material';
import { useSelector } from 'react-redux';
import type { UseTranslationResponse } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import type { RootState } from '../../../state/store';
import ModalService from '../../../services/ModalService';
import dialogStyles from '../../../theme/dialog.module.scss';
import SuccessButton from '../../common/buttons/SuccessButton';
import TertiaryButton from '../../common/buttons/TertiaryButton';
import styles from './ActivateTrial.module.scss';
import { ActivateTrialModal } from './constants';
import ModalTitle from '../ModalTitle/ModalTitle';
import ModalContent from '../ModalContent';
import type { AuthUser } from '../../../services/auth/constants';
import { ManageSubscriptionModal } from '../ManageSubscription/constants';

const ActivateTrial: FC = () => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const modalsState = useSelector((state: RootState) => state.modals.custom[ActivateTrialModal]);
  const crooglooAuth: AuthUser = useSelector((state: RootState) => state.auth.crooglooauth);

  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (modalsState.isVisible) {
      const contentToShow: string = (crooglooAuth.trialDaysLeft !== undefined && crooglooAuth.trialDaysLeft > 0) ? 'subscription.activateTrial.contentMore' : 'subscription.activateTrial.contentOne';
      setContent(contentToShow);
    }
  }, [modalsState]);

  const handleClose = (): void => {
    ModalService.closeCustomModal(ActivateTrialModal);
    setContent('');
  }

  const handleCancel = (): void => {
    handleClose();
  }

  const handleConfirm = (): void => {
    handleClose();
    ModalService.openCustomModal(ManageSubscriptionModal, {
      heading: 'subscription.endTrial.heading',
      confirmButton: 'action.confirm',
      metaData: ['endingTrial']
    });
  }

  return (
    <Dialog
      classes={{
        root: `${dialogStyles.dialogRoot} ${styles.ActivateTrial}`,
        paper: `${dialogStyles.dialogPaper} ${dialogStyles.dialogGeneralWidth}`
      }}
      BackdropProps={{
        className: dialogStyles.dialogBackdrop
      }}
      open={modalsState.isVisible}
      onClose={handleConfirm}
      data-testid="ActivateTrial"
    >
      <ModalTitle handleClose={handleCancel} title={modalsState.title} />
      <ModalContent
        heading={String(t(modalsState.heading))}
        content={String(t(content))}
      />
      <DialogActions className={dialogStyles.dialogActionsCenter}>
        <SuccessButton disableRipple onClick={handleConfirm}>{t(modalsState.confirmButton)}</SuccessButton>
        <TertiaryButton
          disableRipple
          onClick={handleCancel}
          className={dialogStyles.cancelButton}
        >
          {t('action.cancel')}
        </TertiaryButton>
      </DialogActions>
    </Dialog>
  )
};

export default ActivateTrial;
