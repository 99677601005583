import type { FC } from 'react';
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import type { DistributionList, SelectedGroup, ExtraEmail } from '../../models/DistributionList';
import type { Person } from '../../models/Person';
import type { Department } from '../../models/Department';
import TabPanel from '../slideDrawer/TabPanel';
import TabItem from './TabItem';
import TabHeaders from '../slideDrawer/TabHeaders/TabHeaders.lazy';
import type { RootState } from '../../state/store';
import DraftService from '../../services/draft/DraftService';
import ChipsDropdown from '../common/ChipsDropdown';
import type { ChipDropdownData } from '../common/ChipsDropdown';
import UtilityService from '../../services/UtilityService';
import Toast from '../common/toast/Toast';
import DistributionService from '../../services/distribution/DistributionService';
import type { WSResponse } from '../../models/WSResponse';
import { addCachedExtraEmail } from '../../state/slices/DistributionReducer';
import { DistributionMessagesKeys } from '../../services/distribution/constants';
import scrollStyles from '../../theme/sidebar.module.scss';
import { headerHeight, tabHeaderHeight, ChipDropDownHeight } from '../../theme/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

export interface DrawerTabsProps {
  tab: number
  onChange: (event: React.SyntheticEvent, newTab: number) => void
  departmentList: Department[]
  areDepartmentsAvailable?: boolean
}

/**
 * Drawer Tabs Component for the Slide Drawer Component containing lists and departemnts
 */
const DrawerTabs: FC<DrawerTabsProps> = (props: DrawerTabsProps) => {
  const dispatch = useDispatch();
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const [distributionList, setDistributionList] = useState<DistributionList[]>([]);
  const [groupList, setGroupList] = useState<SelectedGroup[]>([]);
  const [groupDepartment, setGroupDepartment] = useState<SelectedGroup[]>([]);
  const [extraEmailsList, setExtraEmailsList] = useState<ChipDropdownData[]>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<string>('');

  const distributions: DistributionList[] = useSelector((state: RootState) => state.distribution.distributionList);
  const allContactsList: DistributionList | null = useSelector((state: RootState) => state.distribution.allContactsList);
  const extraEmails: ExtraEmail[] = useSelector((state: RootState) => state.distribution.extraEmails);
  const cachedEmails: string[] = useSelector((state: RootState) => state.distribution.cachedExtraEmails);

  const draftgroupDepartment: SelectedGroup[] = useSelector((state: RootState) => state.draft.drafts[DistributionMessagesKeys.Compose].groupDepartment);
  const draftgroupList: SelectedGroup[] = useSelector((state: RootState) => state.draft.drafts[DistributionMessagesKeys.Compose].groupList);
  const draftExtraEmails: string[] = useSelector((state: RootState) => state.draft.drafts[DistributionMessagesKeys.Compose].selectedExtraEmails);

  const tabs: string[] = [
    'slideDrawer.tabs.listTab'
  ];

  useEffect(() => {
    const list: ChipDropdownData[] = [
      // the id here for the ExtraEmail types has to include all three of email, primaryEmail, label -
      // the data coming back from backend throws an error for duplicate id's if one or two of them are only used (i.e email and label)
      // unique id's should be created when stored in the backend
      ...extraEmails.map((extra: ExtraEmail) => ({
        id: extra.email + '_' + extra.primaryEmail + '_' + extra.label, label: extra.label, name: extra.email
      })),
      ...cachedEmails.map((email: string) => ({ id: email, label: email, name: email }))
    ];
    setExtraEmailsList(list);
  }, [extraEmails, cachedEmails]);

  useEffect(() => {
    setGroupList(draftgroupList);
  }, [draftgroupList]);

  useEffect(() => {
    let fullList: DistributionList[] = [];
    if (distributions.length > 0) {
      fullList = distributions;
    }
    // hide all contacts in list for now - it's too big and slows down the ui
    if (allContactsList) {
      fullList = [allContactsList, ...fullList];
    }
    setDistributionList(fullList);
  }, [distributions, allContactsList]);

  if (props.areDepartmentsAvailable) {
    tabs.push('slideDrawer.tabs.departmentTab');

    useEffect(() => {
      setGroupDepartment(draftgroupDepartment);
    }, [draftgroupDepartment])
  }

  const handleCloseToast = (): void => {
    setShowToast(false);
  }

  const handleListSelect = (member: Person, distributionList: DistributionList): void => {
    DraftService.handleListSelect(member, distributionList);
  }

  const handleDepartmentSelect = (member: Person, department: DistributionList | Department): void => {
    DraftService.handleDepartmentSelect(member, department);
  }

  const handleGroupSelect = (distributionList: DistributionList): void => {
    DraftService.handleGroupSelect(distributionList);
  }

  const handleDepartmentGroupSelect = (department: DistributionList | Department): void => {
    DraftService.handleDepartmentGroupSelect(department);
  }

  const handleEmailSelect = (extraEmail: ChipDropdownData): void => {
    handleCloseToast();
    if (UtilityService.isInValidEmailAddress(extraEmail.name)) {
      setInvalidEmail(extraEmail.name);
      setShowToast(true);
    } else {
      const email: ChipDropdownData | undefined = extraEmailsList.find((email: ChipDropdownData) => email.name === extraEmail.name);
      if (email) {
        DraftService.handleExtraEmailSelect(extraEmail.name);
      } else if (extraEmail.inputValue) {
        DistributionService.cacheExtraEmail(extraEmail.id)
          .then((resp: WSResponse) => {
            if (!resp?.responseCode || !UtilityService.isSuccessResponse(resp.responseCode)) {
              console.error({
                message: 'Extra email could not be cached',
                resp
              });
            } else {
              DraftService.handleExtraEmailSelect(extraEmail.id);
              dispatch(addCachedExtraEmail({ email: extraEmail.id }));
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }

  return (
    <>
      <ChipsDropdown
        showChips={false}
        data={extraEmailsList}
        readValue={draftExtraEmails.map((extra: string) => ({ id: extra, name: extra, label: extra }))}
        setValue={handleEmailSelect}
        addOptions={true}
        placeholder={'common.labels.searchEmails'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '10px'
        }}
        itemsSx={{
          fontSize: '0.725rem'
        }}
      />
      <TabHeaders tab={props.tab} onChange={props.onChange} tabs={tabs} centerHeaders={true} />
      <TabPanel value={props.tab} index={0}>
        <List
          sx={{
            width: '100%',
            height: `calc(100vh - ${headerHeight}px - ${ChipDropDownHeight}px - ${tabHeaderHeight}px + 10px)`
          }}
          className={`${scrollStyles.scrollbar}`}
        >
          {distributionList.map((distribution: DistributionList) => (
            <TabItem
              key={distribution.id}
              item={distribution}
              group={groupList}
              handleGroupSelect={handleGroupSelect}
              handleSelect={handleListSelect}
            />
          ))}
        </List>
      </TabPanel>
      {
        (props.areDepartmentsAvailable) && (
          <TabPanel value={props.tab} index={1}>
            <List
              sx={{
                width: '100%',
                height: `calc(100vh - ${headerHeight}px - ${ChipDropDownHeight}px - ${tabHeaderHeight}px)`
              }}
              className={`${scrollStyles.scrollbar}`}
            >
              {props.departmentList.map((department: Department) => (
                <TabItem
                  key={department.id}
                  item={department}
                  group={groupDepartment}
                  handleGroupSelect={handleDepartmentGroupSelect}
                  handleSelect={handleDepartmentSelect}
                />
              ))}
            </List>
          </TabPanel>
        )
      }
      <Toast open={showToast} onClose={handleCloseToast} type={'error'} title={String(t('compose.invalidEmail', { email: invalidEmail }))}/>
    </>
  )
}

export default DrawerTabs;
