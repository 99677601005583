import React, { lazy, Suspense } from 'react';
import type { MergeDistributionListProps } from './MergeDistributionList';
const LazyMergeDistributionList = lazy(async () => await import('./MergeDistributionList'));

const MergeDistributionList = (props: MergeDistributionListProps) => (
  <Suspense fallback={null}>
    <LazyMergeDistributionList {...props} />
  </Suspense>
);

export default MergeDistributionList;
