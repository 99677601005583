import React, { lazy, Suspense } from 'react';

const LazyDeleteTemplate = lazy(async () => await import('./DeleteTemplate'));

const DeleteTemplate = () => (
  <Suspense fallback={null}>
    <LazyDeleteTemplate />
  </Suspense>
);

export default DeleteTemplate;
