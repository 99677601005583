import React, { lazy, Suspense } from 'react';
import type { AbortMessageProps } from './AbortMessage';

const LazyAbortMessage = lazy(async () => await import('./AbortMessage'));

const AbortMessage = (props: AbortMessageProps) => (
  <Suspense fallback={null}>
    <LazyAbortMessage {...props} />
  </Suspense>
);

export default AbortMessage;
