import React, { lazy, Suspense } from 'react';

const LazyNewFolder = lazy(async () => await import('./NewFolder'));

const NewFolder = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyNewFolder {...props} />
  </Suspense>
);

export default NewFolder;
