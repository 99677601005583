import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import styles from './Dashboard.module.scss';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setPageTitle } from '../../state/slices/pageTitle';
import CallTime from '../CallTime/CallTime';
import DashboardFolders from '../DashboardFolders/DashboardFolders';
import DashboardButtons from './DashboardButtons/DashboardButtons.lazy';
import PersonService from '../../services/person/PersonService';
import DistributionService from '../../services/distribution/DistributionService';
import DashboardService from '../../services/DashboardService';
import TextSMS from '../modals/TextSMS/TextSMS.lazy';
import type { AuthUser } from '../../services/auth/constants';
import type { AuthState } from '../../state/slices/AuthReducer';
import { SecurityListIds } from '../../models/SecurityList';
import type { ClosestCallSheet } from '../../models/CalenderEntity';
import type { RootState } from '../../state/store';
import { DashboardCategories } from '../../models/Document';
import FileViewerPopup from '../FileViewerPopup/FileViewerPopup.lazy';
import ESignature from '../modals/ESignature/ESignature.lazy';
import Toast from '../common/toast/Toast';
import { InitialToastValues } from '../../models/Toast';
import type { ToastIcons, ToastProps } from '../../models/Toast';
import { setLoadingData } from '../../state/slices/DashboardReducer';
import ViewService from '../../services/ViewService';
import MainSection from '../common/MainSection';
import { drawerWidth } from '../../theme/styles';
import LoadingProgress from '../common/LoadingProgress/LoadingProgress.lazy';
import { DashboardTemplates } from '../../models/Template';
import type { AlertColor } from '@mui/material';
import { t } from 'i18next';

interface UserDashboardState {
  isAdmin: boolean
  productionType: string
  dtrAvailable: boolean
  productionName: string
}

const initialDashboardValues: UserDashboardState = {
  isAdmin: false,
  dtrAvailable: false,
  productionName: '',
  productionType: ''
}

const initialDashboardCallTimeState: ClosestCallSheet = {
  callTime: '',
  date: ''
}

export enum DashboardPage {
  Title = 'Dashboard'
}

export const TimeReportsFolderId: string = 'TIME_REPORTS_ROOT';
export const TimeReportsName: string = 'Time Reports';

interface Props {
  pageTitle: DashboardPage
}

const Dashboard: FC<Props> = ({ pageTitle }: Props) => {
  const dispatch = useAppDispatch();
  const auth: AuthState = useAppSelector((state: RootState) => state.auth);
  const { closestCallSheet, loadingData } = useAppSelector((state: RootState) => state.dashboard);

  const [dashboardDetails, setDashboardDetails] = useState<UserDashboardState>(initialDashboardValues);
  const [callTimeDetails, setCallTimeDetails] = useState<ClosestCallSheet>(initialDashboardCallTimeState);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);
  const [fetchingFiles, setFetchingFiles] = useState<boolean>(true);

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setLoadingData({ loadingData: true }));
    DashboardService.checkRestrictedCommunity();
    DistributionService.fetchDistributionLists();
    PersonService.fetchContacts();
    DashboardService.loadDashboardCallTime();
    ViewService.fetchCardViewDocuments([TimeReportsFolderId], true);
    handleLatestDistroGet();
  }, [pageTitle]);

  useEffect(() => {
    if (loadingData && DashboardTemplates.length > 0) {
      setFetchingFiles(false);
    }
  }, [DashboardTemplates, loadingData])

  useEffect(() => {
    const crooglooAuth: AuthUser = auth.crooglooauth;

    const productionName: string = crooglooAuth.communitiesIdNameMap?.[auth.tenantId] ?? '';
    const updatedDashboardDetails: UserDashboardState = {
      ...initialDashboardValues,
      productionName,
      productionType: crooglooAuth.productionType ?? ''
    }

    if (crooglooAuth.securityListId) {
      const securityListId: string = crooglooAuth.securityListId.toLowerCase();
      const adminSecurityList: string = SecurityListIds.ADMIN.toLowerCase();
      const dhSecurityList: string = SecurityListIds.DH.toLowerCase();
      if (securityListId === adminSecurityList) {
        updatedDashboardDetails.isAdmin = true;
      }
      updatedDashboardDetails.dtrAvailable = DashboardService.checkDtrAvailable(
        securityListId, adminSecurityList, dhSecurityList
      );
    }
    setDashboardDetails(updatedDashboardDetails);
  }, [auth]);

  useEffect(() => {
    const updatedCallTimeState: ClosestCallSheet = {
      callTime: closestCallSheet.callTime ?? initialDashboardCallTimeState.callTime,
      date: closestCallSheet.date ?? initialDashboardCallTimeState.date
    };
    setCallTimeDetails(updatedCallTimeState);
  }, [closestCallSheet]);

  const handleLatestDistroGet = (): void => {
    DashboardService.loadPaperDistros();
  }

  const handleChangeDashboardCategory = (category: DashboardCategories): void => {
    switch (category) {
      case DashboardCategories.DISTRO:
        handleLatestDistroGet();
        break;
      case DashboardCategories.TEMPLATES:
        console.debug('templates'); // Templates already loaded in component
        break;
    }
  }

  const handleShowToast = (message: string, type: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message: t(message),
      type,
      icon,
      isShown: true
    });
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  return (
    <MainSection open={true} drawerwidth={drawerWidth} className={styles.dashboard} sx={{ padding: '24px', paddingBottom: '0px' }}>
      {(fetchingFiles)
        ? <LoadingProgress isLoading={fetchingFiles} />
        : <>
          <div className={styles.dashboardContent}>
            <CallTime
              callTime={callTimeDetails.callTime}
              productionName={dashboardDetails.productionName}
              callDate={callTimeDetails.date}
            />
            <div className={styles.lowerSection}>
              <DashboardButtons dtrAvailable={dashboardDetails.dtrAvailable} productionType={dashboardDetails.productionType} />
              <DashboardFolders
                handleChangeDashboardCategory={handleChangeDashboardCategory}
                handleShowToast={handleShowToast}
                isAdminUser={dashboardDetails.isAdmin}
                dashboardTemplates={DashboardTemplates}
                productionType={dashboardDetails.productionType}
              />
            </div>
          </div>
          <div className={styles.footer}></div>
       </>
      }
      <TextSMS />
      <FileViewerPopup />
      <ESignature />
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={toastStatus.message}
        icon={toastStatus.icon}
      />
    </MainSection>
  )
}

export default Dashboard;
