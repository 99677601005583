import React, { lazy, Suspense } from 'react';
import type { DocumentItemsProps } from './DocumentItems';

const LazyDocumentItems = lazy(async () => await import('./DocumentItems'));
const DocumentItems = (props: DocumentItemsProps) => (
  <Suspense fallback={null}>
    <LazyDocumentItems {...props} />
  </Suspense>
);

export default DocumentItems;
