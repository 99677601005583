import React, { lazy, Suspense } from 'react';

const LazyExportOptions = lazy(async () => await import('./ExportOptions'));

const ExportOptions = () => (
  <Suspense fallback={null}>
    <LazyExportOptions />
  </Suspense>
);

export default ExportOptions;
