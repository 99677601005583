import React, { useState, useEffect } from 'react';
import type { FC, Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import type { ListOptions, DistributionGroupLists } from '../../models/DistributionList';
import TabHeaders from '../slideDrawer/TabHeaders/TabHeaders.lazy';
import TabPanel from '../slideDrawer/TabPanel';
import { Box } from '@mui/system';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import type { RootState } from '../../state/store';
import Grid from '@mui/material/Grid';
import FormInputSelect from '../forms/FormInputSelect';
import { AddDistributionModal } from '../modals/AddDistributionList/constants';
import { MergeDistributionModal } from '../modals/MergeDistributionList/constants';
import { ExportDistributionModal } from '../modals/ExportDistributionList/constants';
import ModalService from '../../services/ModalService';
import styles from './DistributionLists.module.scss';
import { ChipColor } from '../../theme/colors';
import ContactListsTable from './ContactListsTable/ContactListsTable.lazy';
import DistributionListsTable from './DistributionListsTable/DistributionListsTable.lazy';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import actionBtnStyles from '../../theme/actionBar.module.scss';
import { DeleteListModal } from '../modals/DeleteList/constants';
import { CompareListsModal } from '../modals/CompareLists/constants';
import { setDistributionListToEdit } from '../../state/slices/DistributionReducer';
import { ALL_CREW_LIST_ID } from '../../services/distribution/constants';
import DistributionService from '../../services/distribution/DistributionService';
import { PostMethod, DeleteMethod } from '../../services/constants';
import Toast from '../common/toast/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import type { ToastProps } from '../../models/Toast';
import type { AlertColor } from '@mui/material/Alert';
import type { DistributionListMembers, DistributionListWithContacts } from '../../models/DistributionListMembers';
import PermissionService, { AccessLevel, permissionFilter } from '../../services/auth/PermissionService';
import SuccessButton from '../common/buttons/SuccessButton';
import DisabledButton from '../common/buttons/DisabledButton';
import DeleteButton from '../common/buttons/DeleteButton';
import {
  mainPadding,
  sideBarClosedWidth,
  sideBarMarginWidth,
  sideBarOpenedWidth,
  headerHeight,
  tabHeaderHeight,
  drawerWidth
} from '../../theme/styles';

interface Props {
  tabIndex: number
  selectedList: string
  selectedContact: string
  handleDistributionSelect: (value: string) => void
  handleContactSelect: (value: string) => void
  setLoading: Dispatch<SetStateAction<boolean>>
  drawerOpen: boolean
  selected: string[]
  setSelected: Dispatch<SetStateAction<string[]>>
}

const DistributionLists: FC<Props> = ({
  tabIndex, selectedList, selectedContact,
  handleDistributionSelect, handleContactSelect, setLoading, drawerOpen,
  selected, setSelected
}: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const dispatch = useDispatch();

  const tabs: string[] = [
    'distributionList.tabs.membersTab',
    'distributionList.tabs.nonMembersTab'
  ];

  // the varaible holding what current tab is open
  const [tab, setTab] = useState<number>(0);
  const [listMembers, setListMembers] = useState<DistributionListMembers[]>([]);
  const [nonListMembers, setNonListMembers] = useState<DistributionListMembers[]>([]);
  const [contactMembers, setContactMembers] = useState<DistributionListWithContacts[]>([]);
  const [nonContactMembers, setNonContactMembers] = useState<DistributionListWithContacts[]>([]);
  const [search] = useState('');

  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);
  const [listOptions, setListOptions] = useState<ListOptions[]>([]);

  const [tableHeight, setTableHeight] = useState<number>(0);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const distributionListMembers: any = useSelector((state: RootState) => state.distribution.distributionListMembers[selectedList]);
  const distributionContactListMembers: any = useSelector((state: RootState) => state.distribution.distributionContactListMembers[selectedContact]);
  const distributionGroupList: DistributionGroupLists = useSelector((state: RootState) => state.distribution.distributionGroupList);
  const isSideBarOpen: boolean = useSelector((state: RootState) => state.sideBar.isSideBarOpen);

  useEffect(() => {
    let listOptions: ListOptions[] = [
      {
        option: t('distributionList.addList.button'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      },
      {
        option: t('distributionList.listOptions.duplicate'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      },
      {
        option: t('distributionList.listOptions.export')
      },
      {
        option: t('distributionList.listOptions.compareLists'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      }
    ];

    const allOptions: ListOptions[] = [
      {
        option: t('distributionList.addList.button'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      },
      {
        option: t('distributionList.listOptions.editListName'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      },
      {
        option: t('distributionList.listOptions.duplicate'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      },
      {
        option: t('distributionList.listOptions.mergeLists'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      },
      {
        option: t('distributionList.listOptions.compareLists'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      },
      {
        option: t('distributionList.listOptions.export')
      },
      {
        option: t('distributionList.listOptions.delete'),
        hasPermission: () => PermissionService.hasPermission(AccessLevel.READ_WRITE)
      }
    ];

    if (selectedList !== ALL_CREW_LIST_ID) {
      listOptions = [...allOptions];
    }

    setListOptions(listOptions.filter(permissionFilter));
  }, [selectedList])

  useEffect(() => {
    if (distributionListMembers) {
      setListMembers(filterListMembers(distributionListMembers.members, search));
      setNonListMembers(filterListMembers(distributionListMembers.nonMembers, search));
      if (tabIndex === 0) {
        setLoading(false);
      }
    }
  }, [distributionListMembers, search]);

  useEffect(() => {
    if (distributionContactListMembers) {
      setContactMembers(filterContactMembers(distributionContactListMembers.members, search));
      setNonContactMembers(filterContactMembers(distributionContactListMembers.nonMembers, search));
      if (tabIndex === 1) {
        setLoading(false);
      }
    }
  }, [distributionContactListMembers, search]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  useEffect(() => {
    handleResize();
  }, [isSideBarOpen, drawerOpen])

  const handleResize = (): void => {
    // dynamically set table height and width
    const windowWidth: number = window.innerWidth;
    const windowHeight: number = window.innerHeight;
    let width = windowWidth - sideBarMarginWidth - mainPadding - 50;
    const height = windowHeight - headerHeight - tabHeaderHeight - mainPadding - 70;
    if (isSideBarOpen) {
      width = width - sideBarOpenedWidth;
    } else {
      width = width - sideBarClosedWidth;
    }
    if (drawerOpen) {
      width = width - drawerWidth;
    }
    setTableWidth(width);
    setTableHeight(height);
  }

  // handling the tab change event
  const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    setTab(newTab);
    setSelected([]);
  };

  const handleSetSelected = (addSelection: string) => {
    let newSelected: string[] = [];
    if (selected.includes(addSelection)) {
      newSelected = selected.filter((id: string) => id !== addSelection);
    } else {
      newSelected = [...selected, addSelection];
    }
    setSelected(newSelected);
  }

  const selectAllMembers = (isSelected: boolean) => {
    if (isSelected) { setSelected(listMembers.map((item) => item.personId)); } else { setSelected([]); }
  }

  const selectAllNonMembers = (isSelected: boolean) => {
    if (isSelected) { setSelected(nonListMembers.map((item) => item.personId)); } else { setSelected([]); }
  }

  const selectAllContactMembers = (isSelected: boolean) => {
    if (isSelected) { setSelected(contactMembers.map((item) => item.listId)); } else { setSelected([]); }
  }

  const selectAllContactNonMembers = (isSelected: boolean) => {
    if (isSelected) { setSelected(nonContactMembers.map((item) => item.listId)); } else { setSelected([]); }
  }

  const handleShowToast = (message: string, toastType: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true
    })
  }

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  // default values for form
  const defaultValues = {
    listOption: ''
  };

  // react-hook-form functions
  const { control } = useForm({
    defaultValues
  });

  const addSelected = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setLoading(true);
    event.stopPropagation();
    if (tabIndex === 0) {
      updateDistroListMembers('addDistributionListMember', PostMethod);
    } else {
      DistributionService.addDistributionListMembersByContactCombo(selected, selectedContact)
        .then(() => {
          handleContactSelect(selectedContact);
        })
        .catch((err) => {
          setLoading(false);
          handleShowToast(err.message, 'error', ToastIcons.Info);
        })
    }
  }

  const removeSelected = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setLoading(true);
    event.stopPropagation();
    if (tabIndex === 0) {
      updateDistroListMembers('removeDistributionListMember', DeleteMethod);
    } else {
      DistributionService.removeDistributionListMemberByContactCombo(selected, selectedContact)
        .then(() => {
          handleContactSelect(selectedContact);
        })
        .catch((err) => {
          setLoading(false);
          handleShowToast(err.message, 'error', ToastIcons.Info);
        })
    }
  }

  const updateDistroListMembers = (listTransaction: string, method: string): void => {
    DistributionService.updateDistroListMembers(selected, selectedList, listTransaction, method)
      .then(() => {
        handleDistributionSelect(selectedList);
      })
      .catch((err) => {
        setLoading(false);
        handleShowToast(err.message, 'error', ToastIcons.Info);
      })
  }

  /**
   * function for handling list options
   */
  const handleListOption = (templateName: string): void => {
    if (templateName === 'Edit List Name') {
      dispatch(setDistributionListToEdit({ listId: selectedList }))
      ModalService.openCustomModal(AddDistributionModal, {
        heading: 'distributionList.editListName.heading',
        content: 'distributionList.editListName.label',
        confirmButton: 'action.confirm',
        contentValues: { name: 'EditList' },
        displayIcon: true
      });
    } else if (templateName === 'Duplicate') {
      dispatch(setDistributionListToEdit({ listId: selectedList }));
      ModalService.openCustomModal(AddDistributionModal, {
        heading: 'distributionList.duplicate.heading',
        content: 'distributionList.duplicate.label',
        confirmButton: 'action.confirm',
        contentValues: { name: 'DuplicateList' },
        displayIcon: true,
        callback: () => {
          setSelected([]);
        }
      });
    } else if (templateName === 'Merge Lists') {
      if (selectedList === ALL_CREW_LIST_ID) {
        handleShowToast('distributionList.mergeList.error.all', 'error', ToastIcons.Info);
        return;
      } else if (DistributionService.getMemberCount(selectedList) === 0) {
        handleShowToast('distributionList.mergeList.error.count', 'error', ToastIcons.Info);
        return;
      }
      DistributionService.fetchDistributionLists();

      dispatch(setDistributionListToEdit({ listId: selectedList }));
      ModalService.openCustomModal(MergeDistributionModal, {
        heading: 'distributionList.mergeList.heading',
        content: 'distributionList.mergeList.label',
        metaData: [selectedList],
        confirmButton: 'action.merge',
        displayIcon: true,
        callback: () => {
          setSelected([]);
        }
      });
    } else if (templateName === 'Export') {
      dispatch(setDistributionListToEdit({ listId: selectedList }));
      ModalService.openCustomModal(ExportDistributionModal, {
        heading: 'distributionList.exportList.heading',
        content: 'distributionList.exportList.selectListToExport',
        confirmButton: 'action.export',
        displayIcon: true
      });
    } else if (templateName === 'Delete') {
      dispatch(setDistributionListToEdit({ listId: selectedList }))
      ModalService.openCustomModal(DeleteListModal, {
        heading: 'distributionList.deleteList.heading',
        content: 'distributionList.deleteList.content',
        confirmButton: 'action.delete',
        displayIcon: true,
        callback: () => {
          setSelected([]);
          const value: string = Object.keys(distributionGroupList)[0];
          handleDistributionSelect(value);
        }
      });
    } else if (templateName === 'Compare Lists') {
      DistributionService.fetchDistributionLists();
      dispatch(setDistributionListToEdit({ listId: selectedList }))
      ModalService.openCustomModal(CompareListsModal, {
        heading: 'distributionList.compareLists.heading',
        content: 'distributionList.compareLists.content',
        displayIcon: false
      });
    } else if (templateName === t('distributionList.addList.button')) {
      ModalService.openCustomModal(AddDistributionModal, {
        heading: 'distributionList.addList.heading',
        content: 'distributionList.addList.content',
        confirmButton: 'action.confirm',
        displayIcon: true,
        contentValues: { name: 'AddList' },
        callback: () => {
          setSelected([]);
        }
      });
    }
  };

  /*  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  }; */

  return (
    <div className={styles.DistributionLists} data-testid="DistributionLists">
      <Grid
        item
        container
        direction={'row'}
        alignItems={'center'}
        spacing={2}
        sx={{
          textAlign: 'left',
          justifyContent: 'space-between',
          width: tableWidth + 10
        }}>
        { <Grid item xs="auto">
          <FormInputSelect
            name={'listOption'}
            placeholder={String(t('distributionList.listOptions.label'))}
            startAdornmentValue={<SettingsOutlinedIcon />}
            formItemProps={{
              control
            }}
            onItemClick={handleListOption}
            options={listOptions.map((template: ListOptions) => ({ key: template.option, label: template.option, value: template.option }))}
            alwaysShowPlaceholder={true}
          />
        </Grid>}
        <Grid item xs="auto">
          {PermissionService.hasPermission(AccessLevel.READ_WRITE) &&
            <div className={actionBtnStyles.actionBtnContainer}>
              {(tab === 0)
                ? (selected.length > 0)
                    ? <DeleteButton onClick={removeSelected}>{t('distributionList.removeSelected')}</DeleteButton>
                    : <DisabledButton disabled={true}>{t('distributionList.removeSelected')}</DisabledButton>
                : (selected.length > 0)
                    ? <SuccessButton onClick={addSelected}>{t('distributionList.addSelected')}</SuccessButton>
                    : <DisabledButton disabled={true} >{t('distributionList.addSelected')}</DisabledButton>
              }
            </div>
          }
        </Grid>
      </Grid>
      <Box className={styles.tabPanel} sx={{
        '& .tabHeader': {
          color: ChipColor,
          fontWeight: 700,
          fontStyle: 'bold'
        }
      }}>
        <Grid container direction={'row'} style={{ marginBottom: '18px' }}>
          <Grid item xs={8}>
            <TabHeaders tab={tab} onChange={handleTabChange} tabs={tabs} />
          </Grid>
          <Grid item xs={4} className={styles.searchBar}>
            {/* {tabIndex === 0 ? <SearchBar placeholder={String(t('common.labels.searchContacts'))} onChange={handleSearch} margin={'0px'} maxWidth={'250px'} /> : null} */}
          </Grid>
        </Grid>
        {(tabIndex === 0) &&
        <>
          <TabPanel value={tab} index={0}>
            <DistributionListsTable
              members={listMembers}
              selected={selected}
              setSelected={handleSetSelected}
              selectAll={selectAllMembers}
              selectedList={selectedList}
              tableWidth={tableWidth}
              tableHeight={tableHeight}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <DistributionListsTable
              members={nonListMembers}
              selected={selected}
              setSelected={handleSetSelected}
              selectAll={selectAllNonMembers}
              selectedList={selectedList}
              tableWidth={tableWidth}
              tableHeight={tableHeight}
            />
          </TabPanel>
        </>
        }
        {(tabIndex === 1) &&
        <>
          <TabPanel value={tab} index={0}>
            <ContactListsTable
              lists={contactMembers}
              selected={selected}
              setSelected={handleSetSelected}
              selectAll={selectAllContactMembers}
              tableWidth={tableWidth}
              tableHeight={tableHeight}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ContactListsTable
              lists={nonContactMembers}
              selected={selected}
              setSelected={handleSetSelected}
              selectAll={selectAllContactNonMembers}
              tableWidth={tableWidth}
              tableHeight={tableHeight}
            />
          </TabPanel>
        </>
        }
      </Box>
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={String(t(toastStatus.message))}
        icon={toastStatus.icon}
      />
    </div>
  )
};

export default DistributionLists;

function filterListMembers (members: DistributionListMembers[], search: string | null) {
  if (!search || !members) {
    return members;
  }

  return members.filter(c => {
    return c.firstName.toLowerCase().includes(search.toLowerCase()) ||
      c.lastName.toLowerCase().includes(search.toLowerCase()) ||
      c.jobTitle.toLowerCase().includes(search.toLowerCase()) ||
      (c.department ?? '').toLowerCase().includes(search.toLowerCase());
  });
}

function filterContactMembers (members: DistributionListWithContacts[], search: string | null) {
  if (!search || !members) {
    return members;
  }

  return members.filter(c => {
    return c.name.toLowerCase().includes(search.toLowerCase());
  });
}
