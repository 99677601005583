import React, { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import type { GridRowId } from '@mui/x-data-grid';
import DistributionReport from '../DistributionReport/DistributionReport';
import SlideDrawer from '../slideDrawer/SlideDrawer';
import EmailDrawer from './EmailDrawer/EmailDrawer';
import MainSection from '../common/MainSection';
import type { Anchor } from '../../models/Anchor';
import type { RootState } from '../../state/store';
import DistributionService from '../../services/distribution/DistributionService';
import MessageService from '../../services/message/MessageService';
import DistributionEditor from './DistributionEditor/DistributionEditor.lazy';
import type { DistributionMessage } from '../../models/DistributionMessage';
import type { MessageStatus } from '../../models/Message';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DistributionHistory.module.scss';
import { Grid } from '@mui/material';
import type { AlertColor } from '@mui/material';
import NothingToSee, { NothingToSeeEntityType } from '../nothingToSee/NothingToSee';
import { resetDraft } from '../../state/slices/DraftReducer';
import Toast from '../common/toast/Toast';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { setPageTitle } from '../../state/slices/pageTitle';
import type { DistributionMessagesKeys } from '../../services/distribution/constants';
import PersonService from '../../services/person/PersonService';
import type { Department, DepartmentResponse } from '../../models/Department';
import type { Person } from '../../models/Person';
import { drawerWidth } from '../../theme/styles';

const drawerAnchor: Anchor = 'left';

export enum DistributionHistoryPage {
  Drafts = 'Drafts',
  Outbox = 'Outbox',
  Sent = 'Sent'
}

const originalPageNumber: number = 1;

export interface Props {
  pageTitle: DistributionHistoryPage
  messageStatus: MessageStatus
  messageKey: DistributionMessagesKeys
}

/**
 * Distribution History Component used for the displaying distribution messages
 * Either sent, outbox or draft
 */
const DistributionHistory: FC<Props> = ({ pageTitle, messageStatus, messageKey }: Props) => {
  const dispatch = useDispatch();
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const [open, setOpen] = useState(true);
  const [isReportOpen, setReportOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<DistributionMessage | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastType, setToastType] = useState<AlertColor>('success');
  const [toastContent, setToastContent] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(originalPageNumber);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);

  const buttonText: string = `distributionHistory.pageTitle.${pageTitle}`;

  const distributionMessages: DistributionMessage[] = useSelector((state: RootState) => state.distribution.distributionMessages[pageTitle]);
  const persons: Person[] = useSelector((state: RootState) => state.distribution.personList);
  const departments: DepartmentResponse[] = useSelector((state: RootState) => state.person.departments);

  useEffect(() => {
    DistributionService.loadDistributionGroupAndMembers();
    PersonService.fetchDepartments();
    DistributionService.fetchDistributionListNames();
    DistributionService.fetchEmailList();
    DistributionService.fetchBasicContactInfo();
  }, []);

  useEffect(() => {
    if (pageTitle) {
      setPageNumber(originalPageNumber);
      dispatch(setPageTitle(pageTitle));
      dispatch(resetDraft({ key: messageKey }));
      DistributionService.fetchMessageHistory(pageTitle, messageStatus);
      setSelectedMessage(null);
      setReportOpen(false);
    }
  }, [pageTitle, messageStatus]); // handle changing of distribution history page / type - load data needed

  useEffect(() => {
    setDepartmentList(PersonService.addPersonToDepartments(departments, persons));
  }, [persons, departments]);

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  const handleReportOpen = (msgId: GridRowId): void => {
    DistributionService.generateDistroReports(String(msgId));
    setReportOpen(true);
  };

  const handleShowToast = (): void => {
    setShowToast(!showToast);
  };

  const handleDraftDeleted = (): void => {
    dispatch(resetDraft({ key: messageKey }));
    setSelectedMessage(null);
    setToastType('success');
    setToastContent('Draft was deleted successfully');
    handleShowToast();
  };

  const handleAllDraftsDeleted = (): void => {
    dispatch(resetDraft({ key: messageKey }));
    setSelectedMessage(null);
  };

  useEffect(() => {
    if (distributionMessages.length > 0) {
      handleMessageSelect(distributionMessages[0].id);
    }
  }, [distributionMessages])

  // handle selecting of message in slide drawer & set to draft to display in compose editor
  const handleMessageSelect = useCallback((msgId: GridRowId): void => {
    const message: DistributionMessage | undefined = distributionMessages.find((message: DistributionMessage) => message.id === msgId);
    if (message && message.id !== selectedMessage?.id) {
      MessageService.distributionToDraft(message, messageKey);
      setSelectedMessage(message);

      if (pageTitle === DistributionHistoryPage.Sent) {
        handleReportOpen(message.id);
      }
    }
  }, [distributionMessages, setSelectedMessage, selectedMessage]);

  const showNothingToSee = !selectedMessage?.id && (pageTitle === DistributionHistoryPage.Sent || pageTitle === DistributionHistoryPage.Drafts || pageTitle === DistributionHistoryPage.Outbox);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <SlideDrawer
        drawerWidth={drawerWidth}
        open={open}
        anchor={drawerAnchor}
        toggleDrawer={toggleDrawer}
        buttonText={buttonText}
      >
        <EmailDrawer
            pageTitle={pageTitle}
            messageStatus={messageStatus}
            isReportOpen={isReportOpen}
            openReport={handleReportOpen}
            closeReport={() => { setReportOpen(false); }}
            handleMessageSelect={handleMessageSelect}
            onDraftDeleted={handleAllDraftsDeleted}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setSelectedMessage={setSelectedMessage}
        />
      </SlideDrawer>
      <MainSection open={open} drawerwidth={drawerWidth}>
        <Grid
          container
          spacing={2}
          className={styles.DistributionHistoryContent}
          sx={{
            paddingTop: '30px',
            paddingLeft: '30px'
          }}
        >
          {showNothingToSee &&
            <Grid item className={styles.composeBoxEmpty}>
              <NothingToSee entityType={NothingToSeeEntityType.Draft}/>
            </Grid>
          }

          {!isReportOpen && selectedMessage?.id &&
            <DistributionEditor
              pageTitle={pageTitle}
              messageId={selectedMessage.id}
              openReport={handleReportOpen}
              onDraftDeleted={handleDraftDeleted}
              messageKey={messageKey}
              departmentList={departmentList}
            />
          }
          {isReportOpen && selectedMessage?.id &&
            <DistributionReport closeReport={() => { setReportOpen(false); }} isDrawerOpen={open} />
          }
        </Grid>
      </MainSection>
      <Toast open={showToast} onClose={handleShowToast} type={toastType} title={String(t(toastContent))} />
    </Box>
  )
};

export default DistributionHistory;
