import React, { lazy, Suspense } from 'react';
import type { LatestDistroProps } from './LatestDistro';

const LazyLatestDistro = lazy(async () => await import('./LatestDistro'));

const LatestDistro = (props: LatestDistroProps) => (
  <Suspense fallback={null}>
    <LazyLatestDistro {...props} />
  </Suspense>
);

export default LatestDistro;
