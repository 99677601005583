import type { SxProps, Theme } from '@mui/material';
import { paperStyle } from '../../theme/styles';

// Custom label input line style
export const lineInputStyle: SxProps<Theme> = {
  border: '1px solid rgba(0,0,0,0.15)',
  borderRadius: '20px',
  paddingX: '10px',
  width: '100%',
  fontSize: '0.8rem',
  height: '32px'
}

// Paper style for the opacity slider container
export const opacityPaperStyle: SxProps<Theme> = {
  ...paperStyle,
  position: 'absolute',
  top: '30px',
  left: '0',
  width: '200px',
  minHeight: 0,
  margin: 0,
  paddingX: '10px',
  lineHeight: 0,
  zIndex: 100
}

// Type of output for the watermarked file
export type OutputMode = 'combined' | 'individual' | '';

// Available options for the first watermark line
export enum FirstLine {
  Name = 'FullName',
  Label = 'Other'
}

// Available options for the second watermark line
export enum SecondLine {
  Blank = 'Blank',
  Label = 'Other',
  Title = 'Title',
  Department = 'Department',
  Date = 'Date',
  IpAddress = 'IPAddress'
}

// Labels of all first line options
export const firstLineLabels: { [key in FirstLine]: string } = {
  FullName: 'watermark.firstLine.name',
  Other: 'watermark.firstLine.label'
}

// Labels of all second line options
export const secondLineLabels: { [key in SecondLine]: string } = {
  Blank: 'watermark.secondLine.blank',
  Title: 'watermark.secondLine.title',
  Department: 'watermark.secondLine.department',
  Date: 'watermark.secondLine.date',
  IPAddress: 'watermark.secondLine.ipAddress',
  Other: 'watermark.secondLine.other'
}

export const drawerWidth: number = 332;
export const maxNbOfSelectedFiles: number = 5;
export const defaultOpacity: number = 25;
export const defaultSize: number = 100;
export const defaultOrientation: number = 45;
export const defaultOutputMode: OutputMode = 'combined';
