import React, { lazy, Suspense } from 'react';

const LazyCompareLists = lazy(async () => await import('./CompareLists'));

const CompareLists = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyCompareLists {...props} />
  </Suspense>
);

export default CompareLists;
