import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import styles from './DashboardFolders.module.scss';
import type { DashboardCategories } from '../../models/Document';
import LatestDistro from './LatestDistro/LatestDistro.lazy';
import TemplateFolders from './TemplateFolders/TemplateFolders.lazy';
import type { ToastIcons } from '../../models/Toast';
import DashboardService from '../../services/DashboardService';
import TabHeaders from '../slideDrawer/TabHeaders/TabHeaders.lazy';
import TabPanel from '../slideDrawer/TabPanel';
import type { TemplateItem } from '../../models/Template';
import { useAppSelector } from '../../state/hooks';
import type { RootState } from '../../state/store';
import type { AlertColor } from '@mui/material';
import { ProductionTypes } from '../../state/slices/AuthReducer';

export interface DashboardFoldersProps {
  handleChangeDashboardCategory: (category: DashboardCategories) => void
  handleShowToast: (message: string, type: AlertColor, icon: ToastIcons) => void
  isAdminUser: boolean
  dashboardTemplates: TemplateItem[]
  productionType: string
}

/**
 * Component showing distribution files and templates in dashboard
 * Files are only shown if not a restricted community
 * Templates shown all the time
 */
const DashboardFolders: FC<DashboardFoldersProps> = ({
  handleChangeDashboardCategory,
  handleShowToast,
  isAdminUser,
  dashboardTemplates,
  productionType
}: DashboardFoldersProps) => {
  const [tab, setTab] = useState<number>(0);
  const [tabs, setTabs] = useState<string[]>([]);

  const isRestrictedCommunity: boolean = useAppSelector((state: RootState) => state.dashboard.restrictedCommunity);

  useEffect(() => {
    let tabsAvailable: string[] = ['dashboard.foldersList.latestDistro', 'dashboard.foldersList.productionForms'];

    if (isRestrictedCommunity || productionType === ProductionTypes.TIME || productionType === ProductionTypes.ASSISTANT) {
      tabsAvailable = tabsAvailable.slice(1);
    }
    setTabs(tabsAvailable);
  }, [isRestrictedCommunity, productionType])

  const handleTabChange = (event: React.SyntheticEvent, newTab: number): void => {
    event.stopPropagation();
    const categories: DashboardCategories[] = DashboardService.getDashboardCategoryList();
    setTab(newTab);
    handleChangeDashboardCategory(categories[newTab]);
  }

  return (
    <div className={styles.DashboardFolders}>
      <div className={styles.tabHeader}>
        <TabHeaders tab={tab} onChange={handleTabChange} tabs={tabs} sx={{ fontSize: '1.1rem' }} />
      </div>
      {(!isRestrictedCommunity && productionType !== ProductionTypes.TIME && productionType !== ProductionTypes.ASSISTANT)
        ? <>
          <TabPanel index={0} value={tab}>
            <LatestDistro handleShowToast={handleShowToast} isAdminUser={isAdminUser} />
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <TemplateFolders dashboardTemplates={dashboardTemplates} />
          </TabPanel>
          </>
        : <TabPanel index={0} value={tab}>
            <TemplateFolders dashboardTemplates={dashboardTemplates} />
          </TabPanel>
      }
    </div>
  );
}

export default DashboardFolders;
