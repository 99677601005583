import type { Descendant } from 'slate';

export interface CustomText {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  color?: string
  backgroundColor?: string
  text: string
  style?: object
  className?: string
  id?: string
}

export interface CustomElement {
  type: string
  align?: 'left' | 'center' | 'right'
  children: CustomText[]
  fontFamily?: string
  fontSize?: string
  color?: string
  backgroundColor?: string
  url?: string
}

interface ISlateToHtmlService {
  slateContentToHtml: (value: any) => any
  htmlContentToSlate: (value: any) => any
}

const convertStyleObjectToString = (styleObj: object): string[] => {
  return Object.entries(styleObj)
    .map(([key, value]) => {
      const kebabKey = key.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);
      return `${kebabKey}: ${String(value)}`;
    });
}

const serializeNode = (node: Descendant): string => {
  if ('text' in node) {
    const textNode = node as CustomText;
    let text = textNode.text;

    if (textNode.bold) {
      text = `<strong>${text}</strong>`;
    }

    if (textNode.italic) {
      text = `<em>${text}</em>`;
    }

    if (textNode.underline) {
      text = `<u>${text}</u>`;
    }
    const style = (textNode.style) ? convertStyleObjectToString(textNode.style) : [];
    if (textNode.fontSize) {
      style.push(`font-size: ${textNode.fontSize}`);
    }
    if (textNode.fontFamily) {
      style.push(`font-family: ${textNode.fontFamily}`);
    }
    if (textNode.color) {
      style.push(`color: ${textNode.color}`);
    }
    if (textNode.backgroundColor) {
      style.push(`backgroundColor: ${textNode.backgroundColor}`);
    }

    const classNameString: string = (textNode.className) ? `class="${textNode.className}"` : '';
    const idString: string = (textNode.id) ? `id="${textNode.id}"` : '';

    // Wrap the text in a <span> with inline styles, if any
    if (style.length > 0) {
      text = `<span ${idString} style="${style.join('; ')}" ${classNameString}>${text}</span>`;
    }

    return text;
  }
  const element = node as CustomElement;
  const blockStyle = element.align ? ` style="text-align: ${element.align};"` : '';
  switch (element.type) {
    case 'paragraph':
      return `<p${blockStyle}>${element.children.map(serializeNode).join('')}</p>`;
    case 'block-quote':
      return `<blockquote${blockStyle}>${element.children.map(serializeNode).join('')}</blockquote>`;
    case 'list-item':
      return `<li${blockStyle}>${element.children.map(serializeNode).join('')}</li>`;
    case 'bulleted-list':
      return `<ul${blockStyle}>${element.children.map(serializeNode).join('')}</ul>`;
    case 'numbered-list':
      return `<ol${blockStyle}>${element.children.map(serializeNode).join('')}</ol>`;
    case 'image':
      return (element.url) ? `<img${blockStyle} src="${element.url}"/>` : '';
    case 'link':
      return (element.url) ? `<a${blockStyle} href="${element.url}">${element.children.map(serializeNode).join('')}</a>` : '';
    default:
      return `<div${blockStyle}>${element.children.map(serializeNode).join('')}</div>`;
  }
}

const parseStyleString = (styleString: string) => {
  const styleObj: Record<string, string> = {};

  styleString.split(';').forEach(style => {
    const [key, value] = style.split(':').map(item => item.trim());
    if (key && value) {
      const formattedKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase()); // Convert 'background-color' to 'backgroundColor'
      styleObj[formattedKey] = value;
    }
  });

  return styleObj;
}

const convertNodeToSlate = (
  node: ChildNode,
  styles: CustomText = { text: '' },
  listContext?: 'numbered-list' | 'bulleted-list'
): any => {
  if (node.nodeType === Node.TEXT_NODE) {
    return {
      ...styles,
      text: node.textContent ?? ''
    };
  }
  if (node.nodeType === Node.ELEMENT_NODE) {
    const element = node as HTMLElement;
    const updatedStyles = { ...styles };
    const style = element.getAttribute('style');
    const src = element.getAttribute('src');
    let blockAlign: string | undefined;
    switch (element.tagName.toLowerCase()) {
      case 'p':
      case 'div':
        if (style) {
          const styleObj = parseStyleString(style);
          if (styleObj.textAlign) {
            blockAlign = styleObj.textAlign;
          }
        }
        return {
          type: 'paragraph',
          align: blockAlign,
          children: Array.from(element.childNodes)
            .map(childNode => convertNodeToSlate(childNode, updatedStyles))
            .filter(Boolean) as CustomText[]
        };
      case 'ul':
        return {
          type: 'bulleted-list',
          children: Array.from(element.childNodes)
            .map(childNode => convertNodeToSlate(childNode, updatedStyles, 'bulleted-list'))
            .filter(Boolean) as CustomElement[]
        };
      case 'ol': // Handle ordered lists
        return {
          type: 'numbered-list',
          children: Array.from(element.childNodes)
            .map(childNode => convertNodeToSlate(childNode, updatedStyles, 'numbered-list'))
            .filter(Boolean) as CustomElement[]
        };
      case 'li': // Handle list items
        return {
          type: 'list-item',
          children: Array.from(element.childNodes)
            .map(childNode => convertNodeToSlate(childNode, updatedStyles, listContext))
            .filter(Boolean) as CustomText[]
        };
      case 'strong':
        updatedStyles.bold = true;
        break;
      case 'em':
        updatedStyles.italic = true;
        break;
      case 'u':
        updatedStyles.underline = true;
        break;
      case 'span':
        if (style) {
          const styleObj = parseStyleString(style);
          if (styleObj.color) {
            updatedStyles.color = styleObj.color;
          }
          if (styleObj.backgroundColor) {
            updatedStyles.backgroundColor = styleObj.backgroundColor;
          }
          if (styleObj.fontFamily) {
            updatedStyles.fontFamily = styleObj.fontFamily;
          }
          if (styleObj.fontSize) {
            updatedStyles.fontSize = styleObj.fontSize;
          }
          if (styleObj.fontWeight) {
            updatedStyles.fontWeight = styleObj.fontWeight;
          }
        }
        return {
          type: 'span',
          style: { ...updatedStyles },
          children: Array.from(element.childNodes)
            .map(childNode => convertNodeToSlate(childNode, updatedStyles))
            .filter(Boolean) as CustomText[]
        }
      case 'a': {
        const href = element.getAttribute('href') ?? '';
        if (href) {
          return {
            type: 'link',
            url: href,
            children: Array.from(element.childNodes)
              .map(childNode => convertNodeToSlate(childNode, updatedStyles))
              .filter(Boolean) as CustomText[]
          };
        }
        break;
      }
      case 'img':
        if (src) {
          return {
            type: 'image',
            url: src,
            children: [{ text: '' }]
          }
        }
        break;
    }
    const childNodes = Array.from(element.childNodes).map(childNode => convertNodeToSlate(childNode, updatedStyles, listContext));
    if (childNodes.length === 1 && childNodes[0]?.text) {
      return childNodes[0];
    }
    return childNodes.reduce((acc: CustomText | null, child) => {
      if (acc?.text && child?.text) {
        acc.text += String(child.text);
        return acc;
      }
      return acc ?? child;
    }, null);
  }

  return null;
}

const ensureValidSlateNode = (node: any): Descendant => {
  if (!node.children || node.children.length === 0) {
    return {
      ...node,
      children: [{ text: '' }]
    };
  }
  return node;
}

class SlateToHtmlService implements ISlateToHtmlService {
  slateContentToHtml (nodes: Descendant[]): string {
    return nodes.map(serializeNode).join('');
  }

  htmlContentToSlate (value: string): Descendant[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    return Array.from(doc.body.childNodes).map(node => {
      return convertNodeToSlate(node);
    }).filter((node): node is CustomText | CustomElement => node !== null)
      .map(ensureValidSlateNode);
  }
}
export const slateToHtmlService: ISlateToHtmlService = new SlateToHtmlService();
export default slateToHtmlService;
