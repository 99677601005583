import type { Dispatch, FC, SetStateAction } from 'react';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../state/store';
import { Grid, Box, Divider, ButtonGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import type { ProductionOfficeUser } from '../../models/Production';
import type { DistributionEmailTemplateName, DistributionEmailTemplate } from '../../models/DistributionEmailTemplate';
import type { SelectedGroup } from '../../models/DistributionList';
import type { MessageForm } from '../../models/Message';
import { MessageStatus } from '../../models/Message';
import type { Draft } from '../../models/Draft';
import type { Department } from '../../models/Department';
import DistributionService from '../../services/distribution/DistributionService';
import MessageService from '../../services/message/MessageService';
import SlateTextEditor from '../textEditor/SlateTextEditor';
import TertiaryButton from '../common/buttons/TertiaryButton';
import SpecialButton from '../common/buttons/SpecialButton';
import FileUpload from '../fileUpload/FileUpload';
import ComposeTo from './ComposeTo';
import ComposeRecipient from './ComposeRecipient';
import ComposeSubject from './ComposeSubject';
import FormInputSelect from '../forms/FormInputSelect';
import FormInputTextField from '../forms/FormInputLongTextField';
import BasicMenuDropdown from '../common/BasicMenuDropdown';
import Tooltip from '../common/Tooltip';
import SaveTemplateFlow from '../saveTemplate/SaveTemplateFlow';
import ScheduleSendFlow from '../scheduleSend/ScheduleSendFlow';
import SearchFilesFlow from '../searchFiles/SearchFilesFlow';
import TextSMSInput from '../common/TextSMSInput';
import Toast from '../common/toast/Toast';
import ChipAttachArea from '../ChipAttachArea';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import {
  ToastPink,
  MainWhite,
  BorderGrey,
  MainBlack,
  CrooglooGreen,
  DarkerCroolooGreen
} from '../../theme/colors';
import {
  headerHeight,
  mainPadding,
  ComposeToHeight,
  ComposeSubjectHeight,
  ComposeChipHeight
} from '../../theme/styles';
import DraftService from '../../services/draft/DraftService';
import { resetDraft, setMessageToSend } from '../../state/slices/DraftReducer';
import { TemplateDraft, OriginalMsgId } from '../../services/draft/constants';
import { DistributionHistoryPage } from '../DistributionHistory/DistributionHistory';
import { ComposePage } from '../../routes/Compose';
import ClearModal, { ClearEditorModal } from '../modals/ClearModal';
import NoSubject from '../modals/NoSubject';
import PreviewSend, { PreviewSendModal } from '../modals/PreviewSend';
import NoRecipients from '../modals/NoRecipients';
import ConfirmSend from '../modals/ConfirmSend';
import SettingsService from '../../services/SettingsService';
import ModalService from '../../services/ModalService';
import { TemplateWarningModal } from '../modals/TemplateWarning/constants';
import TemplateWarning from '../modals/TemplateWarning/TemplateWarning.lazy';
import InvalidRecipients from '../modals/InvalidRecipients/InvalidRecipients.lazy';
import NoAttachment from '../modals/NoAttachment/NoAttachment.lazy';
import AttachmentError from '../modals/AttachmentError/AttachmentError.lazy';
import IncludeAgents from '../modals/IncludeAgents/IncludeAgents.lazy';
import InactiveContacts from '../modals/InactiveContacts/InactiveContacts.lazy';
import SendingFilesError from '../modals/SendingFilesError/SendingFilesError.lazy';
import MissingFiles from '../modals/MissingFiles/MissingFiles.lazy';
import { SaveTemplateModal } from '../saveTemplate/StepsModel';
import { SearchFilesModal } from '../searchFiles/StepsModel';
import type { FileAttachment } from '../../models/Document';
import FormCheckbox from '../forms/FormCheckbox';
import type { OptionProps } from '../../models/OptionProps';
import { LinkOptionsModal } from '../modals/LinkOptions/constants';
import LinkOptions from '../modals/LinkOptions/LinkOptions.lazy';
import UploadService from '../../services/UploadService';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import type { AlertColor } from '@mui/material';
import MessageSent from '../modals/MessageSent/MessageSent.lazy';
import { ScheduleSendModal } from '../scheduleSend/constants';
import PendingContacts from '../modals/PendingContacts/PendingContacts.lazy';
import { UploadFileLocation } from '../../models/Upload';
import type { ToastProps } from '../../models/Toast';
import { ToastIcons } from '../../models/Toast';
import type { DistributionMessagesKeys } from '../../services/distribution/constants';
import FileViewerPopup from '../FileViewerPopup/FileViewerPopup.lazy';
import UtilityService from '../../services/UtilityService';
import LoadingProgress from '../common/LoadingProgress/LoadingProgress.lazy';
import { DeleteTemplateModal } from '../modals/DeleteTemplate/constants';
import DeleteTemplate from '../modals/DeleteTemplate/DeleteTemplate.lazy';
import moment from 'moment';
import type { Descendant } from 'slate';
import SlateToHtmlService from '../../services/SlateToHtmlService';

interface ComposeProps {
  pageTitle: DistributionHistoryPage | ComposePage
  messageKey: DistributionMessagesKeys
  departmentList: Department[]
  setListPanelOpen?: Dispatch<SetStateAction<boolean>>
  toggleDrawer: () => void
}

const templateOptions: OptionProps[] = [
  {
    key: 'save',
    value: 'save',
    label: 'compose.form.action.saveTemplate'
  },
  {
    key: 'saveAs',
    value: 'saveAs',
    label: 'compose.form.action.saveAs'
  },
  {
    key: 'delete',
    value: 'delete',
    label: 'compose.form.action.deleteTemplate'
  }
]

const linkChkOptions: OptionProps[] = [
  {
    key: 'sendAsLinkChk',
    value: 'sendAsLinkChk',
    label: 'compose.form.labels.sendAsLinkChk'
  }
];

const DraftSavingInterval: number = 2 * 60 * 1000; // save draft every 2 minutes
const MinuteAndAHalf: number = 90000;
let lastSaveTime: number = moment().valueOf();
let hasLastDraftAutoSaved: boolean = false;
let insertCallback: any;
const initialEditorValue = [{
  type: 'paragraph',
  children: [{ text: '' }]
}];

/**
 * Compose Editor Component used for the compose page
 */
const ComposeEditor: FC<ComposeProps> = ({
  pageTitle,
  messageKey,
  departmentList,
  setListPanelOpen,
  toggleDrawer
}: ComposeProps) => {
  const dispatch = useDispatch();
  // template names gotten from backend
  const productionTemplates: DistributionEmailTemplateName[] = useSelector((state: RootState) => state.distribution.distributionEmailTemplateNames);
  const replyToUsers: ProductionOfficeUser[] = useSelector((state: RootState) => state.distribution.replyToUsers);
  const cachedReplyToEmail: string | null = useSelector((state: RootState) => state.distribution.cachedReplyToEmail);
  const isSendingMessage: boolean = useSelector((state: RootState) => state.distribution.isSendingMessage);

  // states for different modals on the compose page
  const [insertRecipientsEl, setInsertRecipientsEl] = useState<null | HTMLElement>(null);
  const [showSms, setShowSms] = useState<boolean>(false);
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  // Group arrays of list and department groups to included in email
  const [groupList, assignGroupList] = useState<SelectedGroup[]>([]);
  const [groupDepartment, assignGroupDepartment] = useState<SelectedGroup[]>([]);
  const [selectedExtraEmails, assignSelectedExtraEmails] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<FileAttachment[]>([]);
  const [editorHeight, setEditorHeight] = useState<number>(450);
  const [totalHeight, setTotalHeight] = useState<number>(450);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contentValue, setContentValue] = useState<Descendant[]>(initialEditorValue);
  const [changeEditorValue, setChangeEditorValue] = useState<boolean>(false);
  const [resetEditor, setResetEditor] = useState<boolean>(false);

  const draftgroupList: SelectedGroup[] = useSelector((state: RootState) => state.draft.drafts[messageKey].groupList);
  const draftgroupDepartment: SelectedGroup[] = useSelector((state: RootState) => state.draft.drafts[messageKey].groupDepartment);
  const draftSelectedExrtaEmails: string[] = useSelector((state: RootState) => state.draft.drafts[messageKey].selectedExtraEmails);
  const uploadFiles: FileAttachment[] = useSelector((state: RootState) => state.uploadFiles.files);

  const draft: Draft = useSelector((state: RootState) => state.draft.drafts[messageKey].draft);

  const [toastStatus, setToastStatus] = useState<ToastProps>({ message: '', isShown: false, type: 'success' });

  // default values for form
  const defaultValues: MessageForm = {
    msgId: OriginalMsgId,
    replyTo: '',
    subject: '',
    template: '',
    templateOption: '',
    smsmessage: '',
    sendAsLinkChk: false
  };

  // react-hook-form functions
  const { control, handleSubmit, reset, getValues, setValue } = useForm<MessageForm>({
    defaultValues
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  useEffect(() => {
    if (pageTitle === ComposePage.Compose) {
      const interval = setInterval(() => {
        if (moment().valueOf() - lastSaveTime > DraftSavingInterval * 2 / 3) {
          saveDraft(true);
        }
      }, DraftSavingInterval);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (cachedReplyToEmail) {
      setValue('replyTo', cachedReplyToEmail);
    }
  }, [cachedReplyToEmail])

  useEffect(() => {
    assignGroupList(draftgroupList);
  }, [draftgroupList, assignGroupList]);

  useEffect(() => {
    assignGroupDepartment(draftgroupDepartment);
  }, [draftgroupDepartment, assignGroupDepartment]);

  useEffect(() => {
    assignSelectedExtraEmails(draftSelectedExrtaEmails)
  }, [draftSelectedExrtaEmails, assignSelectedExtraEmails])

  // fills form fields if a new draft is set in redux (either template or distributionMessage)
  useEffect(() => {
    if (draft !== null && draft !== undefined) {
      lastSaveTime = moment().valueOf();
      const msgId: string = draft.id ?? OriginalMsgId;
      const newForm = { msgId, subject: '', replyTo: '', template: '', smsmessage: '' };
      let uploadedAttachments = [];
      if (draft.type === TemplateDraft && draft.templateName) {
        newForm.template = draft.templateName;
      }
      const isRestoringRecipients: boolean = draft.restoreRecipients;
      const isRestoringContent: boolean = draft.restoreContent;

      newForm.replyTo = (draft.replyTo !== null && draft.replyTo !== undefined) ? MessageService.loadReplyTo(draft.replyTo) : getValues('replyTo');

      if (isRestoringRecipients) {
        MessageService.loadExtraEmails(messageKey);
        // load lists from draft
        DraftService.loadDraftLists(messageKey);
        // load departments from draft
        DraftService.loadDraftDepartments(departmentList, messageKey);
      }

      if (isRestoringContent) {
        // set the subject for form from draft
        newForm.subject = (draft.subject !== null && draft.subject !== undefined) ? MessageService.loadSubject(draft.subject) : '';
        // set the unsigned message for form from draft
        if (draft.unsignedMessage !== null && draft.unsignedMessage !== undefined) {
          const newSlateMessage: Descendant[] = SlateToHtmlService.htmlContentToSlate(draft.unsignedMessage);
          setContentValue(newSlateMessage);
          setChangeEditorValue(true);
        }
        if (draft.smsmessage !== null && draft.smsmessage !== undefined) {
          newForm.smsmessage = draft.smsmessage.trim();
        }
        if (draft.uploadedFilesJSON) {
          uploadedAttachments = draft.uploadedFilesJSON;
        }
      }
      // set new form fields
      reset(newForm);
      setAttachments(uploadedAttachments);
    }
  }, [draft]);

  useEffect(() => {
    handleResize();
  }, [attachments])

  const handleResize = (): void => {
    const windowHeight: number = window.innerHeight;
    let height: number = windowHeight - headerHeight - mainPadding - 50;
    setTotalHeight(height);
    height = height - ComposeToHeight - ComposeSubjectHeight - 50;
    if (attachments.length > 0) {
      height = height - ComposeChipHeight;
    }
    setEditorHeight(height);
  }

  const handleIsLoading = (): void => {
    setIsLoading((prevState: boolean) => !prevState);
  }

  const clickSendAsLinkChk = (item: string): void => {
    setValue('sendAsLinkChk', !getValues('sendAsLinkChk'));
  }

  const checkSendAsLinkChk = (item: string): boolean => {
    let checked = false;
    if (getValues('sendAsLinkChk')) {
      checked = true;
    }
    return checked;
  }

  const handleLinkOptions = (): void => {
    ModalService.openCustomModal(
      LinkOptionsModal,
      {
        heading: 'compose.linkOptions.heading',
        confirmButton: 'action.confirm',
        buttons: 'end'
      }
    );
  }

  const handleClearModal = (): void => {
    ModalService.openCustomModal(
      ClearEditorModal,
      {
        content: 'compose.form.message.clearConfirm',
        heading: 'compose.form.message.clearMessage',
        confirmButton: 'action.confirm',
        buttons: 'end'
      }
    );
  }

  const handlePreviewModal = (): void => {
    ModalService.openCustomModal(
      PreviewSendModal,
      {
        content: 'compose.form.message.previewMessage',
        heading: 'compose.form.message.previewHeading',
        confirmButton: 'action.confirm'
      }
    );
  }

  const handleTemplateWarning = (template: DistributionEmailTemplate): void => {
    ModalService.openCustomModal(
      TemplateWarningModal,
      {
        content: 'compose.templateWarning.content',
        heading: 'compose.templateWarning.heading',
        confirmButton: 'action.confirm',
        template
      }
    )
  }

  const handelSetSaveTemplate = (saveAs?: boolean): void => {
    const templateName: string = (saveAs) ? '' : getValues('template');
    ModalService.openCustomModal(
      SaveTemplateModal,
      {
        content: '',
        heading: '',
        confirmButton: 'action.confirm',
        metaData: [templateName]
      }
    )
  }

  const handleSearchFiles = (): void => {
    ModalService.openCustomModal(
      SearchFilesModal,
      {
        content: '',
        heading: 'compose.searchFiles.SelectDocuments.title',
        confirmButton: 'action.select'
      }
    )
  }

  const handleShowInsertRecipients = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setInsertRecipientsEl(event.currentTarget);
  }

  const handleCloseInsertRecipients = (): void => {
    setInsertRecipientsEl(null);
  }

  // insert firstName and/or lastName variables into editor
  const handleInsertEmailVar = (event: React.SyntheticEvent, value: string): void => {
    if (insertCallback) insertCallback(value);
    event.stopPropagation();
    setInsertRecipientsEl(null);
  }

  const handleRecipientSelect = (value: string, name: any) => {
    SettingsService.updateCachedData(value);
  }

  const handleScheduleSend = (): void => {
    ModalService.openCustomModal(
      ScheduleSendModal,
      {
        content: '',
        heading: '',
        confirmButton: ''
      }
    )
  }

  const handleShowSms = (): void => {
    setShowSms(!showSms);
  }

  const handleCloseToast = (): void => {
    setToastStatus({ isShown: false, message: '', type: toastStatus.type });
  }

  const saveDraft = useCallback((autoDraft: boolean): void => {
    const messageToSend: string = SlateToHtmlService.slateContentToHtml(contentValue);
    // Here draft is being saved TODO: need to check different empty values and see if it still saves
    if (MessageService.areAllFieldsEmpty(getValues, messageToSend)) {
      if (!autoDraft) {
        setToastStatus({
          isShown: true, message: 'compose.form.validation.nothingToSave', type: 'error'
        });
      }
    } else {
      if (autoDraft) {
        if (getValues('msgId') !== '-' && !hasLastDraftAutoSaved && moment().valueOf() - lastSaveTime < MinuteAndAHalf) {
          console.debug('prevented auto save - last message was saved, sent or restored less than a minute and a half ago')
          return;
        }
        if (!hasLastDraftAutoSaved) {
          console.debug('resetting msg id');
          setValue('msgId', OriginalMsgId); // so automatic saves don't overwrite existing drafts
          hasLastDraftAutoSaved = true;
        }
      } else {
        hasLastDraftAutoSaved = false
      }
      MessageService.buildMessage(MessageStatus.DRAFT, getValues, messageToSend, null, autoDraft)
        .then(() => {
          lastSaveTime = moment().valueOf();
          DistributionService.sendMessage(MessageStatus.DRAFT, false, messageKey)
            .then((success: boolean) => {
              if (success) {
                const draftTime: string = moment().format('HH:mm');
                let message: string = 'compose.form.draft.draftSaved';
                // have to have time stamp for auto draft
                let toastType: AlertColor = 'success';
                if (autoDraft) {
                  message = 'compose.form.draft.auto';
                  toastType = 'warning';
                }
                setToastStatus({
                  isShown: !toastStatus.isShown,
                  message: String(t(message, { time: draftTime })),
                  type: toastType,
                  icon: ToastIcons.Success
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [contentValue]);

  const clearMessage = (): void => {
    setResetEditor(true);
  }

  // handle the clearing of form and the compose section - called from clear button
  const handleClearEditor = useCallback(async (): Promise<void> => {
    const newForm = { msgId: OriginalMsgId, subject: '', replyTo: '', template: '', unsignedMessage: '', smsmessage: '' };
    reset(newForm);
    dispatch(resetDraft({ key: messageKey }))
    UploadService.clearUploadedFiles();
    dispatch(setMessageToSend({ message: null }))
    clearMessage();
  }, [reset, resetDraft, dispatch, setMessageToSend, UploadService, clearMessage]);

  /**
   * function for getting template from backend
   * @param templateName {string} name of template to get content from backend
   */
  const handleSelectTemplate = (templateName: string): void => {
    // if template already - show popup - where in old code?
    // get template form backend
    DistributionService.fetchDistributionTemplateContent(templateName)
      .then((template: DistributionEmailTemplate) => {
        const messageToSend: string = SlateToHtmlService.slateContentToHtml(contentValue);
        if (!DraftService.isFieldOverwriteValidated(template, messageToSend, getValues)) {
          handleTemplateWarning(template);
        } else {
          // change template into suitable draft format
          // which then picks it up in useEffect above for draft and resets the form
          MessageService.templateToDraft(template, getValues('msgId'));
        }
      }).catch((err) => {
        console.debug(err);
      });
  };

  const handleSelectTemplateOption = (option: string): void => {
    switch (option) {
      case 'save':
        handelSetSaveTemplate(false);
        break;
      case 'saveAs':
        handelSetSaveTemplate(true);
        break;
      case 'delete':
        ModalService.openCustomModal(
          DeleteTemplateModal,
          {
            content: '',
            metaData: [getValues('template')],
            callback: () => {
              setValue('template', '');
              setToastStatus({
                isShown: true,
                message: String(t('compose.deleteTemplate.success')),
                type: 'success',
                icon: ToastIcons.Success
              });
              void handleClearEditor();
            }
          }
        )
        break;
    }
  }

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = (): void => {
    fileInputRef.current?.click();
  };

  /**
   * handles the receiving of form details and changing into acceptable data to send to backend
   * TODO: look into moving this into its own service
   */
  const sendMessage = useCallback(async (data: MessageForm, scheduledTimeStamp: number | null): Promise<void> => {
    const messageToSend: string = SlateToHtmlService.slateContentToHtml(contentValue);
    await MessageService.buildMessage(MessageStatus.READY, getValues, messageToSend, scheduledTimeStamp, false);
    lastSaveTime = moment().valueOf();
    const success: boolean = await DistributionService.sendMessage(MessageStatus.READY, true, messageKey);
    if (success) {
      await handleClearEditor();
    }
  }, [contentValue]);

  // function to capture the submit of form and then call sendMessage
  const onSubmitHandler: SubmitHandler<MessageForm> = async (data: MessageForm) => {
    await sendMessage(data, null);
  };

  const onScheduleSend = async (scheduledDateStamp: number | null) => {
    const data = getValues();
    await sendMessage(data, scheduledDateStamp);
  };

  const isReadOnly: boolean = pageTitle === DistributionHistoryPage.Sent || pageTitle === DistributionHistoryPage.Drafts || pageTitle === DistributionHistoryPage.Outbox;

  return (
    <>
      {(isSendingMessage || isLoading) && <LoadingProgress isLoading={!!(isSendingMessage || isLoading)} />}
      <form
        onSubmit={(...data) => { void handleSubmit(onSubmitHandler)(...data) }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: (isReadOnly) ? totalHeight : ''
        }}
      >
        <Grid container direction={'column'} justifyContent={'flex-start'} alignItems={'stretch'} rowSpacing={2}>
          <ComposeTo
              groupList={groupList}
              groupDepartment={groupDepartment}
              selectedExtraEmails={selectedExtraEmails}
              deleteDisabled={isReadOnly}
              toggleDrawer={toggleDrawer}
              showMembersCount={(pageTitle !== DistributionHistoryPage.Sent)}
          />
          {!isReadOnly &&
            <ComposeRecipient>
              <FormInputSelect
                name={'replyTo'}
                placeholder={String(t('compose.form.labels.replyTo'))}
                formItemProps={{
                  control
                }}
                width={'300px'}
                autoWidth
                options={replyToUsers.map((user: ProductionOfficeUser) => ({ key: user.name, value: user.email, name: user.name, label: user.name + ' (' + user.email + ')' }))}
                onItemClick={handleRecipientSelect}
              />
            </ComposeRecipient>
          }
          <ComposeSubject isReadOnly={isReadOnly}>
            {!isReadOnly &&
              <FormInputTextField
                  onClick={() => {
                    if (setListPanelOpen) setListPanelOpen(false);
                  }}
                name={'subject'}
                formItemProps={{
                  control
                }}
              />
            }

            {isReadOnly &&
              <Box sx={{ width: '100%', margin: '0 20px' }}>
                {draft.subject}
              </Box>
            }
          </ComposeSubject>
          </Grid>
        {!isReadOnly &&
          <Grid
            item
            sx={{
              textAlign: 'left',
              border: `1px solid ${String(BorderGrey)}`,
              boxShadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              marginTop: '30px',
              marginBottom: '10px'
            }}
          >
            <FileUpload fileInputRef={fileInputRef} uploadDestination={UploadFileLocation.Compose}>
              {(pageTitle === ComposePage.Compose)
                ? <Grid item container direction={'row'} alignItems={'center'}
                        sx={{ textAlign: 'left', padding: '15px 15px 5px 15px', gap: '5px' }}>
                  <Grid item>
                    <FormInputSelect
                      name={'template'}
                      placeholder={String(t('compose.form.labels.selectTemplate'))}
                      formItemProps={{
                        control
                      }}
                      onItemClick={handleSelectTemplate}
                      options={productionTemplates.map((template: DistributionEmailTemplateName) => ({
                        key: template[0],
                        label: template[0],
                        value: template[0]
                      }))}
                    />
                  </Grid>
                  <Grid item sx={{ flex: 1 }}>
                    {!(getValues('template'))
                      ? <TertiaryButton
                          disableRipple
                          onClick={() => handelSetSaveTemplate(true)}
                        >
                          {t('compose.form.action.saveAsTemplate')}
                        </TertiaryButton>
                      : <FormInputSelect
                         name={'templateOption'}
                         formItemProps={{
                           control
                         }}
                         placeholder={String(t('compose.form.action.templateOptions'))}
                         onItemClick={handleSelectTemplateOption}
                         options={templateOptions.map((option: OptionProps) => ({ ...option, label: t(option.label) }))}
                         alwaysShowPlaceholder={true}
                        />
                    }
                  </Grid>
                  <Grid item>
                    <TertiaryButton
                      disableRipple
                      nocapitalize
                      onClick={handleShowInsertRecipients}
                    >
                      {t('compose.form.action.insertRecipient')}
                    </TertiaryButton>
                    <BasicMenuDropdown
                      anchorEl={insertRecipientsEl}
                      handleClose={handleCloseInsertRecipients}
                      handleSelect={handleInsertEmailVar}
                      options={[
                        { key: 'insert-first-name', label: 'compose.form.labels.firstName', value: 'firstName' },
                        { key: 'insert-last-name', label: 'compose.form.labels.lastName', value: 'lastName' }
                      ]}
                    />
                  </Grid>
                </Grid>
                : <></>
              }
              <Divider/>
              <FormControl sx={{ width: '100%', height: '320px' }}>
                <SlateTextEditor
                  initialValue={initialEditorValue}
                  contentValue={contentValue}
                  setContentValue={setContentValue}
                  changeEditorValue={changeEditorValue}
                  setChangeEditorValue={setChangeEditorValue}
                  placeholder={'compose.form.labels.startMessage'}
                  toolbar={true}
                  clearEditor={resetEditor}
                  setClearEditor={setResetEditor}
                  setInsertFunction={(callback: any) => { insertCallback = callback; } }
                />
              </FormControl>
              <ChipAttachArea
                files={uploadFiles}
                sx={{
                  border: 'none',
                  boxShadow: 'none'
                }}
              />
              <Divider/>
              {(pageTitle === ComposePage.Compose)
                ? <>
                  <Grid item container sx={{ width: '100%' }}>
                    <ButtonGroup
                      variant="text"
                      aria-label="tertiary button group"
                      sx={{
                        display: 'flex',
                        justifyContent: 'row',
                        width: '100%',
                        alignItems: 'center',
                        paddingBottom: '5px',
                        paddingTop: '5px',
                        paddingRight: '10px',
                        borderTop: `1px solid ${BorderGrey}`
                      }}>
                      <TertiaryButton
                        disableRipple
                        onClick={handleShowSms}
                        textcolor={CrooglooGreen}
                        hovercolor={DarkerCroolooGreen}
                        endIcon={(!showSms) ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                        sx={{
                          fontWeight: 700,
                          marginLeft: '6px',
                          display: 'flex',
                          flexGrow: 1,
                          justifyContent: 'flex-start',
                          borderColor: `${BorderGrey} !important`
                        }}
                      >
                        {t('compose.form.action.textSms')}
                      </TertiaryButton>
                      <TertiaryButton
                        startIcon={<AttachmentIcon/>}
                        disableRipple onClick={handleUploadClick}
                        sx={{ borderColor: `${BorderGrey} !important` }}
                      >
                        {t('compose.form.action.uploadFiles')}
                      </TertiaryButton>
                      <TertiaryButton startIcon={<SearchIcon/>} disableRipple onClick={handleSearchFiles}>
                        {t('compose.form.action.searchFiles')}
                      </TertiaryButton>
                    </ButtonGroup>
                  </Grid>
                  <TextSMSInput
                    showSms={showSms}
                    placeholder={String(t('compose.form.message.textSMSPlaceholder'))}
                    name={'smsmessage'}
                    formItemProps={{
                      control
                    }}
                    sx={{
                      padding: '0 16px 16px'
                    }}
                  />
                </>
                : <></>
              }
            </FileUpload>
          </Grid>
        }
        {isReadOnly &&
          <Grid
            item
            sx={{
              textAlign: 'left',
              maxHeight: editorHeight,
              height: 'auto',
              overflow: 'auto',
              flexShrink: 0
            }}
          >
            {<SlateTextEditor
              initialValue={initialEditorValue}
              contentValue={contentValue}
              setContentValue={setContentValue}
              changeEditorValue={changeEditorValue}
              setChangeEditorValue={setChangeEditorValue}
              toolbar={false}
              clearEditor={resetEditor}
              setClearEditor={setResetEditor}
            />}
          </Grid>
        }
        {(pageTitle === ComposePage.Compose)
          ? <>
              {uploadFiles.length > 0 &&
              <Grid item container direction={'column'} spacing={0}>
                <Grid item container alignItems={'center'} direction={'row'} justifyContent={'flex-start'} spacing={0}>
                  <Grid item>
                    <FormCheckbox
                      name={'sendAsLinkChk'}
                      checkChecked={checkSendAsLinkChk}
                      onClick={clickSendAsLinkChk}
                      options={linkChkOptions.map((item: OptionProps) => ({ ...item, label: t(item.label) }))}
                      formItemProps={{
                        control
                      }}
                      labelSx={{
                        '&.MuiFormControlLabel-root': {
                          marginRight: '10px'
                        },
                        '& .MuiFormControlLabel-label': {
                          fontSize: '12px'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item sx={{ paddingTop: '10px' }}>
                    <TertiaryButton
                      sx={{
                        maxWidth: '12px',
                        minWidth: '12px',
                        '& .MuiButton-startIcon>*:nth-of-type(1)': {
                          fontSize: '15px'
                        }
                      }}
                      disableRipple
                      onClick={handleLinkOptions}
                      startIcon={<SettingsOutlinedIcon />}
                    >
                      {''}
                    </TertiaryButton>
                  </Grid>
                </Grid>
                <Grid item container justifyContent={'flex-start'}>
                  <Box component={'span'}>
                    {t('compose.form.labels.attachmentSize', { size: UtilityService.getTotalFileSizes(uploadFiles) })}
                  </Box>
                </Grid>
              </Grid>
              }
            <Grid
              item
              container
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: (uploadFiles.length > 0) ? '0px' : '20px'
              }}
            >
             <Grid item justifyContent={'flex-start'}>
               <TertiaryButton
                 disableRipple
                 hovercolor={ToastPink}
                 sx={{
                   fontWeight: 700,
                   color: MainBlack,
                   display: 'flex',
                   justifyContent: 'flex-start',
                   paddingLeft: '0px'
                 }}
                 onClick={handleClearModal}
               >
                 {t('action.clear')}
               </TertiaryButton>
             </Grid>
             <Grid justifyContent={'flex-end'}>
               <TertiaryButton disableRipple onClick={() => saveDraft(false)} sx={{ fontWeight: 700, color: MainBlack }}>{t('action.save')}</TertiaryButton>
               <TertiaryButton disableRipple onClick={handlePreviewModal} sx={{ fontWeight: 700, color: MainBlack, marginRight: '14px' }}>{t('compose.form.action.preview')}</TertiaryButton>
               <SpecialButton
                 disableRipple
                 type={'submit'}
                 backgroundcolor={CrooglooGreen}
                 sx={{
                   borderTopLeftRadius: '100px',
                   borderBottomLeftRadius: '100px',
                   borderRight: `1px solid ${String(MainWhite)}`
                 }}
               >
                 {t('compose.form.action.send')}
               </SpecialButton>
               <Tooltip title={t('compose.form.action.scheduleSend')}>
                 <SpecialButton
                   disableRipple
                   backgroundcolor={CrooglooGreen}
                   sx={{
                     borderTopRightRadius: '100px',
                     borderBottomRightRadius: '100px',
                     minWidth: '44px',
                     padding: '5px 0px'
                   }}
                   onClick={handleScheduleSend}
                 >
                   {<KeyboardArrowDownIcon/>}
                 </SpecialButton>
               </Tooltip>
             </Grid>
          </Grid>
          </>
          : <></>
      }

      {isReadOnly && attachments.length > 0 &&
        <Grid
          item
          container
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            height: ComposeChipHeight,
            overflow: 'auto',
            flexGrow: 1
          }}
        >
          <ChipAttachArea
            files={attachments}
            sx={{
              border: 'none',
              boxShadow: 'none'
            }}
            static={true}
            iconOnly={true}
          />
        </Grid>
      }

      </form>
      <SaveTemplateFlow getValues={getValues} contentValue={SlateToHtmlService.slateContentToHtml(contentValue)} />
      <ScheduleSendFlow
        sendScheduledMessage={onScheduleSend}
        toastStatus={toastStatus}
        setToastStatus={setToastStatus}
      />
      <SearchFilesFlow search={true} setIsLoading={handleIsLoading}/>
      <ClearModal clearEditor={handleClearEditor} />
      <PreviewSend getValues={getValues} contentValue={SlateToHtmlService.slateContentToHtml(contentValue)} />
      <NoRecipients />
      <ConfirmSend />
      <NoSubject />
      <TemplateWarning msgId={getValues('msgId')} />
      <InvalidRecipients />
      <NoAttachment />
      <AttachmentError />
      <IncludeAgents />
      <InactiveContacts />
      <SendingFilesError />
      <MissingFiles />
      <LinkOptions />
      <MessageSent />
      <PendingContacts />
      <FileViewerPopup />
      <DeleteTemplate />
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={toastStatus.message}
        icon={toastStatus.icon}
      />
    </>
  )
}
export default ComposeEditor;
