import { useState } from 'react';
import type { FC } from 'react';
import { IconButton, Box, Popover, Grid } from '@mui/material';

import { colors } from './defaultColors';
import useStyles from './editorStyles';

interface Props {
  Icon: React.ElementType
  handleColorSelect: (color: string) => void
  handleColorOpen: () => void
}

export const ColorButton: FC<Props> = ({
  Icon,
  handleColorSelect,
  handleColorOpen
}: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
    handleColorOpen();
  }

  const handleClose = (): void => {
    setIsOpen(false);
  }

  const handleSelect = (color: string): void => {
    handleColorSelect(color);
    handleClose();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <IconButton
        onMouseDown={handleOpen}
        className={classes.button}
        disableRipple
      >
        <Icon />
      </IconButton>
      <Popover
        id={'simple-popover'}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Grid
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto auto auto auto auto auto',
            alignItems: 'center',
            padding: '10px',
            gap: '5px'
          }}
        >
          {colors.map((color: string, index: number) => {
            return (
              <Grid
                key={index}
                data-value={color}
                onClick={() => handleSelect(color)}
                sx={{
                  backgroundColor: color,
                  width: '16px',
                  height: '16px'
                }}
              ></Grid>
            )
          })}
        </Grid>
      </Popover>
    </Box>
  )
}

export default ColorButton;
