import type { FC } from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import styles from './NothingToSee.module.scss';
import { ReactComponent as PlantPersonIcon } from '../../assets/icons/plantPerson.svg';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

export enum NothingToSeeEntityType {
  List,
  Draft,
  Group,
  Contact,
  Script,
  Side
}

export interface NothingToSeeProps {
  entityType?: NothingToSeeEntityType
}

// This component creates the nothing to see here page
const NothingToSee: FC<NothingToSeeProps> = (props: NothingToSeeProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  let entity = t('nothingToSeeHere.type.list');

  if (props.entityType) {
    if (props.entityType === NothingToSeeEntityType.Draft) {
      entity = t('nothingToSeeHere.type.draft');
    } else if (props.entityType === NothingToSeeEntityType.Group) {
      entity = t('nothingToSeeHere.type.group');
    } else if (props.entityType === NothingToSeeEntityType.Contact) {
      entity = t('nothingToSeeHere.type.contact');
    } else if (props.entityType === NothingToSeeEntityType.Side) {
      entity = t('nothingToSeeHere.type.side');
    } else if (props.entityType === NothingToSeeEntityType.Script) {
      entity = t('nothingToSeeHere.type.script');
    }
  }

  const subLabel = t('nothingToSeeHere.create', { entityType: entity });

  return (
    <Grid sx={{ flexGrow: 1, paddingRight: '60px' }} container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs="auto">
        <PlantPersonIcon />
      </Grid>
      <Grid item xs="auto" className={styles.emptyList}>
        <Box className={styles.emptyListTitle}>{t('nothingToSeeHere.title')}</Box>
        <Box className={styles.subLabel}>{subLabel}</Box>
      </Grid>
    </Grid>
  )
};

export default NothingToSee;
