import React, { lazy, Suspense } from 'react';
import type { DocumentListsProps } from './DocumentLists';

const LazyDocumentLists = lazy(async () => await import('./DocumentLists'));

const DocumentLists = (props: DocumentListsProps) => (
  <Suspense fallback={null}>
    <LazyDocumentLists {...props} />
  </Suspense>
);

export default DocumentLists;
