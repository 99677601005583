import React from 'react';
import type { FC } from 'react';
import dialogStyles from '../../../theme/dialog.module.scss';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';

export interface ModalTitleProps {
  handleClose: () => void
  title: string
  hideCloseButton?: boolean
}

/**
 * Reusable Dialog Popup Title component
 * Called by any modal/dialog to display the title
 * contains an (x) close button at top with title
 */
const ModalTitle: FC<ModalTitleProps> = ({ handleClose, title, hideCloseButton }) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  return (
    <DialogTitle className={dialogStyles.dialogTitle}>
      {(title) ? t(title) : ''}

      { hideCloseButton !== false && <IconButton
        aria-label='close'
        onClick={handleClose}
        className={dialogStyles.dialogCloseButton}
      >
        <CloseIcon />
      </IconButton> }
    </DialogTitle>
  )
};

export default ModalTitle;
