import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Paper, IconButton, Box } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import Tooltip from './common/Tooltip';
import { ReactComponent as PdfIcon } from '../assets/icons/pdf.svg';
import { ReactComponent as DocIcon } from '../assets/icons/doc.svg';
import { ReactComponent as RemoveIcon } from '../assets/icons/remove.svg';
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import { LightBlack, MainWhite, BorderGrey, MainBlack, BlackGrey, CrooglooGreen } from '../theme/colors';
import UploadService from '../services/UploadService';
import { FileType } from '../models/Document';
import type { FileAttachment } from '../models/Document';
import DocumentService from '../services/documents/DocumentService';
import UtilityService from '../services/UtilityService';
import { CrooglooFileTypes } from '../models/FileTypes';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}));

interface Props {
  files: FileAttachment[]
  sx?: SxProps<Theme>
  static?: boolean
  iconOnly?: boolean
}

/**
 * Chip attach area for displaying files/docs on the text editor
 */
const ChipAttachArea: FC<Props> = (props: Props) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();
  const [filesData, setFiles] = useState<FileAttachment[]>([]);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  const handleDelete = (fileToDelete: FileAttachment) => () => {
    if (props.static) {
      return;
    }
    setFiles((files: FileAttachment[]) => files.filter((file: FileAttachment) => file.id !== fileToDelete.id));
    UploadService.removeUploadedFile(fileToDelete.id);
  };

  const handleClick: (file: FileAttachment) => any = async (file: FileAttachment) => {
    if (!file) {
      return;
    }

    if (!file.id.toLowerCase().endsWith(CrooglooFileTypes.PDF)) {
      const url: string = await DocumentService.fetchDownloadLink(file.id);
      if (!url) {
        return;
      }
      DocumentService.handleDownloadFile(url, file.fileName);
    } else {
      await DocumentService.openFile(file);
    }
  };

  return (
    <Paper
      sx={{
        ...{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0
        },
        ...props.sx
      }}
      component='ul'
    >
      {filesData.map((file: FileAttachment, index: number) => {
        let icon = <DocIcon />;
        if (file.fileExtension === FileType.PDF) {
          icon = <PdfIcon />;
        }

        const iconTooltip =
          <IconButton aria-label='close' sx={{ color: LightBlack }}>
            <Tooltip title={t('common.tooltips.removeAttachment')} placement={'right'}>
              {<RemoveIcon/>}
            </Tooltip>
          </IconButton>
        ;

        return (
          <ListItem key={`file-upload-${index}`}>
            {(props.iconOnly)
              ? <Tooltip title={file.fileName} placement={'top'}>
                <Chip
                  sx={{
                    height: '40px',
                    background: MainWhite,
                    border: `1px solid ${BorderGrey}`,
                    borderRadius: '60px',
                    paddingLeft: '10px',
                    '&:hover': {
                      background: 'WhiteGrey',
                      border: `1px solid ${MainBlack}`,
                      borderRadius: '60px'
                    },
                    '.MuiChip-deleteIcon': {
                      display: props.static ? 'none' : 'initial'
                    }
                  }}
                  label={<></>}
                  icon={icon}
                />
                </Tooltip>
              : <Chip
                icon={icon}
                deleteIcon={iconTooltip}
                onClick={() => handleClick(file)}
                onDelete={handleDelete(file)}
                sx={{
                  height: '40px',
                  background: MainWhite,
                  border: `1px solid ${BorderGrey}`,
                  borderRadius: '60px',
                  paddingLeft: '10px',
                  '&:hover': {
                    background: 'WhiteGrey',
                    border: `1px solid ${MainBlack}`,
                    borderRadius: '60px'
                  },
                  '.MuiChip-deleteIcon': {
                    display: props.static ? 'none' : 'initial'
                  }
                }}
                label={(
                  <Box
                    sx={{
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '20px'
                    }}
                  >
                    <Box
                      component={'div'}
                      sx={{
                        fontSize: '0.875rem',
                        color: BlackGrey
                      }}
                    >
                      {file.fileName}
                    </Box>
                    {(UtilityService.isWatermarked(file.isWatermarked)) // display the watermark tag
                      ? <Box
                        component={'div'}
                        sx={{
                          color: CrooglooGreen,
                          fontSize: '0.75rem'
                        }}
                      >
                        {t('common.labels.watermarked')}
                      </Box>
                      : <></>
                    }
                    </Box>
                )}
            />
          }
          </ListItem>
        );
      })}
    </Paper>
  );
}

export default ChipAttachArea;
