import type { Dispatch, FC, SetStateAction } from 'react';
import styles from './LegendListItem.module.scss';
import { type UseTranslationResponse, useTranslation } from 'react-i18next';

interface LegendListItemProps {
  label: string
  value: number
  color: string
  filterStatus: Dispatch<SetStateAction<string>>
}

const LegendListItem: FC<LegendListItemProps> = (props: LegendListItemProps) => {
  const { t }: UseTranslationResponse<'translation', undefined> = useTranslation();

  const handleClickItem = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
    props.filterStatus(props.label);
  }

  return <div className={styles.LegendListItem} data-testid="LegendListItem" onClick={handleClickItem}>
      <div className={styles.circle}>
        <div style={{ backgroundColor: props.color }}></div>
      </div>
      <div className={styles.label}>
        {t(`distributionReport.status.${props.label}`)}: {props.value}
      </div>
  </div>
};

export default LegendListItem;
