import React, { lazy, Suspense } from 'react';

const LazyRenameFile = lazy(async () => await import('./RenameFile'));

const RenameFile = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => (
  <Suspense fallback={null}>
    <LazyRenameFile {...props} />
  </Suspense>
);

export default RenameFile;
