import { useSlate } from 'slate-react';
import { IconButton } from '@mui/material';

interface ButtonProps {
  format: string
  Icon: React.ElementType
  style: string
  toggleBlock: (editor: any, format: string) => void
}

const BlockButton = ({ format, Icon, style, toggleBlock }: ButtonProps) => {
  const editor = useSlate();
  return (
    <IconButton
      onMouseDown={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        toggleBlock(editor, format);
      }}
      disableRipple
      className={style}
    >
        <Icon />
    </IconButton>
  );
}

export default BlockButton;
