import React, { useState } from 'react';
import type { FC } from 'react';
import GeneralModal from './GeneralModal/GeneralModal.lazy';
import type { RootState } from '../../state/store';
import DistributionService from '../../services/distribution/DistributionService';
import Toast from '../common/toast/Toast';
import { InitialToastValues, ToastIcons } from '../../models/Toast';
import type { ToastProps } from '../../models/Toast';
import { TestMessageResponses } from '../../models/Message';
import { ModalIcons } from './ModalIcon/ModalIcon';
import type { AlertColor } from '@mui/material/Alert';
import { useSelector } from 'react-redux';

export const PreviewSendModal: string = 'PreviewSendModal';

interface Props {
  getValues: any
  contentValue: string
}

/**
 * Preview send Modal component
 * Used in compose when previewing a message
 * Calls generalModal to display data
 */
const PreviewSend: FC<Props> = ({ getValues, contentValue }) => {
  const modalsState = useSelector((state: RootState) => state.modals.custom[PreviewSendModal]);
  const [toastStatus, setToastStatus] = useState<ToastProps>(InitialToastValues);

  const handleCloseToast = (): void => {
    setToastStatus(InitialToastValues);
  }

  const handleShowToast = (message: string, toastType: AlertColor, icon: ToastIcons): void => {
    setToastStatus({
      message,
      type: toastType,
      icon,
      isShown: true
    })
  }

  /**
   * Confirm Preview send funciton
   * On Success or error show toast
   */
  const handleConfirm = async (event: React.SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    return await new Promise((resolve) => {
      DistributionService.sendTestMessage(getValues, contentValue)
        .then((result: TestMessageResponses) => {
          if (result === TestMessageResponses.Success) {
            handleShowToast('compose.testMessage.response.success', 'success', ToastIcons.Success);
          } else if (result === TestMessageResponses.NoPhone) {
            handleShowToast('compose.testMessage.response.noPhone', 'warning', ToastIcons.Info);
          } else if (result === TestMessageResponses.Fail) {
            handleShowToast('compose.testMessage.response.fail', 'error', ToastIcons.Info);
          }
        }).finally(() => {
          resolve();
        })
    })
  }

  return (
    <>
      <GeneralModal
        modalKey={PreviewSendModal}
        isVisible={modalsState.isVisible}
        title={modalsState.title}
        content={modalsState.content}
        heading={modalsState.heading}
        buttons={modalsState.buttons}
        width={538}
        confirmButton={modalsState.confirmButton}
        handleConfirm={handleConfirm}
        iconType={ModalIcons.Question}
      />
      <Toast
        open={toastStatus.isShown}
        onClose={handleCloseToast}
        type={toastStatus.type}
        title={toastStatus.message}
        icon={toastStatus.icon}
      />
    </>
  )
}

export default PreviewSend;
